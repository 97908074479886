export enum MonitoringTabTypes {
    LAS = 'las',
    EFICIENCY = 'efficiency',
    MOMENT_AND_WEIGHT = 'moment-weight',
    DRM = 'drm',
    PRO_MASTER = 'pro-master',
    CANDLE_CONSTRUCTION = 'candle-construction',
}

export enum MonitoringTabLabels {
    MONITORING = 'Мониторинг',
    LAS = 'Работа с LAS',
    EFICIENCY = 'Эффективность буровых бригад',
    MOMENT_AND_WEIGHT = 'График моментов и весов',
    DRM = 'РТК',
    PRO_MASTER = 'Цифровая Буровая 3.0',
    CANDLE_CONSTRUCTION = 'Конструктор свечи',
}
