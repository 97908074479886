import {Autocomplete, TextField, styled, Box, alpha} from '@mui/material';
import {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setWellNumber} from 'slices/wellNumberSlice';
import {RouteParams} from 'utils';
import {selectWellNumber} from 'slices';
import {activeConnectionId$} from '@pages/monitoring/controllers/dashboardSettingsController';
import {useMonitoringWellsData} from '@pages/monitoring/hooks/useMonitoringWellsData';

type WellSelectProps = {
    width?: number;
    label?: string;
};

type WellOption = {
    label: string;
    value?: number;
    connectionStatus: 'Активные' | 'Неактивные' | 'Нет подключения';
    minDelay?: number;
};

const GroupHeader = styled('div')(({theme}) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    zIndex: 1,
    backdropFilter: 'blur(3px)',
    fontSize: '0.75rem',
}));

export const MonitoringWellSelect = ({width = 250, label = 'Скважина'}: WellSelectProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams<RouteParams>();
    const wellNumber = useSelector(selectWellNumber);

    const {data: wellData} = useMonitoringWellsData();
    const [dynamicWidth, setDynamicWidth] = useState(width);

    const wellOptions = useMemo<WellOption[]>(() => {
        if (!wellData) return [];

        return wellData.map((well) => {
            const connections = well.connections ?? [];
            let connectionStatus: WellOption['connectionStatus'] = 'Нет подключения';
            let minDelay: number | undefined = undefined;

            if (connections.length > 0) {
                const delays = connections.map((c) => c.delayInSec ?? Infinity);
                minDelay = Math.min(...delays);

                const hasActiveConnection = connections.some(
                    (conn) =>
                        conn.active &&
                        conn.delayInSec !== undefined &&
                        conn.delayInSec <= 12 * 3600,
                );

                if (hasActiveConnection) {
                    connectionStatus = 'Активные';
                } else {
                    connectionStatus = 'Неактивные';
                }
            }

            return {
                label: `${well.padTitle}/${well.name}/${well.fieldTitle}`,
                value: well.id ?? 0,
                connectionStatus,
                minDelay,
            };
        });
    }, [wellData]);

    const sortedAndGroupedOptions = useMemo(() => {
        const grouped = {
            Активные: [] as WellOption[],
            Неактивные: [] as WellOption[],
            'Нет подключения': [] as WellOption[],
        };

        wellOptions.forEach((option) => {
            grouped[option.connectionStatus].push(option);
        });

        return [...grouped['Активные'], ...grouped['Неактивные'], ...grouped['Нет подключения']];
    }, [wellOptions]);

    const currentWell = useMemo(
        () =>
            params.wellId || wellNumber
                ? wellOptions.find((well) => well.value === +(params.wellId || wellNumber))
                : null,
        [params.wellId, wellNumber, wellOptions],
    );

    useEffect(() => {
        if (currentWell?.label) {
            const charWidth = 12;
            const textWidth = currentWell.label.length * charWidth;

            if (textWidth > width) {
                setDynamicWidth(textWidth + 20);
            } else {
                setDynamicWidth(width);
            }
        }
    }, [currentWell, width]);

    const handleWellChange = (
        event: SyntheticEvent<Element, Event>,
        newWell: WellOption | null,
    ) => {
        if (newWell) {
            activeConnectionId$.set('');
            dispatch(setWellNumber(newWell.value));
        }

        if (!newWell) {
            dispatch(setWellNumber(''));
        }

        if (newWell && params.wellId) {
            const newPath = location.pathname
                .replace(params.wellId, `${newWell.value}`)
                .concat(location.search);

            navigate(newPath);
        }
    };

    return (
        <Autocomplete
            disablePortal
            options={sortedAndGroupedOptions}
            groupBy={(option) => option.connectionStatus}
            getOptionLabel={(option) => option.label}
            sx={{
                width: `${dynamicWidth}px`,
            }}
            value={currentWell ?? null}
            onChange={handleWellChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='standard'
                    label={label}
                    size='small'
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <Box component='div'>{params.children}</Box>
                </li>
            )}
        />
    );
};
