/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface WorkingOutUpDrmOperationInterval
 */
export interface WorkingOutUpDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof WorkingOutUpDrmOperationInterval
     */
    rpmSetpoint: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutUpDrmOperationInterval
     */
    flowRate: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutUpDrmOperationInterval
     */
    reaming1RepetitionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutUpDrmOperationInterval
     */
    reamingDurationMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutUpDrmOperationInterval
     */
    reaming1RopUp: number;
}

/**
 * Check if a given object implements the WorkingOutUpDrmOperationInterval interface.
 */
export function instanceOfWorkingOutUpDrmOperationInterval(value: object): value is WorkingOutUpDrmOperationInterval {
    if (!('rpmSetpoint' in value) || value['rpmSetpoint'] === undefined) return false;
    if (!('flowRate' in value) || value['flowRate'] === undefined) return false;
    if (!('reaming1RepetitionsCount' in value) || value['reaming1RepetitionsCount'] === undefined) return false;
    if (!('reamingDurationMinutes' in value) || value['reamingDurationMinutes'] === undefined) return false;
    if (!('reaming1RopUp' in value) || value['reaming1RopUp'] === undefined) return false;
    return true;
}

export function WorkingOutUpDrmOperationIntervalFromJSON(json: any): WorkingOutUpDrmOperationInterval {
    return WorkingOutUpDrmOperationIntervalFromJSONTyped(json, false);
}

export function WorkingOutUpDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingOutUpDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'rpmSetpoint': json['rpmSetpoint'],
        'flowRate': json['flowRate'],
        'reaming1RepetitionsCount': json['reaming1RepetitionsCount'],
        'reamingDurationMinutes': json['reamingDurationMinutes'],
        'reaming1RopUp': json['reaming1RopUp'],
    };
}

export function WorkingOutUpDrmOperationIntervalToJSON(value?: WorkingOutUpDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'rpmSetpoint': value['rpmSetpoint'],
        'flowRate': value['flowRate'],
        'reaming1RepetitionsCount': value['reaming1RepetitionsCount'],
        'reamingDurationMinutes': value['reamingDurationMinutes'],
        'reaming1RopUp': value['reaming1RopUp'],
    };
}

