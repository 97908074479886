import {createEventSource} from 'utils/functions/createEventSource';
import {GetMonitoringWellsParams, MonitoringWellsMessage} from './types';
import {WellDataApi} from '@core/api/api';
import {MonitoringWell} from '@core/api/back/models';

export class MonitoringWellsService {
    private static instance: MonitoringWellsService;

    public static getInstance() {
        if (!MonitoringWellsService.instance) {
            MonitoringWellsService.instance = new MonitoringWellsService();
        }
        return MonitoringWellsService.instance;
    }

    public getMonitoringWellsPersons({
        scene,
        onMessage,
        wellId,
        isUnmountedRef,
    }: GetMonitoringWellsParams) {
        const urlParams = new URLSearchParams({scene});
        const url = `/api/monitoring/wells/${wellId}/persons?${urlParams.toString()}`;

        return createEventSource<MonitoringWellsMessage>({
            url,
            onMessage,
            isUnmountedRef,
        });
    }
}

export const getMonitoringWells = (): Promise<MonitoringWell[]> => {
    return WellDataApi.findAll2();
};
