import {Checkbox, TextField} from '@mui/material';
import {
    ActionType,
    ColumnData,
    ColumnFormats,
    ColumnTypes,
    FormatValueToDisplayProps,
    GetFormFieldProps,
    setElementsOrderType,
} from './types';
import {DesktopDatePicker, DesktopDateTimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import styles from './NewTable.module.scss';
import TableCellTooltip from './TableCellTooltip';
import {DT_FORMATS, DictionaryItem, formatDate, invokeAsync} from 'utils';
import {ErrorAdornment} from './components/ErrorAndornment';
import {TableAutocomplete} from './components/TableAutocomplete';
import {TableMultipleAutocomplete} from './components/TableMultipleAutocomplete';
import {formateStringAsNumber, formatNumber} from 'utils/functions/number';

export const formatValueToDisplay = ({
    type,
    format,
    value,
    columnWidth,
    actionType,
    customDisplayFormat,
    rowId,
}: FormatValueToDisplayProps) => {
    if (customDisplayFormat) {
        return customDisplayFormat(rowId, value);
    }
    if (actionType === ActionType.DATE_TIME_SELECT_ONLY_TIME) {
        return value ? dayjs(value).format(DT_FORMATS.shortTime) : null;
    }
    if (format === ColumnFormats.DATE) {
        return value ? formatDate(value, DT_FORMATS.defaultDate) : null;
    }
    if (format === ColumnFormats.DATE_TIME) {
        return value ? formatDate(value) : null;
    }
    if (format === ColumnFormats.CURRENCY) {
        const parts = value.toString().split('.');
        parts[0] = formatNumber(parts[0]);
        return parts.join('.');
    }
    if (type === ColumnTypes.BOOLEAN) {
        return value === true ? 'Да' : value === false ? 'Нет' : '';
    }
    if (type === ColumnTypes.STRING) {
        return (
            <TableCellTooltip
                value={value}
                columnWidth={columnWidth}
            />
        );
    }

    if (type === ColumnTypes.ARRAY) {
        const stringValue = value && Array.isArray(value) ? value.join(', ') : '';
        return (
            <TableCellTooltip
                value={stringValue}
                columnWidth={columnWidth}
            />
        );
    }
    return value;
};

export const getTableField = ({
    valueKey,
    dictionary,
    values,
    setValue,
    column,
    disabled,
    CustomComponent,
    customProps,
    required = false,
}: GetFormFieldProps) => {
    const value = values[valueKey];
    if (CustomComponent) {
        return (
            <CustomComponent
                valueKey={valueKey}
                dictionary={dictionary}
                values={values}
                setValue={setValue}
                column={column}
                disabled={disabled}
                customProps={customProps}
                required={required}
            />
        );
    }

    if (column.type === ColumnTypes.ARRAY) {
        return (
            <TableMultipleAutocomplete
                disabled={disabled}
                dictionary={dictionary}
                valueKey={valueKey}
                setValue={setValue}
                required={required}
                values={values}
                column={column}
            />
        );
    }

    if (column.dictName) {
        return (
            <TableAutocomplete
                disabled={disabled}
                dictionary={dictionary}
                valueKey={valueKey}
                setValue={setValue}
                required={required}
                values={values}
                column={column}
            />
        );
    }

    if (column.type === ColumnTypes.BOOLEAN) {
        return (
            <Checkbox
                checked={value || false}
                onChange={(event) => setValue(valueKey, event.target.checked)}
                sx={{padding: '4px'}}
            />
        );
    }

    if (column.format === ColumnFormats.DATE) {
        return (
            <DesktopDatePicker
                value={value ? dayjs(value) : null}
                format={DT_FORMATS.defaultDate}
                onChange={(newValue) =>
                    setValue(valueKey, formatDate(newValue, DT_FORMATS.utcDate))
                }
                className={styles.dataPicker}
                sx={{
                    minWidth: 160,
                    '.MuiInputBase-root': {
                        padding: 0,
                    },
                    '.MuiInputBase-input': {
                        padding: '6px 0',
                    },
                }}
                slotProps={{
                    textField: {
                        InputProps: {
                            startAdornment: (
                                <ErrorAdornment
                                    value={value}
                                    required={required}
                                />
                            ),
                        },
                    },
                }}
            />
        );
    }
    if (column.format === ColumnFormats.DATE_TIME) {
        return (
            <DesktopDateTimePicker
                ampm={false}
                format={DT_FORMATS.full}
                value={value ? dayjs(value) : null}
                className={styles.dataPicker}
                onChange={(newValue) =>
                    setValue(valueKey, formatDate(newValue, DT_FORMATS.utcFull))
                }
                sx={{
                    minWidth: 200,
                    '.MuiInputBase-root': {
                        padding: 0,
                    },
                    '.MuiInputBase-input': {
                        padding: '6px 0',
                    },
                }}
                slotProps={{
                    textField: {
                        InputProps: {
                            startAdornment: (
                                <ErrorAdornment
                                    value={value}
                                    required={required}
                                />
                            ),
                        },
                    },
                }}
            />
        );
    }

    return (
        <TextField
            fullWidth
            maxRows={column.type === ColumnTypes.STRING ? 4 : 1}
            multiline={column.type === ColumnTypes.STRING}
            size='small'
            value={value || '' || (value === 0 ? value : '')}
            variant='standard'
            type={column.type === ColumnTypes.STRING ? 'string' : 'number'}
            style={{minWidth: column.type === ColumnTypes.STRING ? '150px' : '70px'}}
            InputProps={{
                startAdornment: (
                    <ErrorAdornment
                        value={value}
                        required={required}
                    />
                ),
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (column.type === ColumnTypes.INTEGER) {
                    const newValue = event.target.value;
                    const modifyValue = formateStringAsNumber({
                        value: newValue,
                        onlyPositive: false,
                    });
                    setValue(valueKey, modifyValue);
                    return;
                }
                if (column.type === ColumnTypes.NUMBER) {
                    const newValue = event.target.value;
                    const modifyValue = newValue.replace('e', '');
                    setValue(valueKey, modifyValue);
                    return;
                }
                setValue(valueKey, event.target.value);
            }}
        />
    );
};

export const onSetElementsOrder =
    (setElementsOrder: setElementsOrderType, startIndex?: number) =>
    (orders: Array<string | number>) => {
        const newOrder = orders.reduce((res: Record<string | number, number>, item, index) => {
            res[item] = (startIndex || 0) + index + 1;
            return res;
        }, {});

        return invokeAsync(() => setElementsOrder(newOrder));
    };

export const handleSelectForTable = (
    id: number,
    selected: number[],
    setSelected: React.Dispatch<React.SetStateAction<number[]>>,
) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
    }
    setSelected(newSelected);
};

type getOptionsProps = {
    dictionary?: DictionaryItem[] | string | null;
    column: ColumnData;
    values: Record<string, string>;
};

export const getOptions = ({dictionary, column, values}: getOptionsProps): DictionaryItem[] => {
    if (Array.isArray(dictionary)) {
        if (column?.keyTableAttr && values?.[column.keyTableAttr]) {
            const keyTableAttr = column.keyTableAttr;
            return dictionary?.filter(
                (el) => el.key.toLowerCase() === values?.[keyTableAttr].toLowerCase(),
            );
        }
        return dictionary;
    }
    return [];
};
