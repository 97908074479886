/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrmSectionOperationTableDto,
  ErrorResponse,
  UpdateIntervalInOperationDrmTableRequest,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrmSectionOperationTableDtoFromJSON,
    DrmSectionOperationTableDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UpdateIntervalInOperationDrmTableRequestFromJSON,
    UpdateIntervalInOperationDrmTableRequestToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface CreateNewIntervalInOperationDrmTableRequest {
    operationId: string;
    updateIntervalInOperationDrmTableRequest: UpdateIntervalInOperationDrmTableRequest;
}

export interface DeleteDrmOperationIntervalRequest {
    operationId: string;
    intervalId: string;
}

export interface GetDrmOperationTableByOperationIdRequest {
    operationId: string;
}

export interface GetOperationSchemaByOperationTypeRequest {
    operationType: GetOperationSchemaByOperationTypeOperationTypeEnum;
    drmType: GetOperationSchemaByOperationTypeDrmTypeEnum;
}

export interface GetOperationSchemasRequest {
    drmType: GetOperationSchemasDrmTypeEnum;
}

export interface UpdateIntervalInOperationDrmTableOperationRequest {
    operationId: string;
    intervalId: string;
    updateIntervalInOperationDrmTableRequest: UpdateIntervalInOperationDrmTableRequest;
}

/**
 * 
 */
export class DrmSectionOperationTableControllerApi extends runtime.BaseAPI {

    /**
     */
    async createNewIntervalInOperationDrmTableRaw(requestParameters: CreateNewIntervalInOperationDrmTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling createNewIntervalInOperationDrmTable().'
            );
        }

        if (requestParameters['updateIntervalInOperationDrmTableRequest'] == null) {
            throw new runtime.RequiredError(
                'updateIntervalInOperationDrmTableRequest',
                'Required parameter "updateIntervalInOperationDrmTableRequest" was null or undefined when calling createNewIntervalInOperationDrmTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/operations/{operationId}/intervals`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIntervalInOperationDrmTableRequestToJSON(requestParameters['updateIntervalInOperationDrmTableRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewIntervalInOperationDrmTable(requestParameters: CreateNewIntervalInOperationDrmTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewIntervalInOperationDrmTableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteDrmOperationIntervalRaw(requestParameters: DeleteDrmOperationIntervalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling deleteDrmOperationInterval().'
            );
        }

        if (requestParameters['intervalId'] == null) {
            throw new runtime.RequiredError(
                'intervalId',
                'Required parameter "intervalId" was null or undefined when calling deleteDrmOperationInterval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/operations/{operationId}/intervals/{intervalId}`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))).replace(`{${"intervalId"}}`, encodeURIComponent(String(requestParameters['intervalId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDrmOperationInterval(requestParameters: DeleteDrmOperationIntervalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDrmOperationIntervalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getDrmOperationTableByOperationIdRaw(requestParameters: GetDrmOperationTableByOperationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrmSectionOperationTableDto>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling getDrmOperationTableByOperationId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/operations/{operationId}`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrmSectionOperationTableDtoFromJSON(jsonValue));
    }

    /**
     */
    async getDrmOperationTableByOperationId(requestParameters: GetDrmOperationTableByOperationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrmSectionOperationTableDto> {
        const response = await this.getDrmOperationTableByOperationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOperationSchemaByOperationTypeRaw(requestParameters: GetOperationSchemaByOperationTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['operationType'] == null) {
            throw new runtime.RequiredError(
                'operationType',
                'Required parameter "operationType" was null or undefined when calling getOperationSchemaByOperationType().'
            );
        }

        if (requestParameters['drmType'] == null) {
            throw new runtime.RequiredError(
                'drmType',
                'Required parameter "drmType" was null or undefined when calling getOperationSchemaByOperationType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['drmType'] != null) {
            queryParameters['drmType'] = requestParameters['drmType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/schemas/{operationType}`.replace(`{${"operationType"}}`, encodeURIComponent(String(requestParameters['operationType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getOperationSchemaByOperationType(requestParameters: GetOperationSchemaByOperationTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getOperationSchemaByOperationTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOperationSchemasRaw(requestParameters: GetOperationSchemasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<object>>> {
        if (requestParameters['drmType'] == null) {
            throw new runtime.RequiredError(
                'drmType',
                'Required parameter "drmType" was null or undefined when calling getOperationSchemas().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['drmType'] != null) {
            queryParameters['drmType'] = requestParameters['drmType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/schemas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getOperationSchemas(requestParameters: GetOperationSchemasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<object>> {
        const response = await this.getOperationSchemasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateIntervalInOperationDrmTableRaw(requestParameters: UpdateIntervalInOperationDrmTableOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling updateIntervalInOperationDrmTable().'
            );
        }

        if (requestParameters['intervalId'] == null) {
            throw new runtime.RequiredError(
                'intervalId',
                'Required parameter "intervalId" was null or undefined when calling updateIntervalInOperationDrmTable().'
            );
        }

        if (requestParameters['updateIntervalInOperationDrmTableRequest'] == null) {
            throw new runtime.RequiredError(
                'updateIntervalInOperationDrmTableRequest',
                'Required parameter "updateIntervalInOperationDrmTableRequest" was null or undefined when calling updateIntervalInOperationDrmTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/operations/{operationId}/intervals/{intervalId}`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))).replace(`{${"intervalId"}}`, encodeURIComponent(String(requestParameters['intervalId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIntervalInOperationDrmTableRequestToJSON(requestParameters['updateIntervalInOperationDrmTableRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateIntervalInOperationDrmTable(requestParameters: UpdateIntervalInOperationDrmTableOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIntervalInOperationDrmTableRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetOperationSchemaByOperationTypeOperationTypeEnum = {
    RotorDrilling: 'ROTOR_DRILLING',
    SlideDrilling: 'SLIDE_DRILLING',
    WorkingOutDown: 'WORKING_OUT_DOWN',
    WorkingOutUp: 'WORKING_OUT_UP',
    Spo: 'SPO',
    Restriction: 'RESTRICTION',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    LoadCapacity: 'LOAD_CAPACITY',
    TfOrientation: 'TF_ORIENTATION',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    ReamingRotor: 'REAMING_ROTOR',
    ReamingSlide: 'REAMING_SLIDE',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    Oscillation: 'OSCILLATION'
} as const;
export type GetOperationSchemaByOperationTypeOperationTypeEnum = typeof GetOperationSchemaByOperationTypeOperationTypeEnum[keyof typeof GetOperationSchemaByOperationTypeOperationTypeEnum];
/**
 * @export
 */
export const GetOperationSchemaByOperationTypeDrmTypeEnum = {
    Drm: 'DRM',
    AkbMode: 'AKB_MODE'
} as const;
export type GetOperationSchemaByOperationTypeDrmTypeEnum = typeof GetOperationSchemaByOperationTypeDrmTypeEnum[keyof typeof GetOperationSchemaByOperationTypeDrmTypeEnum];
/**
 * @export
 */
export const GetOperationSchemasDrmTypeEnum = {
    Drm: 'DRM',
    AkbMode: 'AKB_MODE'
} as const;
export type GetOperationSchemasDrmTypeEnum = typeof GetOperationSchemasDrmTypeEnum[keyof typeof GetOperationSchemasDrmTypeEnum];
