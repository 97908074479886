/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationPeriod
 */
export interface OperationPeriod {
    /**
     * 
     * @type {string}
     * @memberof OperationPeriod
     */
    operationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationPeriod
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationPeriod
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationPeriod
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationPeriod
     */
    shift?: OperationPeriodShiftEnum;
}


/**
 * @export
 */
export const OperationPeriodShiftEnum = {
    Day: 'DAY',
    Night: 'NIGHT'
} as const;
export type OperationPeriodShiftEnum = typeof OperationPeriodShiftEnum[keyof typeof OperationPeriodShiftEnum];


/**
 * Check if a given object implements the OperationPeriod interface.
 */
export function instanceOfOperationPeriod(value: object): value is OperationPeriod {
    return true;
}

export function OperationPeriodFromJSON(json: any): OperationPeriod {
    return OperationPeriodFromJSONTyped(json, false);
}

export function OperationPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationPeriod {
    if (json == null) {
        return json;
    }
    return {
        
        'operationCode': json['operationCode'] == null ? undefined : json['operationCode'],
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'duration': json['duration'] == null ? undefined : json['duration'],
        'shift': json['shift'] == null ? undefined : json['shift'],
    };
}

export function OperationPeriodToJSON(value?: OperationPeriod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationCode': value['operationCode'],
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'duration': value['duration'],
        'shift': value['shift'],
    };
}

