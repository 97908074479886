/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface SwitchingToTheModeDrmOperationInterval
 */
export interface SwitchingToTheModeDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof SwitchingToTheModeDrmOperationInterval
     */
    rampTime: number;
    /**
     * 
     * @type {number}
     * @memberof SwitchingToTheModeDrmOperationInterval
     */
    flowRate: number;
    /**
     * 
     * @type {number}
     * @memberof SwitchingToTheModeDrmOperationInterval
     */
    pressureMax: number;
}

/**
 * Check if a given object implements the SwitchingToTheModeDrmOperationInterval interface.
 */
export function instanceOfSwitchingToTheModeDrmOperationInterval(value: object): value is SwitchingToTheModeDrmOperationInterval {
    if (!('rampTime' in value) || value['rampTime'] === undefined) return false;
    if (!('flowRate' in value) || value['flowRate'] === undefined) return false;
    if (!('pressureMax' in value) || value['pressureMax'] === undefined) return false;
    return true;
}

export function SwitchingToTheModeDrmOperationIntervalFromJSON(json: any): SwitchingToTheModeDrmOperationInterval {
    return SwitchingToTheModeDrmOperationIntervalFromJSONTyped(json, false);
}

export function SwitchingToTheModeDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwitchingToTheModeDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'rampTime': json['rampTime'],
        'flowRate': json['flowRate'],
        'pressureMax': json['pressureMax'],
    };
}

export function SwitchingToTheModeDrmOperationIntervalToJSON(value?: SwitchingToTheModeDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'rampTime': value['rampTime'],
        'flowRate': value['flowRate'],
        'pressureMax': value['pressureMax'],
    };
}

