/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AutoDrillingFunctionParamsSchema,
  AutoDrillingOperationParamsSchema,
  AutoDrillingProgram,
  AutoDrillingProgramFunction,
  AutoDrillingProgramFunctionParams,
  AutoDrillingProgramFunctionToCreate,
  AutoDrillingProgramOperation,
  AutoDrillingProgramOperationParams,
  AutoDrillingProgramOperationToCreate,
  AutoDrillingProgramStage,
  AutoDrillingProgramStageInterval,
  AutoDrillingProgramStageToCreate,
  AutoDrillingProgramStatusInfo,
  AutoDrillingProgramToUpdate,
  ErrorResponse,
  ValidationErrorResponse,
} from '../models/index';
import {
    AutoDrillingFunctionParamsSchemaFromJSON,
    AutoDrillingFunctionParamsSchemaToJSON,
    AutoDrillingOperationParamsSchemaFromJSON,
    AutoDrillingOperationParamsSchemaToJSON,
    AutoDrillingProgramFromJSON,
    AutoDrillingProgramToJSON,
    AutoDrillingProgramFunctionFromJSON,
    AutoDrillingProgramFunctionToJSON,
    AutoDrillingProgramFunctionParamsFromJSON,
    AutoDrillingProgramFunctionParamsToJSON,
    AutoDrillingProgramFunctionToCreateFromJSON,
    AutoDrillingProgramFunctionToCreateToJSON,
    AutoDrillingProgramOperationFromJSON,
    AutoDrillingProgramOperationToJSON,
    AutoDrillingProgramOperationParamsFromJSON,
    AutoDrillingProgramOperationParamsToJSON,
    AutoDrillingProgramOperationToCreateFromJSON,
    AutoDrillingProgramOperationToCreateToJSON,
    AutoDrillingProgramStageFromJSON,
    AutoDrillingProgramStageToJSON,
    AutoDrillingProgramStageIntervalFromJSON,
    AutoDrillingProgramStageIntervalToJSON,
    AutoDrillingProgramStageToCreateFromJSON,
    AutoDrillingProgramStageToCreateToJSON,
    AutoDrillingProgramStatusInfoFromJSON,
    AutoDrillingProgramStatusInfoToJSON,
    AutoDrillingProgramToUpdateFromJSON,
    AutoDrillingProgramToUpdateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface CreateFunctionRequest {
    operationId: string;
    autoDrillingProgramFunctionToCreate: AutoDrillingProgramFunctionToCreate;
}

export interface CreateOperationRequest {
    stageId: string;
    autoDrillingProgramOperationToCreate: AutoDrillingProgramOperationToCreate;
}

export interface CreateProgramsFromTemplatesRequest {
    wellId: number;
    requestBody: Array<string>;
}

export interface CreateStageRequest {
    programId: string;
    autoDrillingProgramStageToCreate: AutoDrillingProgramStageToCreate;
}

export interface DeleteFunctionRequest {
    functionId: string;
}

export interface DeleteOperationRequest {
    operationId: string;
}

export interface DeleteStageRequest {
    stageId: string;
}

export interface DeleteTemplateRequest {
    programId: string;
}

export interface GetAllProgramsRequest {
    wellId: number;
}

export interface GetFunctionParamsSchemaRequest {
    operationType: GetFunctionParamsSchemaOperationTypeEnum;
    functionType: GetFunctionParamsSchemaFunctionTypeEnum;
}

export interface GetOperationParamsSchemaRequest {
    stageType: GetOperationParamsSchemaStageTypeEnum;
    operationType: GetOperationParamsSchemaOperationTypeEnum;
}

export interface GetProgramStatusRequest {
    programId: string;
}

export interface GetProgramStatusesRequest {
    requestBody: Array<string>;
}

export interface StartProgramRequest {
    programId: string;
}

export interface UpdateFunctionsOrdersRequest {
    operationId: string;
    requestBody: { [key: string]: number; };
}

export interface UpdateOperationOrdersRequest {
    stageId: string;
    requestBody: { [key: string]: number; };
}

export interface UpdateOperationParamsRequest {
    operationId: string;
    autoDrillingProgramOperationParams: AutoDrillingProgramOperationParams;
}

export interface UpdateOperationParams1Request {
    functionId: string;
    autoDrillingProgramFunctionParams: AutoDrillingProgramFunctionParams;
}

export interface UpdateParamsFromAsbRequest {
    programId: string;
}

export interface UpdateProgramRequest {
    programId: string;
    autoDrillingProgramToUpdate: AutoDrillingProgramToUpdate;
}

export interface UpdateProgramOrdersRequest {
    wellId: number;
    requestBody: { [key: string]: number; };
}

export interface UpdateStageIntervalsRequest {
    autoDrillingProgramStageInterval: Array<AutoDrillingProgramStageInterval>;
}

export interface UpdateStageOrdersRequest {
    programId: string;
    requestBody: { [key: string]: number; };
}

/**
 * 
 */
export class DigitalDrillingProgramControllerApi extends runtime.BaseAPI {

    /**
     */
    async createFunctionRaw(requestParameters: CreateFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramFunction>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling createFunction().'
            );
        }

        if (requestParameters['autoDrillingProgramFunctionToCreate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramFunctionToCreate',
                'Required parameter "autoDrillingProgramFunctionToCreate" was null or undefined when calling createFunction().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationId'] != null) {
            queryParameters['operationId'] = requestParameters['operationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/functions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramFunctionToCreateToJSON(requestParameters['autoDrillingProgramFunctionToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramFunctionFromJSON(jsonValue));
    }

    /**
     */
    async createFunction(requestParameters: CreateFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramFunction> {
        const response = await this.createFunctionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOperationRaw(requestParameters: CreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramOperation>> {
        if (requestParameters['stageId'] == null) {
            throw new runtime.RequiredError(
                'stageId',
                'Required parameter "stageId" was null or undefined when calling createOperation().'
            );
        }

        if (requestParameters['autoDrillingProgramOperationToCreate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramOperationToCreate',
                'Required parameter "autoDrillingProgramOperationToCreate" was null or undefined when calling createOperation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['stageId'] != null) {
            queryParameters['stageId'] = requestParameters['stageId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/operation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramOperationToCreateToJSON(requestParameters['autoDrillingProgramOperationToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramOperationFromJSON(jsonValue));
    }

    /**
     */
    async createOperation(requestParameters: CreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramOperation> {
        const response = await this.createOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProgramsFromTemplatesRaw(requestParameters: CreateProgramsFromTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingProgram>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling createProgramsFromTemplates().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling createProgramsFromTemplates().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingProgramFromJSON));
    }

    /**
     */
    async createProgramsFromTemplates(requestParameters: CreateProgramsFromTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingProgram>> {
        const response = await this.createProgramsFromTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createStageRaw(requestParameters: CreateStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramStage>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling createStage().'
            );
        }

        if (requestParameters['autoDrillingProgramStageToCreate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramStageToCreate',
                'Required parameter "autoDrillingProgramStageToCreate" was null or undefined when calling createStage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['programId'] != null) {
            queryParameters['programId'] = requestParameters['programId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/stage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramStageToCreateToJSON(requestParameters['autoDrillingProgramStageToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramStageFromJSON(jsonValue));
    }

    /**
     */
    async createStage(requestParameters: CreateStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramStage> {
        const response = await this.createStageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFunctionRaw(requestParameters: DeleteFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['functionId'] == null) {
            throw new runtime.RequiredError(
                'functionId',
                'Required parameter "functionId" was null or undefined when calling deleteFunction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/functions/{functionId}`.replace(`{${"functionId"}}`, encodeURIComponent(String(requestParameters['functionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFunction(requestParameters: DeleteFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFunctionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOperationRaw(requestParameters: DeleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling deleteOperation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/operation/{operationId}`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOperation(requestParameters: DeleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOperationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteStageRaw(requestParameters: DeleteStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['stageId'] == null) {
            throw new runtime.RequiredError(
                'stageId',
                'Required parameter "stageId" was null or undefined when calling deleteStage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/stage/{stageId}`.replace(`{${"stageId"}}`, encodeURIComponent(String(requestParameters['stageId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStage(requestParameters: DeleteStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTemplateRaw(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling deleteTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters['programId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTemplate(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllProgramsRaw(requestParameters: GetAllProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingProgram>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling getAllPrograms().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingProgramFromJSON));
    }

    /**
     */
    async getAllPrograms(requestParameters: GetAllProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingProgram>> {
        const response = await this.getAllProgramsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFunctionParamsSchemaRaw(requestParameters: GetFunctionParamsSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingFunctionParamsSchema>> {
        if (requestParameters['operationType'] == null) {
            throw new runtime.RequiredError(
                'operationType',
                'Required parameter "operationType" was null or undefined when calling getFunctionParamsSchema().'
            );
        }

        if (requestParameters['functionType'] == null) {
            throw new runtime.RequiredError(
                'functionType',
                'Required parameter "functionType" was null or undefined when calling getFunctionParamsSchema().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationType'] != null) {
            queryParameters['operationType'] = requestParameters['operationType'];
        }

        if (requestParameters['functionType'] != null) {
            queryParameters['functionType'] = requestParameters['functionType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/functions/params/schema`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingFunctionParamsSchemaFromJSON(jsonValue));
    }

    /**
     */
    async getFunctionParamsSchema(requestParameters: GetFunctionParamsSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingFunctionParamsSchema> {
        const response = await this.getFunctionParamsSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOperationParamsSchemaRaw(requestParameters: GetOperationParamsSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingOperationParamsSchema>> {
        if (requestParameters['stageType'] == null) {
            throw new runtime.RequiredError(
                'stageType',
                'Required parameter "stageType" was null or undefined when calling getOperationParamsSchema().'
            );
        }

        if (requestParameters['operationType'] == null) {
            throw new runtime.RequiredError(
                'operationType',
                'Required parameter "operationType" was null or undefined when calling getOperationParamsSchema().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['stageType'] != null) {
            queryParameters['stageType'] = requestParameters['stageType'];
        }

        if (requestParameters['operationType'] != null) {
            queryParameters['operationType'] = requestParameters['operationType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/operation/params/schema`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingOperationParamsSchemaFromJSON(jsonValue));
    }

    /**
     */
    async getOperationParamsSchema(requestParameters: GetOperationParamsSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingOperationParamsSchema> {
        const response = await this.getOperationParamsSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProgramStatusRaw(requestParameters: GetProgramStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramStatusInfo>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling getProgramStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/{programId}/status`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters['programId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramStatusInfoFromJSON(jsonValue));
    }

    /**
     */
    async getProgramStatus(requestParameters: GetProgramStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramStatusInfo> {
        const response = await this.getProgramStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProgramStatusesRaw(requestParameters: GetProgramStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingProgramStatusInfo>>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling getProgramStatuses().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingProgramStatusInfoFromJSON));
    }

    /**
     */
    async getProgramStatuses(requestParameters: GetProgramStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingProgramStatusInfo>> {
        const response = await this.getProgramStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async startProgramRaw(requestParameters: StartProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingOperationParamsSchema>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling startProgram().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['programId'] != null) {
            queryParameters['programId'] = requestParameters['programId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingOperationParamsSchemaFromJSON(jsonValue));
    }

    /**
     */
    async startProgram(requestParameters: StartProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingOperationParamsSchema> {
        const response = await this.startProgramRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFunctionsOrdersRaw(requestParameters: UpdateFunctionsOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling updateFunctionsOrders().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateFunctionsOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationId'] != null) {
            queryParameters['operationId'] = requestParameters['operationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/functions/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateFunctionsOrders(requestParameters: UpdateFunctionsOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFunctionsOrdersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateOperationOrdersRaw(requestParameters: UpdateOperationOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['stageId'] == null) {
            throw new runtime.RequiredError(
                'stageId',
                'Required parameter "stageId" was null or undefined when calling updateOperationOrders().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateOperationOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['stageId'] != null) {
            queryParameters['stageId'] = requestParameters['stageId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/operation/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOperationOrders(requestParameters: UpdateOperationOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOperationOrdersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateOperationParamsRaw(requestParameters: UpdateOperationParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramOperation>> {
        if (requestParameters['operationId'] == null) {
            throw new runtime.RequiredError(
                'operationId',
                'Required parameter "operationId" was null or undefined when calling updateOperationParams().'
            );
        }

        if (requestParameters['autoDrillingProgramOperationParams'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramOperationParams',
                'Required parameter "autoDrillingProgramOperationParams" was null or undefined when calling updateOperationParams().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/operation/{operationId}/params`.replace(`{${"operationId"}}`, encodeURIComponent(String(requestParameters['operationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramOperationParamsToJSON(requestParameters['autoDrillingProgramOperationParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramOperationFromJSON(jsonValue));
    }

    /**
     */
    async updateOperationParams(requestParameters: UpdateOperationParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramOperation> {
        const response = await this.updateOperationParamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOperationParams1Raw(requestParameters: UpdateOperationParams1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingProgramFunction>> {
        if (requestParameters['functionId'] == null) {
            throw new runtime.RequiredError(
                'functionId',
                'Required parameter "functionId" was null or undefined when calling updateOperationParams1().'
            );
        }

        if (requestParameters['autoDrillingProgramFunctionParams'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramFunctionParams',
                'Required parameter "autoDrillingProgramFunctionParams" was null or undefined when calling updateOperationParams1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/functions/{functionId}/params`.replace(`{${"functionId"}}`, encodeURIComponent(String(requestParameters['functionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramFunctionParamsToJSON(requestParameters['autoDrillingProgramFunctionParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingProgramFunctionFromJSON(jsonValue));
    }

    /**
     */
    async updateOperationParams1(requestParameters: UpdateOperationParams1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingProgramFunction> {
        const response = await this.updateOperationParams1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateParamsFromAsbRaw(requestParameters: UpdateParamsFromAsbRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling updateParamsFromAsb().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/{programId}/drm`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters['programId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateParamsFromAsb(requestParameters: UpdateParamsFromAsbRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateParamsFromAsbRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateProgramRaw(requestParameters: UpdateProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling updateProgram().'
            );
        }

        if (requestParameters['autoDrillingProgramToUpdate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramToUpdate',
                'Required parameter "autoDrillingProgramToUpdate" was null or undefined when calling updateProgram().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters['programId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingProgramToUpdateToJSON(requestParameters['autoDrillingProgramToUpdate']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateProgram(requestParameters: UpdateProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProgramRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateProgramOrdersRaw(requestParameters: UpdateProgramOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling updateProgramOrders().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateProgramOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/program/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateProgramOrders(requestParameters: UpdateProgramOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProgramOrdersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateStageIntervalsRaw(requestParameters: UpdateStageIntervalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingProgramStage>>> {
        if (requestParameters['autoDrillingProgramStageInterval'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingProgramStageInterval',
                'Required parameter "autoDrillingProgramStageInterval" was null or undefined when calling updateStageIntervals().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/stage/interval`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['autoDrillingProgramStageInterval']!.map(AutoDrillingProgramStageIntervalToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingProgramStageFromJSON));
    }

    /**
     */
    async updateStageIntervals(requestParameters: UpdateStageIntervalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingProgramStage>> {
        const response = await this.updateStageIntervalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStageOrdersRaw(requestParameters: UpdateStageOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['programId'] == null) {
            throw new runtime.RequiredError(
                'programId',
                'Required parameter "programId" was null or undefined when calling updateStageOrders().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateStageOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['programId'] != null) {
            queryParameters['programId'] = requestParameters['programId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/digital-drilling-program/stage/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateStageOrders(requestParameters: UpdateStageOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStageOrdersRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetFunctionParamsSchemaOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type GetFunctionParamsSchemaOperationTypeEnum = typeof GetFunctionParamsSchemaOperationTypeEnum[keyof typeof GetFunctionParamsSchemaOperationTypeEnum];
/**
 * @export
 */
export const GetFunctionParamsSchemaFunctionTypeEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type GetFunctionParamsSchemaFunctionTypeEnum = typeof GetFunctionParamsSchemaFunctionTypeEnum[keyof typeof GetFunctionParamsSchemaFunctionTypeEnum];
/**
 * @export
 */
export const GetOperationParamsSchemaStageTypeEnum = {
    PumpStart: 'PUMP_START',
    OscillationAngles: 'OSCILLATION_ANGLES',
    Rotor: 'ROTOR',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type GetOperationParamsSchemaStageTypeEnum = typeof GetOperationParamsSchemaStageTypeEnum[keyof typeof GetOperationParamsSchemaStageTypeEnum];
/**
 * @export
 */
export const GetOperationParamsSchemaOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type GetOperationParamsSchemaOperationTypeEnum = typeof GetOperationParamsSchemaOperationTypeEnum[keyof typeof GetOperationParamsSchemaOperationTypeEnum];
