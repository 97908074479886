import {ReactComponent as permDeviceInformationOutlined} from 'assets/icons/permDeviceInformationOutlined.svg';
import {ReactComponent as invertColorsOutlined} from 'assets/icons/invertColorsOutlined.svg';
import {ReactComponent as trajectory} from 'assets/icons/trajectory.svg';
import {ReactComponent as mtr} from 'assets/icons/mtr.svg';
import {ReactComponent as fastening} from 'assets/icons/fastening.svg';
import {ReactComponent as vmr} from 'assets/icons/vmr.svg';
import {ReactComponent as knbk} from 'assets/icons/knbk.svg';
import {ReactComponent as drillingWaste} from 'assets/icons/drillingWaste.svg';
import {ReactComponent as kbk} from 'assets/icons/kbk.svg';
import {ReactComponent as autoStoriesOutlined} from 'assets/icons/autoStoriesOutlined.svg';
import {ReactComponent as contentPasteOutlined} from 'assets/icons/contentPasteOutlined.svg';
import {ReactComponent as development} from 'assets/icons/development.svg';
import {ReactComponent as accessTimeOutlined} from 'assets/icons/accessTimeOutlined.svg';
import {ReactComponent as miscellaneousServicesOutlined} from 'assets/icons/miscellaneousServicesOutlined.svg';
import {ReactComponent as prices} from 'assets/icons/prices.svg';
import {ReactComponent as widgetsOutlined} from 'assets/icons/WidgetsOutlined.svg';

export const iconByMenuItemCode: Record<
    string,
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
    common: permDeviceInformationOutlined,
    drillingLiquor: invertColorsOutlined,
    trajectory: trajectory,
    mtr: mtr,
    fastening: fastening,
    vmr: vmr,
    knbk: knbk,
    drillingWaste: drillingWaste,
    chisels: kbk,
    journalOtPbOos: autoStoriesOutlined,
    project: contentPasteOutlined,
    completion: development,
    unproductiveTimes: accessTimeOutlined,
    operation: miscellaneousServicesOutlined,
    wellCommonDashboard: widgetsOutlined,
    prices,
} as const;
