/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationValue
 */
export interface OperationValue {
    /**
     * 
     * @type {string}
     * @memberof OperationValue
     */
    operationCode: string;
    /**
     * 
     * @type {string}
     * @memberof OperationValue
     */
    operationName?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationValue
     */
    value: number;
}

/**
 * Check if a given object implements the OperationValue interface.
 */
export function instanceOfOperationValue(value: object): value is OperationValue {
    if (!('operationCode' in value) || value['operationCode'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OperationValueFromJSON(json: any): OperationValue {
    return OperationValueFromJSONTyped(json, false);
}

export function OperationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationValue {
    if (json == null) {
        return json;
    }
    return {
        
        'operationCode': json['operationCode'],
        'operationName': json['operationName'] == null ? undefined : json['operationName'],
        'value': json['value'],
    };
}

export function OperationValueToJSON(value?: OperationValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationCode': value['operationCode'],
        'operationName': value['operationName'],
        'value': value['value'],
    };
}

