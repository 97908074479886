import {alpha, experimental_extendTheme as extendTheme} from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import {CSSProperties} from 'react';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        secondary: true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        regular1: React.CSSProperties;
        regular2: React.CSSProperties;
        regular3: React.CSSProperties;
        body3: React.CSSProperties;
        tooltipBody?: React.CSSProperties;
        tooltipLabel?: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        regular1?: React.CSSProperties;
        regular2?: React.CSSProperties;
        regular3?: React.CSSProperties;
        body3: React.CSSProperties;
        tooltipBody?: React.CSSProperties;
        tooltipLabel?: React.CSSProperties;
    }

    interface Components {
        MuiDatePicker?: {
            styleOverrides?: {
                root?: CSSProperties;
            };
        };
        MuiCalendarPicker?: {
            styleOverrides?: {
                root?: CSSProperties;
            };
        };
        MuiDateCalendar?: {
            styleOverrides?: {
                root?: CSSProperties;
            };
        };
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        regular1: true;
        regular2: true;
        regular3: true;
        body3: true;
        tooltipBody?: true;
        tooltipLabel?: true;
    }
}

declare module '@mui/material/styles' {
    interface PaletteOptions {
        icon: {
            default: string;
        };
        border: {
            default: string;
        };
        buttonGroup: {
            default: string;
        };
        gradient: {
            menu: string;
        };
        amber: {
            600: string;
        };
        paper_elevation: {
            16: string;
        };
        red: {
            600: string;
        };
        blue: {
            700: string;
        };
        paper: {
            default: string;
        };
        green: {
            primary: string;
        };
        text_contrast: {
            primary: string;
        };
    }
    interface Palette {
        border: {
            default: string;
        };
        icon: {
            default: string;
        };
        buttonGroup: {
            default: string;
        };
    }
    interface TypeBackground {
        upper: string;
    }
}

const buttonGroupColor = alpha('#039BE5', 0.17);

export const theme = extendTheme({
    typography: {
        //fontSize: 12,
        button: {
            //textTransform: 'uppercase',
            //fontSize: 12,
        },
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: 34,
            fontWeight: 400,
            lineHeight: 1.25,
        },
        h2: {
            fontSize: 24,
            fontWeight: 500,
        },
        h3: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.6,
        },
        h4: {
            fontSize: 18,
            fontWeight: 500,
        },
        h5: {
            fontSize: 14,
            fontWeight: 600,
        },
        regular1: {
            fontSize: 16,
            fontWeight: 500,
        },
        regular2: {
            fontSize: 14,
            fontWeight: 500,
        },
        regular3: {
            fontSize: 12,
        },
        body2: {
            fontSize: 12,
        },
        body3: {
            fontSize: 10,
            fontWeight: 400,
        },
        subtitle2: {
            lineHeight: 1.25,
        },
        overline: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.675,
        },
        tooltipBody: {
            fontWeight: 500,
            fontSize: 10,
            lineHeight: '14px',
        },
        tooltipLabel: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '14px',
        },
    },
    shape: {
        borderRadius: 6,
    },
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#039BE5',
                    dark: '#0288D1',
                },
                secondary: {
                    main: '#8F8F8F',
                },
                border: {
                    default: '#3B3B3B',
                },
                icon: {
                    default: '#8F8F8F',
                },
                background: {
                    paper: '#F8F8F8',
                    upper: '#ffffff',
                },
                text: {
                    primary: 'rgba(0, 0, 0, 0.87)',
                    secondary: 'rgba(0, 0, 0, 0.6)',
                },
                buttonGroup: {
                    default: buttonGroupColor,
                },
                gradient: {
                    menu: 'linear-gradient(270deg, rgba(248, 248, 248, 0.00) 9.17%, rgba(248, 248, 248, 0.90) 28.25%, #F8F8F8 100%)',
                },
                grey: {
                    600: '#757575',
                    700: '#eeeeee',
                },
                amber: {
                    600: '#FFC107',
                },
                paper_elevation: {
                    16: '#FFFFFF',
                },
                red: {
                    600: '#E53935',
                },
                blue: {
                    700: '#0288D1',
                },
                paper: {
                    default: ' rgba(248, 248, 248, 0.34)',
                },
                green: {
                    primary: '#2E7D32',
                },
                action: {
                    active: 'rgba(0, 0, 0, 0.54)',
                },
                text_contrast: {
                    primary: '#202020',
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: '#039BE5',
                    dark: '#0288D1',
                },
                background: {
                    default: '#2A2A2A',
                    paper: '#313131',
                    upper: '#303030',
                },
                text: {
                    primary: 'rgba(255, 255, 255, 0.88)',
                    secondary: 'rgba(255, 255, 255, 0.7)',
                },
                error: {
                    main: '#D32F2F',
                    dark: '#8F1B1B',
                },
                border: {
                    default: '#3B3B3B',
                },
                warning: {
                    main: '#E68200',
                    dark: '#F57C00',
                },
                icon: {
                    default: '#8F8F8F',
                },
                Chip: {
                    defaultBorder: 'var(--mui-palette-border-default)',
                },
                secondary: {
                    main: '#8F8F8F',
                },
                buttonGroup: {
                    default: '#383838',
                },
                gradient: {
                    menu: 'linear-gradient(270deg, rgba(42, 42, 42, 0.00) 0%, rgba(42, 42, 42, 0.80) 15.56%, rgba(42, 42, 42, 0.97) 31.58%, #2A2A2A 100%)',
                },
                grey: {
                    600: '#757575',
                    700: '#616161',
                },
                amber: {
                    600: '#FFC107',
                },
                paper_elevation: {
                    16: '#363636',
                },
                red: {
                    600: '#E53935',
                },
                blue: {
                    700: '#0288D1',
                },
                paper: {
                    default: 'rgba(73, 71, 71, 0.34)',
                },
                green: {
                    primary: '#66BB6A',
                },
                action: {
                    active: 'rgba(255, 255, 255, 0.56)',
                },
                text_contrast: {
                    primary: '#FFF',
                },
            },
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    lineHeight: '15px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    color: 'var(--mui-palette-common-background)',
                },
                root: {
                    //textTransform: 'none',
                    borderRadius: '4px',
                    // minWidth: '0px',
                    '&:has( > svg)': {
                        padding: '7px',
                        minWidth: '0px',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid var(--mui-palette-border-default)',
                },
            },
            /* variants: [
                {
                    props: {variant: 'secondary'},
                    style: {
                        color: 'var(--mui-palette-text-secondary)',
                        border: '1px solid var(--mui-palette-border-default)',
                        textTransform: 'none',
                        minWidth: '0px',
                        borderRadius: 'var(--mui-shape-borderRadius)',
                        '&:hover': {
                            backgroundColor: 'var(--mui-palette-border-default)',
                        },
                    },
                },
            ], */
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 24,
                    fontSize: 13,
                    color: 'var(--mui-palette-text-secondary)',
                    borderRadius: 'var(--mui-shape-borderRadius)',
                    borderWidth: '1px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: [
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    '&:hover': {
                        color: 'var(--mui-palette-primary-main)',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: '1.5rem',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: 100,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({theme}) => ({
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? '#ffffff'
                            : 'var(--mui-palette-background-paper)',
                }),
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: 'var(--mui-palette-paper_elevation-16)',
                    backgroundImage: 'none',
                },
                clearIndicator: {
                    visibility: 'visible',
                },
                option: {
                    wordBreak: 'break-word',
                    paddingRight: '10px',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: 'var(--mui-palette-paper_elevation-16)',
                    backgroundImage: 'none',
                },
            },
        },
    },
});
