/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';

/**
 * 
 * @export
 * @interface EventIncidentContent
 */
export interface EventIncidentContent extends SchedulerEventContent {
    /**
     * 
     * @type {number}
     * @memberof EventIncidentContent
     */
    incidentId?: number;
    /**
     * 
     * @type {string}
     * @memberof EventIncidentContent
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof EventIncidentContent
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventIncidentContent
     */
    dateTo: Date;
    /**
     * 
     * @type {string}
     * @memberof EventIncidentContent
     */
    guiltySide: string;
    /**
     * 
     * @type {number}
     * @memberof EventIncidentContent
     */
    durationHours?: number;
}

/**
 * Check if a given object implements the EventIncidentContent interface.
 */
export function instanceOfEventIncidentContent(value: object): value is EventIncidentContent {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('dateFrom' in value) || value['dateFrom'] === undefined) return false;
    if (!('dateTo' in value) || value['dateTo'] === undefined) return false;
    if (!('guiltySide' in value) || value['guiltySide'] === undefined) return false;
    return true;
}

export function EventIncidentContentFromJSON(json: any): EventIncidentContent {
    return EventIncidentContentFromJSONTyped(json, false);
}

export function EventIncidentContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventIncidentContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'incidentId': json['incidentId'] == null ? undefined : json['incidentId'],
        'title': json['title'],
        'dateFrom': (new Date(json['dateFrom'])),
        'dateTo': (new Date(json['dateTo'])),
        'guiltySide': json['guiltySide'],
        'durationHours': json['durationHours'] == null ? undefined : json['durationHours'],
    };
}

export function EventIncidentContentToJSON(value?: EventIncidentContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'incidentId': value['incidentId'],
        'title': value['title'],
        'dateFrom': ((value['dateFrom']).toISOString()),
        'dateTo': ((value['dateTo']).toISOString()),
        'guiltySide': value['guiltySide'],
        'durationHours': value['durationHours'],
    };
}

