/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalMaintenanceAct } from './TechnicalMaintenanceAct';
import {
    TechnicalMaintenanceActFromJSON,
    TechnicalMaintenanceActFromJSONTyped,
    TechnicalMaintenanceActToJSON,
} from './TechnicalMaintenanceAct';

/**
 * 
 * @export
 * @interface TechnicalMaintenanceWorkRequest
 */
export interface TechnicalMaintenanceWorkRequest {
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenanceWorkRequest
     */
    dateFact: Date;
    /**
     * 
     * @type {Array<TechnicalMaintenanceAct>}
     * @memberof TechnicalMaintenanceWorkRequest
     */
    acts: Array<TechnicalMaintenanceAct>;
}

/**
 * Check if a given object implements the TechnicalMaintenanceWorkRequest interface.
 */
export function instanceOfTechnicalMaintenanceWorkRequest(value: object): value is TechnicalMaintenanceWorkRequest {
    if (!('dateFact' in value) || value['dateFact'] === undefined) return false;
    if (!('acts' in value) || value['acts'] === undefined) return false;
    return true;
}

export function TechnicalMaintenanceWorkRequestFromJSON(json: any): TechnicalMaintenanceWorkRequest {
    return TechnicalMaintenanceWorkRequestFromJSONTyped(json, false);
}

export function TechnicalMaintenanceWorkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalMaintenanceWorkRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'dateFact': (new Date(json['dateFact'])),
        'acts': ((json['acts'] as Array<any>).map(TechnicalMaintenanceActFromJSON)),
    };
}

export function TechnicalMaintenanceWorkRequestToJSON(value?: TechnicalMaintenanceWorkRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateFact': ((value['dateFact']).toISOString()),
        'acts': ((value['acts'] as Array<any>).map(TechnicalMaintenanceActToJSON)),
    };
}

