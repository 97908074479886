/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AsbWellMapDto
 */
export interface AsbWellMapDto {
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    connectionName?: string;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    authType: AsbWellMapDtoAuthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AsbWellMapDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof AsbWellMapDto
     */
    externalWellId: number;
    /**
     * 
     * @type {string}
     * @memberof AsbWellMapDto
     */
    connectorType?: AsbWellMapDtoConnectorTypeEnum;
}


/**
 * @export
 */
export const AsbWellMapDtoAuthTypeEnum = {
    Token: 'TOKEN',
    Credentials: 'CREDENTIALS'
} as const;
export type AsbWellMapDtoAuthTypeEnum = typeof AsbWellMapDtoAuthTypeEnum[keyof typeof AsbWellMapDtoAuthTypeEnum];

/**
 * @export
 */
export const AsbWellMapDtoConnectorTypeEnum = {
    GtrWits: 'GTR_WITS',
    DrillerConsole: 'DRILLER_CONSOLE',
    Ecp: 'ECP'
} as const;
export type AsbWellMapDtoConnectorTypeEnum = typeof AsbWellMapDtoConnectorTypeEnum[keyof typeof AsbWellMapDtoConnectorTypeEnum];


/**
 * Check if a given object implements the AsbWellMapDto interface.
 */
export function instanceOfAsbWellMapDto(value: object): value is AsbWellMapDto {
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('authType' in value) || value['authType'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('externalWellId' in value) || value['externalWellId'] === undefined) return false;
    return true;
}

export function AsbWellMapDtoFromJSON(json: any): AsbWellMapDto {
    return AsbWellMapDtoFromJSONTyped(json, false);
}

export function AsbWellMapDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsbWellMapDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'token': json['token'] == null ? undefined : json['token'],
        'login': json['login'] == null ? undefined : json['login'],
        'password': json['password'] == null ? undefined : json['password'],
        'authType': json['authType'],
        'wellId': json['wellId'],
        'externalWellId': json['externalWellId'],
        'connectorType': json['connectorType'] == null ? undefined : json['connectorType'],
    };
}

export function AsbWellMapDtoToJSON(value?: AsbWellMapDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'connectionName': value['connectionName'],
        'token': value['token'],
        'login': value['login'],
        'password': value['password'],
        'authType': value['authType'],
        'wellId': value['wellId'],
        'externalWellId': value['externalWellId'],
        'connectorType': value['connectorType'],
    };
}

