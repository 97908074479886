import {PaginatedResponse} from 'types/pagination';
import {PageData} from 'utils/hooks/useNewPageData';

// Это временная прослойка преобразования данных, так как у нас они сейчас приходят в двух видах
// В будущем все будет приходить в одном виде и прослойка не понадобиться
export const formatDataForTables = <T>(data: PaginatedResponse<T[]>): PageData<T> => {
    const newData: PageData<T> = {
        page: {
            totalElements: data.totalElements,
            number: data.pageable.pageNumber,
            size: data.pageable.pageSize,
            totalPages: data.totalPages,
        },
        _embedded: {
            values: data.content,
        },
    };
    return newData;
};
