/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTemplateTrack } from './MonitoringTemplateTrack';
import {
    MonitoringTemplateTrackFromJSON,
    MonitoringTemplateTrackFromJSONTyped,
    MonitoringTemplateTrackToJSON,
} from './MonitoringTemplateTrack';

/**
 * 
 * @export
 * @interface MonitoringTemplateWithTracks
 */
export interface MonitoringTemplateWithTracks {
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateWithTracks
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateWithTracks
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateWithTracks
     */
    userOwner?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringTemplateWithTracks
     */
    basic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateWithTracks
     */
    description?: string;
    /**
     * 
     * @type {Array<MonitoringTemplateTrack>}
     * @memberof MonitoringTemplateWithTracks
     */
    tracks?: Array<MonitoringTemplateTrack>;
}

/**
 * Check if a given object implements the MonitoringTemplateWithTracks interface.
 */
export function instanceOfMonitoringTemplateWithTracks(value: object): value is MonitoringTemplateWithTracks {
    return true;
}

export function MonitoringTemplateWithTracksFromJSON(json: any): MonitoringTemplateWithTracks {
    return MonitoringTemplateWithTracksFromJSONTyped(json, false);
}

export function MonitoringTemplateWithTracksFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTemplateWithTracks {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'userOwner': json['userOwner'] == null ? undefined : json['userOwner'],
        'basic': json['basic'] == null ? undefined : json['basic'],
        'description': json['description'] == null ? undefined : json['description'],
        'tracks': json['tracks'] == null ? undefined : ((json['tracks'] as Array<any>).map(MonitoringTemplateTrackFromJSON)),
    };
}

export function MonitoringTemplateWithTracksToJSON(value?: MonitoringTemplateWithTracks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'userOwner': value['userOwner'],
        'basic': value['basic'],
        'description': value['description'],
        'tracks': value['tracks'] == null ? undefined : ((value['tracks'] as Array<any>).map(MonitoringTemplateTrackToJSON)),
    };
}

