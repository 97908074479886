/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramFunctionToCreate
 */
export interface AutoDrillingProgramFunctionToCreate {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramFunctionToCreate
     */
    type: AutoDrillingProgramFunctionToCreateTypeEnum;
}


/**
 * @export
 */
export const AutoDrillingProgramFunctionToCreateTypeEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type AutoDrillingProgramFunctionToCreateTypeEnum = typeof AutoDrillingProgramFunctionToCreateTypeEnum[keyof typeof AutoDrillingProgramFunctionToCreateTypeEnum];


/**
 * Check if a given object implements the AutoDrillingProgramFunctionToCreate interface.
 */
export function instanceOfAutoDrillingProgramFunctionToCreate(value: object): value is AutoDrillingProgramFunctionToCreate {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramFunctionToCreateFromJSON(json: any): AutoDrillingProgramFunctionToCreate {
    return AutoDrillingProgramFunctionToCreateFromJSONTyped(json, false);
}

export function AutoDrillingProgramFunctionToCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramFunctionToCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function AutoDrillingProgramFunctionToCreateToJSON(value?: AutoDrillingProgramFunctionToCreate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
    };
}

