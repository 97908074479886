import {LabelDisplayedRowsArgs, TablePagination as TablePaginationMUI} from '@mui/material';
import {TablePaginationProps} from './types';
import {TablePaginationActions} from './TablePaginationActions';

const TablePagination = ({
    totalElements,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
}: TablePaginationProps) => {
    const defaultLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
        const {from, to, count} = paginationInfo;
        return `${from}–${to} из ${count !== -1 ? count : `более чем ${to}`}`;
    };

    if (!(rowsPerPage && setRowsPerPage && setPage && page !== undefined)) {
        return null;
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TablePaginationMUI
            rowsPerPageOptions={[5, 10, 20, 50, 100, 1000]}
            component='div'
            count={totalElements || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Строк на странице:'
            labelDisplayedRows={defaultLabelDisplayedRows}
            ActionsComponent={TablePaginationActions}
        />
    );
};

export default TablePagination;
