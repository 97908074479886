/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationDto } from './DrmOperationDto';
import {
    DrmOperationDtoFromJSON,
    DrmOperationDtoFromJSONTyped,
    DrmOperationDtoToJSON,
} from './DrmOperationDto';

/**
 * 
 * @export
 * @interface DrmSectionDto
 */
export interface DrmSectionDto {
    /**
     * 
     * @type {string}
     * @memberof DrmSectionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionDto
     */
    sectionName?: string;
    /**
     * 
     * @type {Array<DrmOperationDto>}
     * @memberof DrmSectionDto
     */
    operations?: Array<DrmOperationDto>;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionDto
     */
    warningMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof DrmSectionDto
     */
    startDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof DrmSectionDto
     */
    endDepth?: number;
}

/**
 * Check if a given object implements the DrmSectionDto interface.
 */
export function instanceOfDrmSectionDto(value: object): value is DrmSectionDto {
    return true;
}

export function DrmSectionDtoFromJSON(json: any): DrmSectionDto {
    return DrmSectionDtoFromJSONTyped(json, false);
}

export function DrmSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmSectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'sectionName': json['sectionName'] == null ? undefined : json['sectionName'],
        'operations': json['operations'] == null ? undefined : ((json['operations'] as Array<any>).map(DrmOperationDtoFromJSON)),
        'warningMessage': json['warningMessage'] == null ? undefined : json['warningMessage'],
        'startDepth': json['startDepth'] == null ? undefined : json['startDepth'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
    };
}

export function DrmSectionDtoToJSON(value?: DrmSectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'sectionName': value['sectionName'],
        'operations': value['operations'] == null ? undefined : ((value['operations'] as Array<any>).map(DrmOperationDtoToJSON)),
        'warningMessage': value['warningMessage'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
    };
}

