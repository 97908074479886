/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingTemplateStage } from './AutoDrillingTemplateStage';
import {
    AutoDrillingTemplateStageFromJSON,
    AutoDrillingTemplateStageFromJSONTyped,
    AutoDrillingTemplateStageToJSON,
} from './AutoDrillingTemplateStage';

/**
 * 
 * @export
 * @interface AutoDrillingTemplate
 */
export interface AutoDrillingTemplate {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplate
     */
    title: string;
    /**
     * 
     * @type {Array<AutoDrillingTemplateStage>}
     * @memberof AutoDrillingTemplate
     */
    stages?: Array<AutoDrillingTemplateStage>;
}

/**
 * Check if a given object implements the AutoDrillingTemplate interface.
 */
export function instanceOfAutoDrillingTemplate(value: object): value is AutoDrillingTemplate {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function AutoDrillingTemplateFromJSON(json: any): AutoDrillingTemplate {
    return AutoDrillingTemplateFromJSONTyped(json, false);
}

export function AutoDrillingTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'],
        'stages': json['stages'] == null ? undefined : ((json['stages'] as Array<any>).map(AutoDrillingTemplateStageFromJSON)),
    };
}

export function AutoDrillingTemplateToJSON(value?: AutoDrillingTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'stages': value['stages'] == null ? undefined : ((value['stages'] as Array<any>).map(AutoDrillingTemplateStageToJSON)),
    };
}

