/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    timeout?: number;
}

/**
 * Check if a given object implements the SseEmitter interface.
 */
export function instanceOfSseEmitter(value: object): value is SseEmitter {
    return true;
}

export function SseEmitterFromJSON(json: any): SseEmitter {
    return SseEmitterFromJSONTyped(json, false);
}

export function SseEmitterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SseEmitter {
    if (json == null) {
        return json;
    }
    return {
        
        'timeout': json['timeout'] == null ? undefined : json['timeout'],
    };
}

export function SseEmitterToJSON(value?: SseEmitter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timeout': value['timeout'],
    };
}

