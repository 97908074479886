/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';

/**
 * 
 * @export
 * @interface DrillingWellEventContent
 */
export interface DrillingWellEventContent extends SchedulerEventContent {
    /**
     * 
     * @type {string}
     * @memberof DrillingWellEventContent
     */
    wellTitle: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingWellEventContent
     */
    padId: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingWellEventContent
     */
    padTitle: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingWellEventContent
     */
    fieldId: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingWellEventContent
     */
    fieldTitle: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingWellEventContent
     */
    sinking: number;
    /**
     * 
     * @type {Date}
     * @memberof DrillingWellEventContent
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof DrillingWellEventContent
     */
    dateTo: Date;
}

/**
 * Check if a given object implements the DrillingWellEventContent interface.
 */
export function instanceOfDrillingWellEventContent(value: object): value is DrillingWellEventContent {
    if (!('wellTitle' in value) || value['wellTitle'] === undefined) return false;
    if (!('padId' in value) || value['padId'] === undefined) return false;
    if (!('padTitle' in value) || value['padTitle'] === undefined) return false;
    if (!('fieldId' in value) || value['fieldId'] === undefined) return false;
    if (!('fieldTitle' in value) || value['fieldTitle'] === undefined) return false;
    if (!('sinking' in value) || value['sinking'] === undefined) return false;
    if (!('dateFrom' in value) || value['dateFrom'] === undefined) return false;
    if (!('dateTo' in value) || value['dateTo'] === undefined) return false;
    return true;
}

export function DrillingWellEventContentFromJSON(json: any): DrillingWellEventContent {
    return DrillingWellEventContentFromJSONTyped(json, false);
}

export function DrillingWellEventContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingWellEventContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'wellTitle': json['wellTitle'],
        'padId': json['padId'],
        'padTitle': json['padTitle'],
        'fieldId': json['fieldId'],
        'fieldTitle': json['fieldTitle'],
        'sinking': json['sinking'],
        'dateFrom': (new Date(json['dateFrom'])),
        'dateTo': (new Date(json['dateTo'])),
    };
}

export function DrillingWellEventContentToJSON(value?: DrillingWellEventContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'wellTitle': value['wellTitle'],
        'padId': value['padId'],
        'padTitle': value['padTitle'],
        'fieldId': value['fieldId'],
        'fieldTitle': value['fieldTitle'],
        'sinking': value['sinking'],
        'dateFrom': ((value['dateFrom']).toISOString()),
        'dateTo': ((value['dateTo']).toISOString()),
    };
}

