/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';
import type { SchedulerEquipmentTechnicalWorkContent } from './SchedulerEquipmentTechnicalWorkContent';
import {
    SchedulerEquipmentTechnicalWorkContentFromJSON,
    SchedulerEquipmentTechnicalWorkContentFromJSONTyped,
    SchedulerEquipmentTechnicalWorkContentToJSON,
} from './SchedulerEquipmentTechnicalWorkContent';

/**
 * 
 * @export
 * @interface SchedulerEquipmentTechnicalWorkByDayContent
 */
export interface SchedulerEquipmentTechnicalWorkByDayContent extends SchedulerEventContent {
    /**
     * 
     * @type {Array<SchedulerEquipmentTechnicalWorkContent>}
     * @memberof SchedulerEquipmentTechnicalWorkByDayContent
     */
    works: Array<SchedulerEquipmentTechnicalWorkContent>;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEquipmentTechnicalWorkByDayContent
     */
    status: SchedulerEquipmentTechnicalWorkByDayContentStatusEnum;
}


/**
 * @export
 */
export const SchedulerEquipmentTechnicalWorkByDayContentStatusEnum = {
    Overdue: 'OVERDUE',
    Soon: 'SOON',
    NotSoon: 'NOT_SOON',
    Done: 'DONE'
} as const;
export type SchedulerEquipmentTechnicalWorkByDayContentStatusEnum = typeof SchedulerEquipmentTechnicalWorkByDayContentStatusEnum[keyof typeof SchedulerEquipmentTechnicalWorkByDayContentStatusEnum];


/**
 * Check if a given object implements the SchedulerEquipmentTechnicalWorkByDayContent interface.
 */
export function instanceOfSchedulerEquipmentTechnicalWorkByDayContent(value: object): value is SchedulerEquipmentTechnicalWorkByDayContent {
    if (!('works' in value) || value['works'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function SchedulerEquipmentTechnicalWorkByDayContentFromJSON(json: any): SchedulerEquipmentTechnicalWorkByDayContent {
    return SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped(json, false);
}

export function SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerEquipmentTechnicalWorkByDayContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'works': ((json['works'] as Array<any>).map(SchedulerEquipmentTechnicalWorkContentFromJSON)),
        'status': json['status'],
    };
}

export function SchedulerEquipmentTechnicalWorkByDayContentToJSON(value?: SchedulerEquipmentTechnicalWorkByDayContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'works': ((value['works'] as Array<any>).map(SchedulerEquipmentTechnicalWorkContentToJSON)),
        'status': value['status'],
    };
}

