/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WitsmlServerConnectionSettings
 */
export interface WitsmlServerConnectionSettings {
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerConnectionSettings
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerConnectionSettings
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerConnectionSettings
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerConnectionSettings
     */
    witsmlType: WitsmlServerConnectionSettingsWitsmlTypeEnum;
}


/**
 * @export
 */
export const WitsmlServerConnectionSettingsWitsmlTypeEnum = {
    Sngs: 'SNGS',
    Tetrasoft: 'TETRASOFT',
    Geofizmash: 'GEOFIZMASH',
    Prowitsml: 'PROWITSML'
} as const;
export type WitsmlServerConnectionSettingsWitsmlTypeEnum = typeof WitsmlServerConnectionSettingsWitsmlTypeEnum[keyof typeof WitsmlServerConnectionSettingsWitsmlTypeEnum];


/**
 * Check if a given object implements the WitsmlServerConnectionSettings interface.
 */
export function instanceOfWitsmlServerConnectionSettings(value: object): value is WitsmlServerConnectionSettings {
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('login' in value) || value['login'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('witsmlType' in value) || value['witsmlType'] === undefined) return false;
    return true;
}

export function WitsmlServerConnectionSettingsFromJSON(json: any): WitsmlServerConnectionSettings {
    return WitsmlServerConnectionSettingsFromJSONTyped(json, false);
}

export function WitsmlServerConnectionSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlServerConnectionSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'login': json['login'],
        'password': json['password'],
        'witsmlType': json['witsmlType'],
    };
}

export function WitsmlServerConnectionSettingsToJSON(value?: WitsmlServerConnectionSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'login': value['login'],
        'password': value['password'],
        'witsmlType': value['witsmlType'],
    };
}

