/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingTemplateFunction } from './AutoDrillingTemplateFunction';
import {
    AutoDrillingTemplateFunctionFromJSON,
    AutoDrillingTemplateFunctionFromJSONTyped,
    AutoDrillingTemplateFunctionToJSON,
} from './AutoDrillingTemplateFunction';

/**
 * 
 * @export
 * @interface AutoDrillingTemplateOperation
 */
export interface AutoDrillingTemplateOperation {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateOperation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateOperation
     */
    type: AutoDrillingTemplateOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateOperation
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingTemplateOperation
     */
    itemOrder: number;
    /**
     * 
     * @type {Array<AutoDrillingTemplateFunction>}
     * @memberof AutoDrillingTemplateOperation
     */
    functions: Array<AutoDrillingTemplateFunction>;
}


/**
 * @export
 */
export const AutoDrillingTemplateOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingTemplateOperationTypeEnum = typeof AutoDrillingTemplateOperationTypeEnum[keyof typeof AutoDrillingTemplateOperationTypeEnum];


/**
 * Check if a given object implements the AutoDrillingTemplateOperation interface.
 */
export function instanceOfAutoDrillingTemplateOperation(value: object): value is AutoDrillingTemplateOperation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    if (!('functions' in value) || value['functions'] === undefined) return false;
    return true;
}

export function AutoDrillingTemplateOperationFromJSON(json: any): AutoDrillingTemplateOperation {
    return AutoDrillingTemplateOperationFromJSONTyped(json, false);
}

export function AutoDrillingTemplateOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingTemplateOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'itemOrder': json['itemOrder'],
        'functions': ((json['functions'] as Array<any>).map(AutoDrillingTemplateFunctionFromJSON)),
    };
}

export function AutoDrillingTemplateOperationToJSON(value?: AutoDrillingTemplateOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
        'functions': ((value['functions'] as Array<any>).map(AutoDrillingTemplateFunctionToJSON)),
    };
}

