/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectionInfo
 */
export interface ConnectionInfo {
    /**
     * 
     * @type {string}
     * @memberof ConnectionInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionInfo
     */
    type?: ConnectionInfoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionInfo
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionInfo
     */
    live?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConnectionInfo
     */
    delayInSec?: number;
}


/**
 * @export
 */
export const ConnectionInfoTypeEnum = {
    AsbGtrWits: 'ASB_GTR_WITS',
    AsbDrillerConsole: 'ASB_DRILLER_CONSOLE',
    AsbEcp: 'ASB_ECP',
    Witsml: 'WITSML',
    ModbusSkpb: 'MODBUS_SKPB',
    NoType: 'NO_TYPE'
} as const;
export type ConnectionInfoTypeEnum = typeof ConnectionInfoTypeEnum[keyof typeof ConnectionInfoTypeEnum];


/**
 * Check if a given object implements the ConnectionInfo interface.
 */
export function instanceOfConnectionInfo(value: object): value is ConnectionInfo {
    return true;
}

export function ConnectionInfoFromJSON(json: any): ConnectionInfo {
    return ConnectionInfoFromJSONTyped(json, false);
}

export function ConnectionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'type': json['type'] == null ? undefined : json['type'],
        'active': json['active'] == null ? undefined : json['active'],
        'live': json['live'] == null ? undefined : json['live'],
        'delayInSec': json['delayInSec'] == null ? undefined : json['delayInSec'],
    };
}

export function ConnectionInfoToJSON(value?: ConnectionInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'active': value['active'],
        'live': value['live'],
        'delayInSec': value['delayInSec'],
    };
}

