/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface SlideDrillingDrmOperationInterval
 */
export interface SlideDrillingDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof SlideDrillingDrmOperationInterval
     */
    ropMax: number;
    /**
     * 
     * @type {number}
     * @memberof SlideDrillingDrmOperationInterval
     */
    pressureMax: number;
    /**
     * 
     * @type {number}
     * @memberof SlideDrillingDrmOperationInterval
     */
    differentialPressure: number;
    /**
     * 
     * @type {number}
     * @memberof SlideDrillingDrmOperationInterval
     */
    weightOnBit: number;
    /**
     * 
     * @type {number}
     * @memberof SlideDrillingDrmOperationInterval
     */
    flowRate: number;
}

/**
 * Check if a given object implements the SlideDrillingDrmOperationInterval interface.
 */
export function instanceOfSlideDrillingDrmOperationInterval(value: object): value is SlideDrillingDrmOperationInterval {
    if (!('ropMax' in value) || value['ropMax'] === undefined) return false;
    if (!('pressureMax' in value) || value['pressureMax'] === undefined) return false;
    if (!('differentialPressure' in value) || value['differentialPressure'] === undefined) return false;
    if (!('weightOnBit' in value) || value['weightOnBit'] === undefined) return false;
    if (!('flowRate' in value) || value['flowRate'] === undefined) return false;
    return true;
}

export function SlideDrillingDrmOperationIntervalFromJSON(json: any): SlideDrillingDrmOperationInterval {
    return SlideDrillingDrmOperationIntervalFromJSONTyped(json, false);
}

export function SlideDrillingDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlideDrillingDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'ropMax': json['ropMax'],
        'pressureMax': json['pressureMax'],
        'differentialPressure': json['differentialPressure'],
        'weightOnBit': json['weightOnBit'],
        'flowRate': json['flowRate'],
    };
}

export function SlideDrillingDrmOperationIntervalToJSON(value?: SlideDrillingDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'ropMax': value['ropMax'],
        'pressureMax': value['pressureMax'],
        'differentialPressure': value['differentialPressure'],
        'weightOnBit': value['weightOnBit'],
        'flowRate': value['flowRate'],
    };
}

