/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerItemData } from './SchedulerItemData';
import {
    SchedulerItemDataFromJSON,
    SchedulerItemDataFromJSONTyped,
    SchedulerItemDataToJSON,
} from './SchedulerItemData';
import type { SchedulerEvent } from './SchedulerEvent';
import {
    SchedulerEventFromJSON,
    SchedulerEventFromJSONTyped,
    SchedulerEventToJSON,
} from './SchedulerEvent';

/**
 * 
 * @export
 * @interface SchedulerItem
 */
export interface SchedulerItem {
    /**
     * 
     * @type {string}
     * @memberof SchedulerItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerItem
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerItem
     */
    type: SchedulerItemTypeEnum;
    /**
     * 
     * @type {Array<SchedulerItem>}
     * @memberof SchedulerItem
     */
    items: Array<SchedulerItem>;
    /**
     * 
     * @type {Array<SchedulerEvent>}
     * @memberof SchedulerItem
     */
    events: Array<SchedulerEvent>;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerItem
     */
    availableBorderDateTo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerItem
     */
    availableBorderDateFrom?: Date;
    /**
     * 
     * @type {SchedulerItemData}
     * @memberof SchedulerItem
     */
    data?: SchedulerItemData;
}


/**
 * @export
 */
export const SchedulerItemTypeEnum = {
    Root: 'ROOT',
    Field: 'FIELD',
    Pad: 'PAD',
    Well: 'WELL',
    Contractor: 'CONTRACTOR',
    Service: 'SERVICE',
    BuildingPlan: 'BUILDING_PLAN',
    BuildingFact: 'BUILDING_FACT',
    Incident: 'INCIDENT',
    DrillingCompany: 'DRILLING_COMPANY',
    DrillingRig: 'DRILLING_RIG',
    PadKgsBuilding: 'PAD_KGS_BUILDING',
    PadKgsArrangement: 'PAD_KGS_ARRANGEMENT',
    PadBuildingWell: 'PAD_BUILDING_WELL',
    PadCompletion: 'PAD_COMPLETION',
    Device: 'DEVICE',
    DeviceType: 'DEVICE_TYPE',
    TechnicalMaintenance: 'TECHNICAL_MAINTENANCE'
} as const;
export type SchedulerItemTypeEnum = typeof SchedulerItemTypeEnum[keyof typeof SchedulerItemTypeEnum];


/**
 * Check if a given object implements the SchedulerItem interface.
 */
export function instanceOfSchedulerItem(value: object): value is SchedulerItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('parentId' in value) || value['parentId'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('events' in value) || value['events'] === undefined) return false;
    return true;
}

export function SchedulerItemFromJSON(json: any): SchedulerItem {
    return SchedulerItemFromJSONTyped(json, false);
}

export function SchedulerItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': json['parentId'],
        'title': json['title'],
        'type': json['type'],
        'items': ((json['items'] as Array<any>).map(SchedulerItemFromJSON)),
        'events': ((json['events'] as Array<any>).map(SchedulerEventFromJSON)),
        'availableBorderDateTo': json['availableBorderDateTo'] == null ? undefined : (new Date(json['availableBorderDateTo'])),
        'availableBorderDateFrom': json['availableBorderDateFrom'] == null ? undefined : (new Date(json['availableBorderDateFrom'])),
        'data': json['data'] == null ? undefined : SchedulerItemDataFromJSON(json['data']),
    };
}

export function SchedulerItemToJSON(value?: SchedulerItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'parentId': value['parentId'],
        'title': value['title'],
        'type': value['type'],
        'items': ((value['items'] as Array<any>).map(SchedulerItemToJSON)),
        'events': ((value['events'] as Array<any>).map(SchedulerEventToJSON)),
        'availableBorderDateTo': value['availableBorderDateTo'] == null ? undefined : ((value['availableBorderDateTo']).toISOString()),
        'availableBorderDateFrom': value['availableBorderDateFrom'] == null ? undefined : ((value['availableBorderDateFrom']).toISOString()),
        'data': SchedulerItemDataToJSON(value['data']),
    };
}

