/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {SseEmitter} from '../models/index';
import {SseEmitterFromJSON} from '../models/index';

export interface SubscribeToWellPersonMonitoringRequest {
    wellId: number;
    scene: SubscribeToWellPersonMonitoringSceneEnum;
}

/**
 * 
 */
export class VideoRecognitionControllerApi extends runtime.BaseAPI {

    /**
     */
    async subscribeToWellPersonMonitoringRaw(requestParameters: SubscribeToWellPersonMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SseEmitter>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling subscribeToWellPersonMonitoring().'
            );
        }

        if (requestParameters['scene'] == null) {
            throw new runtime.RequiredError(
                'scene',
                'Required parameter "scene" was null or undefined when calling subscribeToWellPersonMonitoring().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['scene'] != null) {
            queryParameters['scene'] = requestParameters['scene'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/wells/{wellId}/persons`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SseEmitterFromJSON(jsonValue));
    }

    /**
     */
    async subscribeToWellPersonMonitoring(requestParameters: SubscribeToWellPersonMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SseEmitter> {
        const response = await this.subscribeToWellPersonMonitoringRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SubscribeToWellPersonMonitoringSceneEnum = {
    RotorTable: 'ROTOR_TABLE',
    Candles: 'CANDLES',
    UpperPlatform: 'UPPER_PLATFORM',
    Winch: 'WINCH',
    Screen: 'SCREEN',
    PumpBlock: 'PUMP_BLOCK',
    Pvo: 'PVO'
} as const;
export type SubscribeToWellPersonMonitoringSceneEnum = typeof SubscribeToWellPersonMonitoringSceneEnum[keyof typeof SubscribeToWellPersonMonitoringSceneEnum];
