/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTemplateTrackCurveUnit } from './MonitoringTemplateTrackCurveUnit';
import {
    MonitoringTemplateTrackCurveUnitFromJSON,
    MonitoringTemplateTrackCurveUnitFromJSONTyped,
    MonitoringTemplateTrackCurveUnitToJSON,
} from './MonitoringTemplateTrackCurveUnit';
import type { MonitoringTemplateTrackCurveMnemonic } from './MonitoringTemplateTrackCurveMnemonic';
import {
    MonitoringTemplateTrackCurveMnemonicFromJSON,
    MonitoringTemplateTrackCurveMnemonicFromJSONTyped,
    MonitoringTemplateTrackCurveMnemonicToJSON,
} from './MonitoringTemplateTrackCurveMnemonic';

/**
 * 
 * @export
 * @interface MonitoringTemplateTrackCurve
 */
export interface MonitoringTemplateTrackCurve {
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackCurve
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackCurve
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    limitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    limitMax?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringTemplateTrackCurve
     */
    limitMinAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringTemplateTrackCurve
     */
    limitMaxAuto?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    curveLimitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    curveLimitMax?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackCurve
     */
    curveLimitColor?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    lineThickness?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackCurve
     */
    lineColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackCurve
     */
    lineType?: MonitoringTemplateTrackCurveLineTypeEnum;
    /**
     * 
     * @type {MonitoringTemplateTrackCurveUnit}
     * @memberof MonitoringTemplateTrackCurve
     */
    unit?: MonitoringTemplateTrackCurveUnit;
    /**
     * 
     * @type {MonitoringTemplateTrackCurveMnemonic}
     * @memberof MonitoringTemplateTrackCurve
     */
    mnemonic?: MonitoringTemplateTrackCurveMnemonic;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrackCurve
     */
    orderNumber?: number;
}


/**
 * @export
 */
export const MonitoringTemplateTrackCurveLineTypeEnum = {
    Line: 'LINE',
    Point: 'POINT',
    Value: 'VALUE'
} as const;
export type MonitoringTemplateTrackCurveLineTypeEnum = typeof MonitoringTemplateTrackCurveLineTypeEnum[keyof typeof MonitoringTemplateTrackCurveLineTypeEnum];


/**
 * Check if a given object implements the MonitoringTemplateTrackCurve interface.
 */
export function instanceOfMonitoringTemplateTrackCurve(value: object): value is MonitoringTemplateTrackCurve {
    return true;
}

export function MonitoringTemplateTrackCurveFromJSON(json: any): MonitoringTemplateTrackCurve {
    return MonitoringTemplateTrackCurveFromJSONTyped(json, false);
}

export function MonitoringTemplateTrackCurveFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTemplateTrackCurve {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'limitMin': json['limitMin'] == null ? undefined : json['limitMin'],
        'limitMax': json['limitMax'] == null ? undefined : json['limitMax'],
        'limitMinAuto': json['limitMinAuto'] == null ? undefined : json['limitMinAuto'],
        'limitMaxAuto': json['limitMaxAuto'] == null ? undefined : json['limitMaxAuto'],
        'curveLimitMin': json['curveLimitMin'] == null ? undefined : json['curveLimitMin'],
        'curveLimitMax': json['curveLimitMax'] == null ? undefined : json['curveLimitMax'],
        'curveLimitColor': json['curveLimitColor'] == null ? undefined : json['curveLimitColor'],
        'lineThickness': json['lineThickness'] == null ? undefined : json['lineThickness'],
        'lineColor': json['lineColor'] == null ? undefined : json['lineColor'],
        'lineType': json['lineType'] == null ? undefined : json['lineType'],
        'unit': json['unit'] == null ? undefined : MonitoringTemplateTrackCurveUnitFromJSON(json['unit']),
        'mnemonic': json['mnemonic'] == null ? undefined : MonitoringTemplateTrackCurveMnemonicFromJSON(json['mnemonic']),
        'orderNumber': json['orderNumber'] == null ? undefined : json['orderNumber'],
    };
}

export function MonitoringTemplateTrackCurveToJSON(value?: MonitoringTemplateTrackCurve | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'limitMin': value['limitMin'],
        'limitMax': value['limitMax'],
        'limitMinAuto': value['limitMinAuto'],
        'limitMaxAuto': value['limitMaxAuto'],
        'curveLimitMin': value['curveLimitMin'],
        'curveLimitMax': value['curveLimitMax'],
        'curveLimitColor': value['curveLimitColor'],
        'lineThickness': value['lineThickness'],
        'lineColor': value['lineColor'],
        'lineType': value['lineType'],
        'unit': MonitoringTemplateTrackCurveUnitToJSON(value['unit']),
        'mnemonic': MonitoringTemplateTrackCurveMnemonicToJSON(value['mnemonic']),
        'orderNumber': value['orderNumber'],
    };
}

