import {
    APILASApi,
    ConfigurationParameters,
    DrillingMachinesEfficiencyApi,
    ErrorContext,
    LogDataExportControllerApi,
    MonitoringConnectionApi,
    MonitoringToolsApi,
    CommentApi,
    DrmSectionOperationTableControllerApi,
    DrmRevisionControllerApi,
    DrmSummaryControllerApi
} from './back';
import {Configuration as ApiConfiguration} from '@core/api/back';
import {APIApi} from '@core/api/back';
import {AuthService} from '@services/AuthService';

const baseConfigurationParameters: ConfigurationParameters = {
    credentials: 'include',
    basePath: '',
    accessToken: async () => {
        if (AuthService.isAuthenticated()) {
            await AuthService.refreshToken();
        }

        return Promise.resolve(AuthService.getToken() as string);
    },
    middleware: [
        {
            // TODO второе потенциальное место по отлову ошибок апи
            onError(context: ErrorContext): Promise<Response | void> {
                console.log('middleware api error', context);
                return Promise.resolve();
            },
        },
    ],
};

//const baseConfigurationGateway = new Configuration(baseConfigurationParameters);

const baseConfiguration = new ApiConfiguration(baseConfigurationParameters);
export const ProjectTreeApi = new APIApi(baseConfiguration);
export const WidgetsApi = new APIApi(baseConfiguration);
export const TemplatesApi = new APIApi(baseConfiguration);
export const WellDataApi = new APIApi(baseConfiguration);
export const BaseApi = new APIApi(baseConfiguration);
export const LasApi = new APILASApi(baseConfiguration);
export const WitsmlApi = new APIApi(baseConfiguration);
export const monitoringConnectionApi = new MonitoringConnectionApi(baseConfiguration);
export const drillingMachinesEfficiencyApi = new DrillingMachinesEfficiencyApi(baseConfiguration);
export const logDataExportControllerApi = new LogDataExportControllerApi(baseConfiguration);
export const monitoringToolsApi = new MonitoringToolsApi(baseConfiguration);
export const commentApi = new CommentApi(baseConfiguration);
export const drmSectionOperationTableControllerApi = new DrmSectionOperationTableControllerApi(baseConfiguration);
export const drmRevisionControllerApi = new DrmRevisionControllerApi(baseConfiguration);
export const drmSummaryControllerApi = new DrmSummaryControllerApi(baseConfiguration);


