/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTemplateTrackCurve } from './MonitoringTemplateTrackCurve';
import {
    MonitoringTemplateTrackCurveFromJSON,
    MonitoringTemplateTrackCurveFromJSONTyped,
    MonitoringTemplateTrackCurveToJSON,
} from './MonitoringTemplateTrackCurve';

/**
 * 
 * @export
 * @interface MonitoringTemplateTrack
 */
export interface MonitoringTemplateTrack {
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrack
     */
    id?: string;
    /**
     * 
     * @type {Array<MonitoringTemplateTrackCurve>}
     * @memberof MonitoringTemplateTrack
     */
    curves?: Array<MonitoringTemplateTrackCurve>;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTemplateTrack
     */
    orderNumber?: number;
}

/**
 * Check if a given object implements the MonitoringTemplateTrack interface.
 */
export function instanceOfMonitoringTemplateTrack(value: object): value is MonitoringTemplateTrack {
    return true;
}

export function MonitoringTemplateTrackFromJSON(json: any): MonitoringTemplateTrack {
    return MonitoringTemplateTrackFromJSONTyped(json, false);
}

export function MonitoringTemplateTrackFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTemplateTrack {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'curves': json['curves'] == null ? undefined : ((json['curves'] as Array<any>).map(MonitoringTemplateTrackCurveFromJSON)),
        'orderNumber': json['orderNumber'] == null ? undefined : json['orderNumber'],
    };
}

export function MonitoringTemplateTrackToJSON(value?: MonitoringTemplateTrack | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(MonitoringTemplateTrackCurveToJSON)),
        'orderNumber': value['orderNumber'],
    };
}

