/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramStageInterval
 */
export interface AutoDrillingProgramStageInterval {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStageInterval
     */
    stageId: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramStageInterval
     */
    depthFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramStageInterval
     */
    depthTo?: number;
}

/**
 * Check if a given object implements the AutoDrillingProgramStageInterval interface.
 */
export function instanceOfAutoDrillingProgramStageInterval(value: object): value is AutoDrillingProgramStageInterval {
    if (!('stageId' in value) || value['stageId'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramStageIntervalFromJSON(json: any): AutoDrillingProgramStageInterval {
    return AutoDrillingProgramStageIntervalFromJSONTyped(json, false);
}

export function AutoDrillingProgramStageIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramStageInterval {
    if (json == null) {
        return json;
    }
    return {
        
        'stageId': json['stageId'],
        'depthFrom': json['depthFrom'] == null ? undefined : json['depthFrom'],
        'depthTo': json['depthTo'] == null ? undefined : json['depthTo'],
    };
}

export function AutoDrillingProgramStageIntervalToJSON(value?: AutoDrillingProgramStageInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stageId': value['stageId'],
        'depthFrom': value['depthFrom'],
        'depthTo': value['depthTo'],
    };
}

