/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationPeriod } from './OperationPeriod';
import {
    OperationPeriodFromJSON,
    OperationPeriodFromJSONTyped,
    OperationPeriodToJSON,
} from './OperationPeriod';

/**
 * 
 * @export
 * @interface OperationTimeDuration
 */
export interface OperationTimeDuration {
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDuration
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDuration
     */
    averageTime?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDuration
     */
    periodsSize?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationTimeDuration
     */
    deviation?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OperationTimeDuration
     */
    timeDuration?: { [key: string]: number; };
    /**
     * 
     * @type {Array<OperationPeriod>}
     * @memberof OperationTimeDuration
     */
    periods?: Array<OperationPeriod>;
}

/**
 * Check if a given object implements the OperationTimeDuration interface.
 */
export function instanceOfOperationTimeDuration(value: object): value is OperationTimeDuration {
    return true;
}

export function OperationTimeDurationFromJSON(json: any): OperationTimeDuration {
    return OperationTimeDurationFromJSONTyped(json, false);
}

export function OperationTimeDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTimeDuration {
    if (json == null) {
        return json;
    }
    return {
        
        'totalTime': json['totalTime'] == null ? undefined : json['totalTime'],
        'averageTime': json['averageTime'] == null ? undefined : json['averageTime'],
        'periodsSize': json['periodsSize'] == null ? undefined : json['periodsSize'],
        'deviation': json['deviation'] == null ? undefined : json['deviation'],
        'timeDuration': json['timeDuration'] == null ? undefined : json['timeDuration'],
        'periods': json['periods'] == null ? undefined : ((json['periods'] as Array<any>).map(OperationPeriodFromJSON)),
    };
}

export function OperationTimeDurationToJSON(value?: OperationTimeDuration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalTime': value['totalTime'],
        'averageTime': value['averageTime'],
        'periodsSize': value['periodsSize'],
        'deviation': value['deviation'],
        'timeDuration': value['timeDuration'],
        'periods': value['periods'] == null ? undefined : ((value['periods'] as Array<any>).map(OperationPeriodToJSON)),
    };
}

