import {Autocomplete, TextField} from '@mui/material';
import {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useWellsData} from 'pages/WellsPage/utils';
import {setWellNumber} from 'slices/wellNumberSlice';
import {RouteParams} from 'utils';
import {selectWellNumber} from 'slices';
import {activeConnectionId$} from '@pages/monitoring/controllers/dashboardSettingsController';

type WellSelectProps = {
    width?: number;
    label?: string;
};
export const WellSelect = ({width = 250, label = 'Скважина'}: WellSelectProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams<RouteParams>();
    const wellNumber = useSelector(selectWellNumber);

    const [store, updateWellData] = useWellsData();
    const [dynamicWidth, setDynamicWidth] = useState(width);

    const wellOptions = useMemo(
        () =>
            store?.data?.map((well) => ({
                label: `${well.padTitle}/${well.name}/${well.fieldTitle}`,
                value: well.id,
            })) ?? [],
        [store?.data],
    );

    const currentWell = useMemo(
        () =>
            params.wellId || wellNumber
                ? wellOptions.find(
                      (well) =>
                          (params.wellId || wellNumber) &&
                          well.value === +(params.wellId || wellNumber),
                  )
                : null,
        [params.wellId, wellNumber, wellOptions],
    );

    useEffect(() => {
        updateWellData();
    }, [updateWellData]);

    useEffect(() => {
        if (currentWell?.label) {
            const charWidth = 12;
            const textWidth = currentWell.label.length * charWidth;

            if (textWidth > width) {
                setDynamicWidth(textWidth + 20);
            } else {
                setDynamicWidth(width);
            }
        }
    }, [currentWell, width]);

    const handleWellChange = (
        event: SyntheticEvent<Element, Event>,
        newWell: {label: string; value: number} | null,
    ) => {
        if (newWell) {
            activeConnectionId$.set('');
            dispatch(setWellNumber(newWell.value));
        }

        if (!newWell) {
            dispatch(setWellNumber(''));
        }

        if (newWell && params.wellId) {
            // we need to navigate to the same page (table/file/calendar) with the same search params but with new wellId
            const newPath = location.pathname
                .replace(params.wellId, `${newWell.value}`)
                .concat(location.search);

            navigate(newPath);
        }
    };

    // Изначальный эффект, чтобы установить значение wellNumber в стор при инициализации
    useEffect(() => {
        if (!!currentWell && !wellNumber) {
            dispatch(setWellNumber(currentWell.value));
        }
    }, [currentWell, dispatch, wellNumber]);

    return (
        <Autocomplete
            disablePortal
            options={wellOptions}
            sx={{
                width: `${dynamicWidth}px`,
            }}
            value={currentWell ?? null}
            getOptionLabel={(option) => option.label}
            onChange={handleWellChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='standard'
                    label={label}
                    size='small'
                />
            )}
        />
    );
};
