/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectionInfo } from './ConnectionInfo';
import {
    ConnectionInfoFromJSON,
    ConnectionInfoFromJSONTyped,
    ConnectionInfoToJSON,
} from './ConnectionInfo';

/**
 * 
 * @export
 * @interface MonitoringWell
 */
export interface MonitoringWell {
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    wellprofileType?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    itemOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    wellheadCoordinatesOffsetToTheNorth?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    wellheadCoordinatesOffsetToTheEast?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    verticalAzimuth?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    totalCorrection?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    projectTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    contractId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    contractTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    padId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    padTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWell
     */
    fieldId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWell
     */
    fieldTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringWell
     */
    isHidden?: boolean;
    /**
     * 
     * @type {Array<ConnectionInfo>}
     * @memberof MonitoringWell
     */
    connections?: Array<ConnectionInfo>;
}

/**
 * Check if a given object implements the MonitoringWell interface.
 */
export function instanceOfMonitoringWell(value: object): value is MonitoringWell {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function MonitoringWellFromJSON(json: any): MonitoringWell {
    return MonitoringWellFromJSONTyped(json, false);
}

export function MonitoringWellFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringWell {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'],
        'status': json['status'],
        'wellprofileType': json['wellprofileType'] == null ? undefined : json['wellprofileType'],
        'customer': json['customer'] == null ? undefined : json['customer'],
        'itemOrder': json['itemOrder'] == null ? undefined : json['itemOrder'],
        'wellheadCoordinatesOffsetToTheNorth': json['wellheadCoordinatesOffsetToTheNorth'] == null ? undefined : json['wellheadCoordinatesOffsetToTheNorth'],
        'wellheadCoordinatesOffsetToTheEast': json['wellheadCoordinatesOffsetToTheEast'] == null ? undefined : json['wellheadCoordinatesOffsetToTheEast'],
        'altitude': json['altitude'] == null ? undefined : json['altitude'],
        'verticalAzimuth': json['verticalAzimuth'] == null ? undefined : json['verticalAzimuth'],
        'totalCorrection': json['totalCorrection'] == null ? undefined : json['totalCorrection'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'projectTitle': json['projectTitle'] == null ? undefined : json['projectTitle'],
        'contractId': json['contractId'] == null ? undefined : json['contractId'],
        'contractTitle': json['contractTitle'] == null ? undefined : json['contractTitle'],
        'padId': json['padId'] == null ? undefined : json['padId'],
        'padTitle': json['padTitle'] == null ? undefined : json['padTitle'],
        'fieldId': json['fieldId'] == null ? undefined : json['fieldId'],
        'fieldTitle': json['fieldTitle'] == null ? undefined : json['fieldTitle'],
        'isHidden': json['isHidden'] == null ? undefined : json['isHidden'],
        'connections': json['connections'] == null ? undefined : ((json['connections'] as Array<any>).map(ConnectionInfoFromJSON)),
    };
}

export function MonitoringWellToJSON(value?: MonitoringWell | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'wellprofileType': value['wellprofileType'],
        'customer': value['customer'],
        'itemOrder': value['itemOrder'],
        'wellheadCoordinatesOffsetToTheNorth': value['wellheadCoordinatesOffsetToTheNorth'],
        'wellheadCoordinatesOffsetToTheEast': value['wellheadCoordinatesOffsetToTheEast'],
        'altitude': value['altitude'],
        'verticalAzimuth': value['verticalAzimuth'],
        'totalCorrection': value['totalCorrection'],
        'projectId': value['projectId'],
        'projectTitle': value['projectTitle'],
        'contractId': value['contractId'],
        'contractTitle': value['contractTitle'],
        'padId': value['padId'],
        'padTitle': value['padTitle'],
        'fieldId': value['fieldId'],
        'fieldTitle': value['fieldTitle'],
        'isHidden': value['isHidden'],
        'connections': value['connections'] == null ? undefined : ((value['connections'] as Array<any>).map(ConnectionInfoToJSON)),
    };
}

