/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrictionCoefficient
 */
export interface FrictionCoefficient {
    /**
     * 
     * @type {number}
     * @memberof FrictionCoefficient
     */
    valueInCasingColumn: number;
    /**
     * 
     * @type {number}
     * @memberof FrictionCoefficient
     */
    valueInOpenTrunk: number;
}

/**
 * Check if a given object implements the FrictionCoefficient interface.
 */
export function instanceOfFrictionCoefficient(value: object): value is FrictionCoefficient {
    if (!('valueInCasingColumn' in value) || value['valueInCasingColumn'] === undefined) return false;
    if (!('valueInOpenTrunk' in value) || value['valueInOpenTrunk'] === undefined) return false;
    return true;
}

export function FrictionCoefficientFromJSON(json: any): FrictionCoefficient {
    return FrictionCoefficientFromJSONTyped(json, false);
}

export function FrictionCoefficientFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrictionCoefficient {
    if (json == null) {
        return json;
    }
    return {
        
        'valueInCasingColumn': json['valueInCasingColumn'],
        'valueInOpenTrunk': json['valueInOpenTrunk'],
    };
}

export function FrictionCoefficientToJSON(value?: FrictionCoefficient | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'valueInCasingColumn': value['valueInCasingColumn'],
        'valueInOpenTrunk': value['valueInOpenTrunk'],
    };
}

