import {AuthService} from '@services/AuthService';
import {EventSource} from 'extended-eventsource';

type EventStreamArgs<T> = {
    url: string;
    headers?: Record<string, string>;
    onMessage: (data: T) => void;
    isUnmountedRef?: React.MutableRefObject<boolean>;
};

const RECONNECT_DELAY = 5 * 1000;

const refreshTokenAndReconnect = async (
    reconnectFn: () => EventSource,
    isUnmountedRef?: React.MutableRefObject<boolean>,
) => {
    if (isUnmountedRef?.current) return;

    await AuthService.refreshToken();

    if (isUnmountedRef?.current) return;

    reconnectFn();
};

export function createEventSource<T>({
    url,
    onMessage,
    headers,
    isUnmountedRef,
}: EventStreamArgs<T>) {
    const eventSource = new EventSource(url, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${AuthService.getToken()}`,
            ...headers,
        },
        retry: RECONNECT_DELAY,
    });

    eventSource.addEventListener('message', (e) => {
        const data = JSON.parse(e.data) as T;
        if (data) {
            onMessage(data);
        }
    });

    eventSource.addEventListener('error', (e) => {
        console.error(`Error in event stream ${url}`, e);
        eventSource.close();

        if (isUnmountedRef?.current) {
            return;
        }
        setTimeout(
            () =>
                refreshTokenAndReconnect(
                    () => createEventSource({url, onMessage, headers, isUnmountedRef}),
                    isUnmountedRef,
                ),
            RECONNECT_DELAY,
        );
    });

    return eventSource;
}
