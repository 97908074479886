import {useQuery, useQueryClient} from '@tanstack/react-query';
import {getMonitoringWells} from '@services/MonitoringWells/MonitoringWells';

export const useMonitoringWellsData = () => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['monitoringWells'],
        queryFn: getMonitoringWells,
    });

    const refetchWells = () => queryClient.invalidateQueries({queryKey: ['monitoringWells']});

    return {
        ...query,
        refetchWells,
    };
};
