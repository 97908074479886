/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MnemonicResponse
 */
export interface MnemonicResponse {
    /**
     * 
     * @type {string}
     * @memberof MnemonicResponse
     */
    mnemonic?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MnemonicResponse
     */
    values?: Array<number>;
}

/**
 * Check if a given object implements the MnemonicResponse interface.
 */
export function instanceOfMnemonicResponse(value: object): value is MnemonicResponse {
    return true;
}

export function MnemonicResponseFromJSON(json: any): MnemonicResponse {
    return MnemonicResponseFromJSONTyped(json, false);
}

export function MnemonicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'mnemonic': json['mnemonic'] == null ? undefined : json['mnemonic'],
        'values': json['values'] == null ? undefined : json['values'],
    };
}

export function MnemonicResponseToJSON(value?: MnemonicResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mnemonic': value['mnemonic'],
        'values': value['values'],
    };
}

