/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTemplate
 */
export interface MonitoringTemplate {
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplate
     */
    userOwner?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringTemplate
     */
    basic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplate
     */
    description?: string;
}

/**
 * Check if a given object implements the MonitoringTemplate interface.
 */
export function instanceOfMonitoringTemplate(value: object): value is MonitoringTemplate {
    return true;
}

export function MonitoringTemplateFromJSON(json: any): MonitoringTemplate {
    return MonitoringTemplateFromJSONTyped(json, false);
}

export function MonitoringTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'userOwner': json['userOwner'] == null ? undefined : json['userOwner'],
        'basic': json['basic'] == null ? undefined : json['basic'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function MonitoringTemplateToJSON(value?: MonitoringTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'userOwner': value['userOwner'],
        'basic': value['basic'],
        'description': value['description'],
    };
}

