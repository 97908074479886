/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigOperationInfoResponse
 */
export interface DrillingRigOperationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    fieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    padName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    wellName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    operationName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    casingColumnName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigOperationInfoResponse
     */
    wellboreName?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigOperationInfoResponse
     */
    endDepth?: number;
}

/**
 * Check if a given object implements the DrillingRigOperationInfoResponse interface.
 */
export function instanceOfDrillingRigOperationInfoResponse(value: object): value is DrillingRigOperationInfoResponse {
    return true;
}

export function DrillingRigOperationInfoResponseFromJSON(json: any): DrillingRigOperationInfoResponse {
    return DrillingRigOperationInfoResponseFromJSONTyped(json, false);
}

export function DrillingRigOperationInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigOperationInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'fieldName': json['fieldName'] == null ? undefined : json['fieldName'],
        'padName': json['padName'] == null ? undefined : json['padName'],
        'wellName': json['wellName'] == null ? undefined : json['wellName'],
        'operationName': json['operationName'] == null ? undefined : json['operationName'],
        'casingColumnName': json['casingColumnName'] == null ? undefined : json['casingColumnName'],
        'wellboreName': json['wellboreName'] == null ? undefined : json['wellboreName'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
    };
}

export function DrillingRigOperationInfoResponseToJSON(value?: DrillingRigOperationInfoResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fieldName': value['fieldName'],
        'padName': value['padName'],
        'wellName': value['wellName'],
        'operationName': value['operationName'],
        'casingColumnName': value['casingColumnName'],
        'wellboreName': value['wellboreName'],
        'endDepth': value['endDepth'],
    };
}

