/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SummaryTableTittleDto } from './SummaryTableTittleDto';
import {
    SummaryTableTittleDtoFromJSON,
    SummaryTableTittleDtoFromJSONTyped,
    SummaryTableTittleDtoToJSON,
} from './SummaryTableTittleDto';

/**
 * 
 * @export
 * @interface DrmSummaryTableDto
 */
export interface DrmSummaryTableDto {
    /**
     * 
     * @type {string}
     * @memberof DrmSummaryTableDto
     */
    sectionName?: string;
    /**
     * 
     * @type {Array<SummaryTableTittleDto>}
     * @memberof DrmSummaryTableDto
     */
    schema?: Array<SummaryTableTittleDto>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DrmSummaryTableDto
     */
    aggregatedData?: { [key: string]: object; };
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof DrmSummaryTableDto
     */
    data?: Array<{ [key: string]: object; }>;
}

/**
 * Check if a given object implements the DrmSummaryTableDto interface.
 */
export function instanceOfDrmSummaryTableDto(value: object): value is DrmSummaryTableDto {
    return true;
}

export function DrmSummaryTableDtoFromJSON(json: any): DrmSummaryTableDto {
    return DrmSummaryTableDtoFromJSONTyped(json, false);
}

export function DrmSummaryTableDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmSummaryTableDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sectionName': json['sectionName'] == null ? undefined : json['sectionName'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SummaryTableTittleDtoFromJSON)),
        'aggregatedData': json['aggregatedData'] == null ? undefined : json['aggregatedData'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function DrmSummaryTableDtoToJSON(value?: DrmSummaryTableDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sectionName': value['sectionName'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SummaryTableTittleDtoToJSON)),
        'aggregatedData': value['aggregatedData'],
        'data': value['data'],
    };
}

