/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTemplateTrackCurveUnit } from './MonitoringTemplateTrackCurveUnit';
import {
    MonitoringTemplateTrackCurveUnitFromJSON,
    MonitoringTemplateTrackCurveUnitFromJSONTyped,
    MonitoringTemplateTrackCurveUnitToJSON,
} from './MonitoringTemplateTrackCurveUnit';

/**
 * 
 * @export
 * @interface MnemonicWithDefaultUnit
 */
export interface MnemonicWithDefaultUnit {
    /**
     * 
     * @type {string}
     * @memberof MnemonicWithDefaultUnit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicWithDefaultUnit
     */
    mnemonicLong?: string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicWithDefaultUnit
     */
    mnemonicShort?: string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicWithDefaultUnit
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicWithDefaultUnit
     */
    description?: string;
    /**
     * 
     * @type {MonitoringTemplateTrackCurveUnit}
     * @memberof MnemonicWithDefaultUnit
     */
    defaultUnit?: MonitoringTemplateTrackCurveUnit;
}

/**
 * Check if a given object implements the MnemonicWithDefaultUnit interface.
 */
export function instanceOfMnemonicWithDefaultUnit(value: object): value is MnemonicWithDefaultUnit {
    return true;
}

export function MnemonicWithDefaultUnitFromJSON(json: any): MnemonicWithDefaultUnit {
    return MnemonicWithDefaultUnitFromJSONTyped(json, false);
}

export function MnemonicWithDefaultUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicWithDefaultUnit {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'mnemonicLong': json['mnemonicLong'] == null ? undefined : json['mnemonicLong'],
        'mnemonicShort': json['mnemonicShort'] == null ? undefined : json['mnemonicShort'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'defaultUnit': json['defaultUnit'] == null ? undefined : MonitoringTemplateTrackCurveUnitFromJSON(json['defaultUnit']),
    };
}

export function MnemonicWithDefaultUnitToJSON(value?: MnemonicWithDefaultUnit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mnemonicLong': value['mnemonicLong'],
        'mnemonicShort': value['mnemonicShort'],
        'name': value['name'],
        'description': value['description'],
        'defaultUnit': MonitoringTemplateTrackCurveUnitToJSON(value['defaultUnit']),
    };
}

