import {useCallback, useMemo, useState} from 'react';
import {invokeAsync} from '../../../utils';
import {WellEntity} from '../types';
import {WellsService} from '../../../services/Wells/WellsService';
import {TablePaginationConfig} from 'utils/hooks/useNewPageData';
import {getTableGetParams} from 'utils/hooks/useNewPageData/utils';
import {SortConfig} from 'components/NewTable/types';

export type PageDataStore<T> = {
    data: T[];
    total: number;
    page: number;
    pageSize: number;
};

export const useWellsData = (isMonitoring?: boolean) => {
    const [store, setStore] = useState<PageDataStore<WellEntity>>();

    const update = useCallback((pagination?: TablePaginationConfig, sort?: SortConfig) => {
        void invokeAsync(async () => {
            const service = WellsService.getInstance();
            const data = await service.getWells(getTableGetParams(pagination, sort));

            setStore({
                data,
                total: data.length,
                page: 0,
                pageSize: 1000,
            });
        });
    }, []);

    return useMemo(() => [store, update] as const, [store, update]);
};
