import {VariantType} from 'notistack';
import {Group} from '@services/Table/types';
import {NamedExoticComponent} from 'react';

export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export type DefaultReducerState<Type> = {
    data: Type;
};

export type DictionaryItem = {value: string; [key: string]: any};

export type SelectDictionary = Record<string, DictionaryItem[]>;

export type NotificationType = {
    text?: string;
    title?: string;
    variant: VariantType;
    className?: string;
};

export type NotificationState = {
    data: NotificationType | null;
};

export interface RootState {
    menu: {
        groups: Group[];
        items: MenuItem[];
        loading: string[];
        menuLoading: boolean;
    };
    dictionaries: DefaultReducerState<SelectDictionary>;
    wellNumber: DefaultReducerState<string>;
    components: Record<string, unknown>;
    colorMode: {colorMode: 'dark' | 'light'};
    notifications: NotificationState;
}

export enum MenuItemType {
    Group = 'GROUP',
    Table = 'TABLE',
    Files = 'FILES',
    CalendarOperations = 'OPERATIONS_CALENDAR',
    LiquorActualCalendar = 'LIQUOR_ACTUAL_CALENDAR',
    KnbkDrillingGroups = 'KNBK_DRILLING_GROUPS',
    KnbkGisGroups = 'KNBK_GIS_GROUPS',
    KnbkProjectGroups = 'KNBK_PROJECT_GROUPS',
    Nested = 'NESTED',
    CasingColumnDescentProtocol = 'CASING_COLUMN_DESCENT_PROTOCOL',
    Prices = 'PRICES',
    Npv = 'NPV',
    Ggd = 'GGD',
    Trajectory = 'TRAJECTORY',
    WellCommonDashboard = 'WELL_COMMON_DASHBOARD',
    KPODescentProtocol = 'KPO_DESCENT_PROTOCOL',
}

export type ServerMenuItem = {
    title: string;
    code: string;
    type: MenuItemType;
    findAllUrl: string | null;
    subgroups?: ServerMenuItem[];
};

export type ServerMenu = {
    title: null | string;
    code: string;
    subgroups: ServerMenuItem[];
    links: null | string;
};

export type MenuItem = {
    key: string | string[];
    label: string;
    findAllUrl: string | null;
    code: string;
    isGroup?: boolean;
    isFiles?: boolean;
    isCalendar?: boolean;
    children?: MenuItem[];
    type: MenuItemType;
    childToRedirectTo?: MenuItem;
};

export type NestedTab = {
    key: string | string[];
    label: string;
    url: string | string[];
    isNested: boolean;
    children: MenuItem[] | undefined;
};

export type CreateData<T> = Omit<T, 'id'>;

export type ParamsType = Record<string, any>;

export type RouteParams = {
    pageId: string;
    wellId: string;
    data?: string;
    id?: string;
    groupId?: string;
    projectId?: string;
    rigId?: string;
    lasId?: string;
    pageType?: string;
};

export enum GroupElementsServiceType {
    KNBK_PLAN = 'knbk-plan',
    KNBK_FACT = 'knbk-fact',
    KNBK_PROJECT = 'knbk-project',
    KNBK_GIS = 'knbk-gis',
    CCDP = 'casing-column-descent-protocol',
    STOWAGE_REGISTER = 'stowage-registers',
    KPODP = 'kpo-descent-protocol',
    KPO_STOWAGE_REGISTER = 'kpo-stowage-registers',
}

export type SchedulerParams = {
    dateFrom: string | null;
    dateTo: string | null;
};

export type PrivateComponent<P extends object> = NamedExoticComponent<P>;
