/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrmSectionCreateUpdateDto
 */
export interface DrmSectionCreateUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DrmSectionCreateUpdateDto
     */
    section: string;
    /**
     * 
     * @type {number}
     * @memberof DrmSectionCreateUpdateDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrmSectionCreateUpdateDto
     */
    endDepth: number;
}

/**
 * Check if a given object implements the DrmSectionCreateUpdateDto interface.
 */
export function instanceOfDrmSectionCreateUpdateDto(value: object): value is DrmSectionCreateUpdateDto {
    if (!('section' in value) || value['section'] === undefined) return false;
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('endDepth' in value) || value['endDepth'] === undefined) return false;
    return true;
}

export function DrmSectionCreateUpdateDtoFromJSON(json: any): DrmSectionCreateUpdateDto {
    return DrmSectionCreateUpdateDtoFromJSONTyped(json, false);
}

export function DrmSectionCreateUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmSectionCreateUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': json['section'],
        'startDepth': json['startDepth'],
        'endDepth': json['endDepth'],
    };
}

export function DrmSectionCreateUpdateDtoToJSON(value?: DrmSectionCreateUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': value['section'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
    };
}

