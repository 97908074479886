/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SPO
 */
export interface SPO {
    /**
     * 
     * @type {number}
     * @memberof SPO
     */
    rpm: number;
    /**
     * 
     * @type {number}
     * @memberof SPO
     */
    liftingSpeed: number;
}

/**
 * Check if a given object implements the SPO interface.
 */
export function instanceOfSPO(value: object): value is SPO {
    if (!('rpm' in value) || value['rpm'] === undefined) return false;
    if (!('liftingSpeed' in value) || value['liftingSpeed'] === undefined) return false;
    return true;
}

export function SPOFromJSON(json: any): SPO {
    return SPOFromJSONTyped(json, false);
}

export function SPOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SPO {
    if (json == null) {
        return json;
    }
    return {
        
        'rpm': json['rpm'],
        'liftingSpeed': json['liftingSpeed'],
    };
}

export function SPOToJSON(value?: SPO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rpm': value['rpm'],
        'liftingSpeed': value['liftingSpeed'],
    };
}

