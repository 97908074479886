/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramStatusInfo
 */
export interface AutoDrillingProgramStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStatusInfo
     */
    programId?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStatusInfo
     */
    status?: AutoDrillingProgramStatusInfoStatusEnum;
}


/**
 * @export
 */
export const AutoDrillingProgramStatusInfoStatusEnum = {
    Unknown: 'UNKNOWN',
    New: 'NEW',
    Delivered: 'DELIVERED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Started: 'STARTED',
    Finished: 'FINISHED',
    Failed: 'FAILED',
    Stopped: 'STOPPED'
} as const;
export type AutoDrillingProgramStatusInfoStatusEnum = typeof AutoDrillingProgramStatusInfoStatusEnum[keyof typeof AutoDrillingProgramStatusInfoStatusEnum];


/**
 * Check if a given object implements the AutoDrillingProgramStatusInfo interface.
 */
export function instanceOfAutoDrillingProgramStatusInfo(value: object): value is AutoDrillingProgramStatusInfo {
    return true;
}

export function AutoDrillingProgramStatusInfoFromJSON(json: any): AutoDrillingProgramStatusInfo {
    return AutoDrillingProgramStatusInfoFromJSONTyped(json, false);
}

export function AutoDrillingProgramStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramStatusInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'programId': json['programId'] == null ? undefined : json['programId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function AutoDrillingProgramStatusInfoToJSON(value?: AutoDrillingProgramStatusInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'programId': value['programId'],
        'status': value['status'],
    };
}

