/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface RecordingStaticMeasurementDrmOperationInterval
 */
export interface RecordingStaticMeasurementDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof RecordingStaticMeasurementDrmOperationInterval
     */
    measurementRecordingTime: number;
}

/**
 * Check if a given object implements the RecordingStaticMeasurementDrmOperationInterval interface.
 */
export function instanceOfRecordingStaticMeasurementDrmOperationInterval(value: object): value is RecordingStaticMeasurementDrmOperationInterval {
    if (!('measurementRecordingTime' in value) || value['measurementRecordingTime'] === undefined) return false;
    return true;
}

export function RecordingStaticMeasurementDrmOperationIntervalFromJSON(json: any): RecordingStaticMeasurementDrmOperationInterval {
    return RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped(json, false);
}

export function RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingStaticMeasurementDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'measurementRecordingTime': json['measurementRecordingTime'],
    };
}

export function RecordingStaticMeasurementDrmOperationIntervalToJSON(value?: RecordingStaticMeasurementDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'measurementRecordingTime': value['measurementRecordingTime'],
    };
}

