/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MnemonicResponse } from './MnemonicResponse';
import {
    MnemonicResponseFromJSON,
    MnemonicResponseFromJSONTyped,
    MnemonicResponseToJSON,
} from './MnemonicResponse';

/**
 * 
 * @export
 * @interface MnemonicDataResponse
 */
export interface MnemonicDataResponse {
    /**
     * 
     * @type {Array<MnemonicResponse>}
     * @memberof MnemonicDataResponse
     */
    mnemonics?: Array<MnemonicResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MnemonicDataResponse
     */
    indices?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MnemonicDataResponse
     */
    statuses?: Array<string>;
}

/**
 * Check if a given object implements the MnemonicDataResponse interface.
 */
export function instanceOfMnemonicDataResponse(value: object): value is MnemonicDataResponse {
    return true;
}

export function MnemonicDataResponseFromJSON(json: any): MnemonicDataResponse {
    return MnemonicDataResponseFromJSONTyped(json, false);
}

export function MnemonicDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicDataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'mnemonics': json['mnemonics'] == null ? undefined : ((json['mnemonics'] as Array<any>).map(MnemonicResponseFromJSON)),
        'indices': json['indices'] == null ? undefined : json['indices'],
        'statuses': json['statuses'] == null ? undefined : json['statuses'],
    };
}

export function MnemonicDataResponseToJSON(value?: MnemonicDataResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mnemonics': value['mnemonics'] == null ? undefined : ((value['mnemonics'] as Array<any>).map(MnemonicResponseToJSON)),
        'indices': value['indices'],
        'statuses': value['statuses'],
    };
}

