/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringWellboreDto } from './MonitoringWellboreDto';
import {
    MonitoringWellboreDtoFromJSON,
    MonitoringWellboreDtoFromJSONTyped,
    MonitoringWellboreDtoToJSON,
} from './MonitoringWellboreDto';

/**
 * 
 * @export
 * @interface MonitoringConnectionResponse
 */
export interface MonitoringConnectionResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitoringConnectionResponse
     */
    connectionId?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringConnectionResponse
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringConnectionResponse
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringConnectionResponse
     */
    witsmlType?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringConnectionResponse
     */
    templateId?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringConnectionResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<MonitoringWellboreDto>}
     * @memberof MonitoringConnectionResponse
     */
    wellbores?: Array<MonitoringWellboreDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringConnectionResponse
     */
    connected?: boolean;
}

/**
 * Check if a given object implements the MonitoringConnectionResponse interface.
 */
export function instanceOfMonitoringConnectionResponse(value: object): value is MonitoringConnectionResponse {
    return true;
}

export function MonitoringConnectionResponseFromJSON(json: any): MonitoringConnectionResponse {
    return MonitoringConnectionResponseFromJSONTyped(json, false);
}

export function MonitoringConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionId': json['connectionId'] == null ? undefined : json['connectionId'],
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'type': json['type'] == null ? undefined : json['type'],
        'witsmlType': json['witsmlType'] == null ? undefined : json['witsmlType'],
        'templateId': json['templateId'] == null ? undefined : json['templateId'],
        'name': json['name'] == null ? undefined : json['name'],
        'wellbores': json['wellbores'] == null ? undefined : ((json['wellbores'] as Array<any>).map(MonitoringWellboreDtoFromJSON)),
        'connected': json['connected'] == null ? undefined : json['connected'],
    };
}

export function MonitoringConnectionResponseToJSON(value?: MonitoringConnectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectionId': value['connectionId'],
        'wellId': value['wellId'],
        'type': value['type'],
        'witsmlType': value['witsmlType'],
        'templateId': value['templateId'],
        'name': value['name'],
        'wellbores': value['wellbores'] == null ? undefined : ((value['wellbores'] as Array<any>).map(MonitoringWellboreDtoToJSON)),
        'connected': value['connected'],
    };
}

