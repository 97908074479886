/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    mnemonic?: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    lastUpdatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    axis?: CommentDtoAxisEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    dateTimeAxisValue?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    depthAxisValue?: number;
}


/**
 * @export
 */
export const CommentDtoAxisEnum = {
    DateTime: 'DATE_TIME',
    Depth: 'DEPTH'
} as const;
export type CommentDtoAxisEnum = typeof CommentDtoAxisEnum[keyof typeof CommentDtoAxisEnum];


/**
 * Check if a given object implements the CommentDto interface.
 */
export function instanceOfCommentDto(value: object): value is CommentDto {
    return true;
}

export function CommentDtoFromJSON(json: any): CommentDto {
    return CommentDtoFromJSONTyped(json, false);
}

export function CommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'message': json['message'] == null ? undefined : json['message'],
        'mnemonic': json['mnemonic'] == null ? undefined : json['mnemonic'],
        'createdOn': json['createdOn'] == null ? undefined : (new Date(json['createdOn'])),
        'lastUpdatedOn': json['lastUpdatedOn'] == null ? undefined : (new Date(json['lastUpdatedOn'])),
        'axis': json['axis'] == null ? undefined : json['axis'],
        'dateTimeAxisValue': json['dateTimeAxisValue'] == null ? undefined : json['dateTimeAxisValue'],
        'depthAxisValue': json['depthAxisValue'] == null ? undefined : json['depthAxisValue'],
    };
}

export function CommentDtoToJSON(value?: CommentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'message': value['message'],
        'mnemonic': value['mnemonic'],
        'createdOn': value['createdOn'] == null ? undefined : ((value['createdOn']).toISOString()),
        'lastUpdatedOn': value['lastUpdatedOn'] == null ? undefined : ((value['lastUpdatedOn']).toISOString()),
        'axis': value['axis'],
        'dateTimeAxisValue': value['dateTimeAxisValue'],
        'depthAxisValue': value['depthAxisValue'],
    };
}

