/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface SwitchingOffThePumpDrmOperationInterval
 */
export interface SwitchingOffThePumpDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof SwitchingOffThePumpDrmOperationInterval
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof SwitchingOffThePumpDrmOperationInterval
     */
    residualPressureLimit: number;
}

/**
 * Check if a given object implements the SwitchingOffThePumpDrmOperationInterval interface.
 */
export function instanceOfSwitchingOffThePumpDrmOperationInterval(value: object): value is SwitchingOffThePumpDrmOperationInterval {
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('residualPressureLimit' in value) || value['residualPressureLimit'] === undefined) return false;
    return true;
}

export function SwitchingOffThePumpDrmOperationIntervalFromJSON(json: any): SwitchingOffThePumpDrmOperationInterval {
    return SwitchingOffThePumpDrmOperationIntervalFromJSONTyped(json, false);
}

export function SwitchingOffThePumpDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwitchingOffThePumpDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'duration': json['duration'],
        'residualPressureLimit': json['residualPressureLimit'],
    };
}

export function SwitchingOffThePumpDrmOperationIntervalToJSON(value?: SwitchingOffThePumpDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'duration': value['duration'],
        'residualPressureLimit': value['residualPressureLimit'],
    };
}

