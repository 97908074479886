/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommentDto,
  CreateCommentDto,
  ErrorResponse,
  UpdateCommentDto,
  ValidationErrorResponse,
} from '../models/index';
import {
    CommentDtoFromJSON,
    CommentDtoToJSON,
    CreateCommentDtoFromJSON,
    CreateCommentDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UpdateCommentDtoFromJSON,
    UpdateCommentDtoToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface CreateCommentRequest {
    createCommentDto: CreateCommentDto;
}

export interface DeleteCommentRequest {
    commentId: string;
}

export interface GetCommentRequest {
    commentId: string;
}

export interface GetCommentsByIntervalRequest {
    wellboreId: string;
    axis: GetCommentsByIntervalAxisEnum;
    startDateTime?: string;
    endDateTime?: string;
    startDepth?: number;
    endDepth?: number;
}

export interface UpdateCommentRequest {
    commentId: string;
    updateCommentDto: UpdateCommentDto;
}

/**
 * 
 */
export class CommentApi extends runtime.BaseAPI {

    /**
     */
    async createCommentRaw(requestParameters: CreateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters['createCommentDto'] == null) {
            throw new runtime.RequiredError(
                'createCommentDto',
                'Required parameter "createCommentDto" was null or undefined when calling createComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCommentDtoToJSON(requestParameters['createCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async createComment(requestParameters: CreateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.createCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling deleteComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteComment(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCommentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCommentRaw(requestParameters: GetCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling getComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async getComment(requestParameters: GetCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.getCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCommentsByIntervalRaw(requestParameters: GetCommentsByIntervalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommentDto>>> {
        if (requestParameters['wellboreId'] == null) {
            throw new runtime.RequiredError(
                'wellboreId',
                'Required parameter "wellboreId" was null or undefined when calling getCommentsByInterval().'
            );
        }

        if (requestParameters['axis'] == null) {
            throw new runtime.RequiredError(
                'axis',
                'Required parameter "axis" was null or undefined when calling getCommentsByInterval().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellboreId'] != null) {
            queryParameters['wellboreId'] = requestParameters['wellboreId'];
        }

        if (requestParameters['axis'] != null) {
            queryParameters['axis'] = requestParameters['axis'];
        }

        if (requestParameters['startDateTime'] != null) {
            queryParameters['startDateTime'] = requestParameters['startDateTime'];
        }

        if (requestParameters['endDateTime'] != null) {
            queryParameters['endDateTime'] = requestParameters['endDateTime'];
        }

        if (requestParameters['startDepth'] != null) {
            queryParameters['startDepth'] = requestParameters['startDepth'];
        }

        if (requestParameters['endDepth'] != null) {
            queryParameters['endDepth'] = requestParameters['endDepth'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentDtoFromJSON));
    }

    /**
     */
    async getCommentsByInterval(requestParameters: GetCommentsByIntervalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommentDto>> {
        const response = await this.getCommentsByIntervalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCommentRaw(requestParameters: UpdateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling updateComment().'
            );
        }

        if (requestParameters['updateCommentDto'] == null) {
            throw new runtime.RequiredError(
                'updateCommentDto',
                'Required parameter "updateCommentDto" was null or undefined when calling updateComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCommentDtoToJSON(requestParameters['updateCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateComment(requestParameters: UpdateCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.updateCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetCommentsByIntervalAxisEnum = {
    DateTime: 'DATE_TIME',
    Depth: 'DEPTH'
} as const;
export type GetCommentsByIntervalAxisEnum = typeof GetCommentsByIntervalAxisEnum[keyof typeof GetCommentsByIntervalAxisEnum];
