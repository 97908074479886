/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrmDto,
  DrmRevisionCreateUpdateDto,
  DrmRevisionDto,
  ErrorResponse,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrmDtoFromJSON,
    DrmDtoToJSON,
    DrmRevisionCreateUpdateDtoFromJSON,
    DrmRevisionCreateUpdateDtoToJSON,
    DrmRevisionDtoFromJSON,
    DrmRevisionDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface DeleteRequest {
    revisionId: string;
}

export interface CreateAkbModeDrmRequest {
    revisionId: string;
}

export interface CreateNewRevisionRequest {
    drmRevisionCreateUpdateDto: DrmRevisionCreateUpdateDto;
}

export interface DeleteDrmByIdRequest {
    drmId: string;
}

export interface GetDrmInfoByIdRequest {
    drmId: string;
}

export interface GetDrmRevisionsByWellIdRequest {
    wellId: number;
}

export interface UpdateRevisionRequest {
    revisionId: string;
    drmRevisionCreateUpdateDto: DrmRevisionCreateUpdateDto;
}

/**
 * 
 */
export class DrmRevisionControllerApi extends runtime.BaseAPI {

    /**
     */
    async _deleteRaw(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['revisionId'] == null) {
            throw new runtime.RequiredError(
                'revisionId',
                'Required parameter "revisionId" was null or undefined when calling _delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/revisions/{revisionId}`.replace(`{${"revisionId"}}`, encodeURIComponent(String(requestParameters['revisionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async _delete(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this._deleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createAkbModeDrmRaw(requestParameters: CreateAkbModeDrmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['revisionId'] == null) {
            throw new runtime.RequiredError(
                'revisionId',
                'Required parameter "revisionId" was null or undefined when calling createAkbModeDrm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/revisions/{revisionId}/akb_mode`.replace(`{${"revisionId"}}`, encodeURIComponent(String(requestParameters['revisionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createAkbModeDrm(requestParameters: CreateAkbModeDrmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAkbModeDrmRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createNewRevisionRaw(requestParameters: CreateNewRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrmRevisionDto>> {
        if (requestParameters['drmRevisionCreateUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'drmRevisionCreateUpdateDto',
                'Required parameter "drmRevisionCreateUpdateDto" was null or undefined when calling createNewRevision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/revisions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrmRevisionCreateUpdateDtoToJSON(requestParameters['drmRevisionCreateUpdateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrmRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async createNewRevision(requestParameters: CreateNewRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrmRevisionDto> {
        const response = await this.createNewRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDrmByIdRaw(requestParameters: DeleteDrmByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['drmId'] == null) {
            throw new runtime.RequiredError(
                'drmId',
                'Required parameter "drmId" was null or undefined when calling deleteDrmById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/{drmId}`.replace(`{${"drmId"}}`, encodeURIComponent(String(requestParameters['drmId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDrmById(requestParameters: DeleteDrmByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDrmByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getDrmInfoByIdRaw(requestParameters: GetDrmInfoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrmDto>> {
        if (requestParameters['drmId'] == null) {
            throw new runtime.RequiredError(
                'drmId',
                'Required parameter "drmId" was null or undefined when calling getDrmInfoById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/{drmId}`.replace(`{${"drmId"}}`, encodeURIComponent(String(requestParameters['drmId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrmDtoFromJSON(jsonValue));
    }

    /**
     */
    async getDrmInfoById(requestParameters: GetDrmInfoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrmDto> {
        const response = await this.getDrmInfoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDrmRevisionsByWellIdRaw(requestParameters: GetDrmRevisionsByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrmRevisionDto>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling getDrmRevisionsByWellId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/revisions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrmRevisionDtoFromJSON));
    }

    /**
     */
    async getDrmRevisionsByWellId(requestParameters: GetDrmRevisionsByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrmRevisionDto>> {
        const response = await this.getDrmRevisionsByWellIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRevisionRaw(requestParameters: UpdateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrmRevisionDto>> {
        if (requestParameters['revisionId'] == null) {
            throw new runtime.RequiredError(
                'revisionId',
                'Required parameter "revisionId" was null or undefined when calling updateRevision().'
            );
        }

        if (requestParameters['drmRevisionCreateUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'drmRevisionCreateUpdateDto',
                'Required parameter "drmRevisionCreateUpdateDto" was null or undefined when calling updateRevision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/drm/revisions/{revisionId}`.replace(`{${"revisionId"}}`, encodeURIComponent(String(requestParameters['revisionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DrmRevisionCreateUpdateDtoToJSON(requestParameters['drmRevisionCreateUpdateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrmRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateRevision(requestParameters: UpdateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrmRevisionDto> {
        const response = await this.updateRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
