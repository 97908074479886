/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DrillingPadEventContent } from './DrillingPadEventContent';
import {
    instanceOfDrillingPadEventContent,
    DrillingPadEventContentFromJSON,
    DrillingPadEventContentFromJSONTyped,
    DrillingPadEventContentToJSON,
} from './DrillingPadEventContent';
import type { DrillingWellEventContent } from './DrillingWellEventContent';
import {
    instanceOfDrillingWellEventContent,
    DrillingWellEventContentFromJSON,
    DrillingWellEventContentFromJSONTyped,
    DrillingWellEventContentToJSON,
} from './DrillingWellEventContent';
import type { EventIncidentContent } from './EventIncidentContent';
import {
    instanceOfEventIncidentContent,
    EventIncidentContentFromJSON,
    EventIncidentContentFromJSONTyped,
    EventIncidentContentToJSON,
} from './EventIncidentContent';
import type { KgsArrangementEventContent } from './KgsArrangementEventContent';
import {
    instanceOfKgsArrangementEventContent,
    KgsArrangementEventContentFromJSON,
    KgsArrangementEventContentFromJSONTyped,
    KgsArrangementEventContentToJSON,
} from './KgsArrangementEventContent';
import type { PadDrillingRigAssemblingEventContent } from './PadDrillingRigAssemblingEventContent';
import {
    instanceOfPadDrillingRigAssemblingEventContent,
    PadDrillingRigAssemblingEventContentFromJSON,
    PadDrillingRigAssemblingEventContentFromJSONTyped,
    PadDrillingRigAssemblingEventContentToJSON,
} from './PadDrillingRigAssemblingEventContent';
import type { PadDrillingRigDisassemblingEventContent } from './PadDrillingRigDisassemblingEventContent';
import {
    instanceOfPadDrillingRigDisassemblingEventContent,
    PadDrillingRigDisassemblingEventContentFromJSON,
    PadDrillingRigDisassemblingEventContentFromJSONTyped,
    PadDrillingRigDisassemblingEventContentToJSON,
} from './PadDrillingRigDisassemblingEventContent';
import type { PadKgsBuildingEventContent } from './PadKgsBuildingEventContent';
import {
    instanceOfPadKgsBuildingEventContent,
    PadKgsBuildingEventContentFromJSON,
    PadKgsBuildingEventContentFromJSONTyped,
    PadKgsBuildingEventContentToJSON,
} from './PadKgsBuildingEventContent';
import type { SchedulerEquipmentTechnicalWorkByDayContent } from './SchedulerEquipmentTechnicalWorkByDayContent';
import {
    instanceOfSchedulerEquipmentTechnicalWorkByDayContent,
    SchedulerEquipmentTechnicalWorkByDayContentFromJSON,
    SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped,
    SchedulerEquipmentTechnicalWorkByDayContentToJSON,
} from './SchedulerEquipmentTechnicalWorkByDayContent';
import type { SchedulerEquipmentTechnicalWorkContent } from './SchedulerEquipmentTechnicalWorkContent';
import {
    instanceOfSchedulerEquipmentTechnicalWorkContent,
    SchedulerEquipmentTechnicalWorkContentFromJSON,
    SchedulerEquipmentTechnicalWorkContentFromJSONTyped,
    SchedulerEquipmentTechnicalWorkContentToJSON,
} from './SchedulerEquipmentTechnicalWorkContent';
import type { WellCompletionEventContent } from './WellCompletionEventContent';
import {
    instanceOfWellCompletionEventContent,
    WellCompletionEventContentFromJSON,
    WellCompletionEventContentFromJSONTyped,
    WellCompletionEventContentToJSON,
} from './WellCompletionEventContent';

/**
 * @type SchedulerEventData
 * 
 * @export
 */
export type SchedulerEventData = DrillingPadEventContent | DrillingWellEventContent | EventIncidentContent | KgsArrangementEventContent | PadDrillingRigAssemblingEventContent | PadDrillingRigDisassemblingEventContent | PadKgsBuildingEventContent | SchedulerEquipmentTechnicalWorkByDayContent | SchedulerEquipmentTechnicalWorkContent | WellCompletionEventContent;

export function SchedulerEventDataFromJSON(json: any): SchedulerEventData {
    return SchedulerEventDataFromJSONTyped(json, false);
}

export function SchedulerEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerEventData {
    if (json == null) {
        return json;
    }
    if (instanceOfDrillingPadEventContent(json)) {
        return DrillingPadEventContentFromJSONTyped(json, true);
    }
    if (instanceOfDrillingWellEventContent(json)) {
        return DrillingWellEventContentFromJSONTyped(json, true);
    }
    if (instanceOfEventIncidentContent(json)) {
        return EventIncidentContentFromJSONTyped(json, true);
    }
    if (instanceOfKgsArrangementEventContent(json)) {
        return KgsArrangementEventContentFromJSONTyped(json, true);
    }
    if (instanceOfPadDrillingRigAssemblingEventContent(json)) {
        return PadDrillingRigAssemblingEventContentFromJSONTyped(json, true);
    }
    if (instanceOfPadDrillingRigDisassemblingEventContent(json)) {
        return PadDrillingRigDisassemblingEventContentFromJSONTyped(json, true);
    }
    if (instanceOfPadKgsBuildingEventContent(json)) {
        return PadKgsBuildingEventContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerEquipmentTechnicalWorkByDayContent(json)) {
        return SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerEquipmentTechnicalWorkContent(json)) {
        return SchedulerEquipmentTechnicalWorkContentFromJSONTyped(json, true);
    }
    if (instanceOfWellCompletionEventContent(json)) {
        return WellCompletionEventContentFromJSONTyped(json, true);
    }

    return {} as any;
}

export function SchedulerEventDataToJSON(value?: SchedulerEventData | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfDrillingPadEventContent(value)) {
        return DrillingPadEventContentToJSON(value as DrillingPadEventContent);
    }
    if (instanceOfDrillingWellEventContent(value)) {
        return DrillingWellEventContentToJSON(value as DrillingWellEventContent);
    }
    if (instanceOfEventIncidentContent(value)) {
        return EventIncidentContentToJSON(value as EventIncidentContent);
    }
    if (instanceOfKgsArrangementEventContent(value)) {
        return KgsArrangementEventContentToJSON(value as KgsArrangementEventContent);
    }
    if (instanceOfPadDrillingRigAssemblingEventContent(value)) {
        return PadDrillingRigAssemblingEventContentToJSON(value as PadDrillingRigAssemblingEventContent);
    }
    if (instanceOfPadDrillingRigDisassemblingEventContent(value)) {
        return PadDrillingRigDisassemblingEventContentToJSON(value as PadDrillingRigDisassemblingEventContent);
    }
    if (instanceOfPadKgsBuildingEventContent(value)) {
        return PadKgsBuildingEventContentToJSON(value as PadKgsBuildingEventContent);
    }
    if (instanceOfSchedulerEquipmentTechnicalWorkByDayContent(value)) {
        return SchedulerEquipmentTechnicalWorkByDayContentToJSON(value as SchedulerEquipmentTechnicalWorkByDayContent);
    }
    if (instanceOfSchedulerEquipmentTechnicalWorkContent(value)) {
        return SchedulerEquipmentTechnicalWorkContentToJSON(value as SchedulerEquipmentTechnicalWorkContent);
    }
    if (instanceOfWellCompletionEventContent(value)) {
        return WellCompletionEventContentToJSON(value as WellCompletionEventContent);
    }

    return {};
}

