/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramFunctionParams
 */
export interface AutoDrillingProgramFunctionParams {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AutoDrillingProgramFunctionParams
     */
    params: { [key: string]: number; };
}

/**
 * Check if a given object implements the AutoDrillingProgramFunctionParams interface.
 */
export function instanceOfAutoDrillingProgramFunctionParams(value: object): value is AutoDrillingProgramFunctionParams {
    if (!('params' in value) || value['params'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramFunctionParamsFromJSON(json: any): AutoDrillingProgramFunctionParams {
    return AutoDrillingProgramFunctionParamsFromJSONTyped(json, false);
}

export function AutoDrillingProgramFunctionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramFunctionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'params': json['params'],
    };
}

export function AutoDrillingProgramFunctionParamsToJSON(value?: AutoDrillingProgramFunctionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'params': value['params'],
    };
}

