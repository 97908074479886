/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingProgramStage } from './AutoDrillingProgramStage';
import {
    AutoDrillingProgramStageFromJSON,
    AutoDrillingProgramStageFromJSONTyped,
    AutoDrillingProgramStageToJSON,
} from './AutoDrillingProgramStage';

/**
 * 
 * @export
 * @interface AutoDrillingProgram
 */
export interface AutoDrillingProgram {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgram
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgram
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgram
     */
    itemOrder: number;
    /**
     * 
     * @type {Array<AutoDrillingProgramStage>}
     * @memberof AutoDrillingProgram
     */
    stages: Array<AutoDrillingProgramStage>;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgram
     */
    wellConstruction?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgram
     */
    startDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgram
     */
    endDepth?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgram
     */
    status: AutoDrillingProgramStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AutoDrillingProgram
     */
    startable?: boolean;
}


/**
 * @export
 */
export const AutoDrillingProgramStatusEnum = {
    Unknown: 'UNKNOWN',
    New: 'NEW',
    Delivered: 'DELIVERED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Started: 'STARTED',
    Finished: 'FINISHED',
    Failed: 'FAILED',
    Stopped: 'STOPPED'
} as const;
export type AutoDrillingProgramStatusEnum = typeof AutoDrillingProgramStatusEnum[keyof typeof AutoDrillingProgramStatusEnum];


/**
 * Check if a given object implements the AutoDrillingProgram interface.
 */
export function instanceOfAutoDrillingProgram(value: object): value is AutoDrillingProgram {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    if (!('stages' in value) || value['stages'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramFromJSON(json: any): AutoDrillingProgram {
    return AutoDrillingProgramFromJSONTyped(json, false);
}

export function AutoDrillingProgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgram {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'itemOrder': json['itemOrder'],
        'stages': ((json['stages'] as Array<any>).map(AutoDrillingProgramStageFromJSON)),
        'wellConstruction': json['wellConstruction'] == null ? undefined : json['wellConstruction'],
        'startDepth': json['startDepth'] == null ? undefined : json['startDepth'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
        'status': json['status'],
        'startable': json['startable'] == null ? undefined : json['startable'],
    };
}

export function AutoDrillingProgramToJSON(value?: AutoDrillingProgram | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
        'stages': ((value['stages'] as Array<any>).map(AutoDrillingProgramStageToJSON)),
        'wellConstruction': value['wellConstruction'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
        'status': value['status'],
        'startable': value['startable'],
    };
}

