/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BasicDictEntity,
  DrillingRigV2,
  DrillingRigV2WithWellInfoResponse,
  ErrorResponse,
  PageDrillingRigV2WithWellInfoResponse,
  Pageable,
  ValidationErrorResponse,
} from '../models/index';
import {
    BasicDictEntityFromJSON,
    BasicDictEntityToJSON,
    DrillingRigV2FromJSON,
    DrillingRigV2ToJSON,
    DrillingRigV2WithWellInfoResponseFromJSON,
    DrillingRigV2WithWellInfoResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageDrillingRigV2WithWellInfoResponseFromJSON,
    PageDrillingRigV2WithWellInfoResponseToJSON,
    PageableFromJSON,
    PageableToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface CreateRequest {
    drillingRigV2: DrillingRigV2;
}

export interface Delete1Request {
    id: number;
}

export interface FindAllByTypeRequest {
    pageable: Pageable;
    rigPurpose?: string;
}

export interface GetByIdRequest {
    id: number;
}

export interface UpdateRequest {
    id: number;
    drillingRigV2: DrillingRigV2;
}

/**
 * 
 */
export class DrillingRigControllerApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['drillingRigV2'] == null) {
            throw new runtime.RequiredError(
                'drillingRigV2',
                'Required parameter "drillingRigV2" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigV2ToJSON(requestParameters['drillingRigV2']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async create(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRaw(requestParameters, initOverrides);
    }

    /**
     */
    async delete1Raw(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling delete1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete1(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delete1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllByTypeRaw(requestParameters: FindAllByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDrillingRigV2WithWellInfoResponse>> {
        if (requestParameters['pageable'] == null) {
            throw new runtime.RequiredError(
                'pageable',
                'Required parameter "pageable" was null or undefined when calling findAllByType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['rigPurpose'] != null) {
            queryParameters['rigPurpose'] = requestParameters['rigPurpose'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDrillingRigV2WithWellInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async findAllByType(requestParameters: FindAllByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDrillingRigV2WithWellInfoResponse> {
        const response = await this.findAllByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigV2WithWellInfoResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigV2WithWellInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getById(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigV2WithWellInfoResponse> {
        const response = await this.getByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDrillingRigDictViewRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BasicDictEntity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig/dict-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicDictEntityFromJSON));
    }

    /**
     */
    async getDrillingRigDictView(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BasicDictEntity>> {
        const response = await this.getDrillingRigDictViewRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling update().'
            );
        }

        if (requestParameters['drillingRigV2'] == null) {
            throw new runtime.RequiredError(
                'drillingRigV2',
                'Required parameter "drillingRigV2" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigV2ToJSON(requestParameters['drillingRigV2']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRaw(requestParameters, initOverrides);
    }

}
