/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingTemplateFunction
 */
export interface AutoDrillingTemplateFunction {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateFunction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateFunction
     */
    type: AutoDrillingTemplateFunctionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingTemplateFunction
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingTemplateFunction
     */
    itemOrder: number;
}


/**
 * @export
 */
export const AutoDrillingTemplateFunctionTypeEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type AutoDrillingTemplateFunctionTypeEnum = typeof AutoDrillingTemplateFunctionTypeEnum[keyof typeof AutoDrillingTemplateFunctionTypeEnum];


/**
 * Check if a given object implements the AutoDrillingTemplateFunction interface.
 */
export function instanceOfAutoDrillingTemplateFunction(value: object): value is AutoDrillingTemplateFunction {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    return true;
}

export function AutoDrillingTemplateFunctionFromJSON(json: any): AutoDrillingTemplateFunction {
    return AutoDrillingTemplateFunctionFromJSONTyped(json, false);
}

export function AutoDrillingTemplateFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingTemplateFunction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'itemOrder': json['itemOrder'],
    };
}

export function AutoDrillingTemplateFunctionToJSON(value?: AutoDrillingTemplateFunction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
    };
}

