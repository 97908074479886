/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingProgramOperation } from './AutoDrillingProgramOperation';
import {
    AutoDrillingProgramOperationFromJSON,
    AutoDrillingProgramOperationFromJSONTyped,
    AutoDrillingProgramOperationToJSON,
} from './AutoDrillingProgramOperation';

/**
 * 
 * @export
 * @interface AutoDrillingProgramStage
 */
export interface AutoDrillingProgramStage {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStage
     */
    type: AutoDrillingProgramStageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramStage
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramStage
     */
    itemOrder: number;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramStage
     */
    depthFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramStage
     */
    depthTo?: number;
    /**
     * 
     * @type {Array<AutoDrillingProgramOperation>}
     * @memberof AutoDrillingProgramStage
     */
    operations: Array<AutoDrillingProgramOperation>;
}


/**
 * @export
 */
export const AutoDrillingProgramStageTypeEnum = {
    PumpStart: 'PUMP_START',
    OscillationAngles: 'OSCILLATION_ANGLES',
    Rotor: 'ROTOR',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingProgramStageTypeEnum = typeof AutoDrillingProgramStageTypeEnum[keyof typeof AutoDrillingProgramStageTypeEnum];


/**
 * Check if a given object implements the AutoDrillingProgramStage interface.
 */
export function instanceOfAutoDrillingProgramStage(value: object): value is AutoDrillingProgramStage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    if (!('operations' in value) || value['operations'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramStageFromJSON(json: any): AutoDrillingProgramStage {
    return AutoDrillingProgramStageFromJSONTyped(json, false);
}

export function AutoDrillingProgramStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramStage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'itemOrder': json['itemOrder'],
        'depthFrom': json['depthFrom'] == null ? undefined : json['depthFrom'],
        'depthTo': json['depthTo'] == null ? undefined : json['depthTo'],
        'operations': ((json['operations'] as Array<any>).map(AutoDrillingProgramOperationFromJSON)),
    };
}

export function AutoDrillingProgramStageToJSON(value?: AutoDrillingProgramStage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
        'depthFrom': value['depthFrom'],
        'depthTo': value['depthTo'],
        'operations': ((value['operations'] as Array<any>).map(AutoDrillingProgramOperationToJSON)),
    };
}

