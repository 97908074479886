/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerItemContent } from './SchedulerItemContent';
import {
    SchedulerItemContentFromJSON,
    SchedulerItemContentFromJSONTyped,
    SchedulerItemContentToJSON,
} from './SchedulerItemContent';

/**
 * 
 * @export
 * @interface SchedulerDeviceContent
 */
export interface SchedulerDeviceContent extends SchedulerItemContent {
    /**
     * 
     * @type {number}
     * @memberof SchedulerDeviceContent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SchedulerDeviceContent
     */
    deviceType: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerDeviceContent
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerDeviceContent
     */
    factoryNumber: string;
}

/**
 * Check if a given object implements the SchedulerDeviceContent interface.
 */
export function instanceOfSchedulerDeviceContent(value: object): value is SchedulerDeviceContent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('deviceType' in value) || value['deviceType'] === undefined) return false;
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('factoryNumber' in value) || value['factoryNumber'] === undefined) return false;
    return true;
}

export function SchedulerDeviceContentFromJSON(json: any): SchedulerDeviceContent {
    return SchedulerDeviceContentFromJSONTyped(json, false);
}

export function SchedulerDeviceContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerDeviceContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerItemContentFromJSONTyped(json, ignoreDiscriminator),
        'id': json['id'],
        'deviceType': json['deviceType'],
        'model': json['model'],
        'factoryNumber': json['factoryNumber'],
    };
}

export function SchedulerDeviceContentToJSON(value?: SchedulerDeviceContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerItemContentToJSON(value),
        'id': value['id'],
        'deviceType': value['deviceType'],
        'model': value['model'],
        'factoryNumber': value['factoryNumber'],
    };
}

