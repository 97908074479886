/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface ReamingRotorDrmOperationInterval
 */
export interface ReamingRotorDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1StopPointOffBottom: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    pressureMax: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    differentialPressure: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    tight: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    slackingOff: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1RepetitionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    numberOfReamings: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    torqueMax: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1RopUp: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1RopDown: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1RpmUp: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1RpmDown: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1FlowRateUp: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1FlowRateDown: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    reaming1Interval: number;
    /**
     * 
     * @type {number}
     * @memberof ReamingRotorDrmOperationInterval
     */
    flowRateDown: number;
}

/**
 * Check if a given object implements the ReamingRotorDrmOperationInterval interface.
 */
export function instanceOfReamingRotorDrmOperationInterval(value: object): value is ReamingRotorDrmOperationInterval {
    if (!('reaming1StopPointOffBottom' in value) || value['reaming1StopPointOffBottom'] === undefined) return false;
    if (!('pressureMax' in value) || value['pressureMax'] === undefined) return false;
    if (!('differentialPressure' in value) || value['differentialPressure'] === undefined) return false;
    if (!('tight' in value) || value['tight'] === undefined) return false;
    if (!('slackingOff' in value) || value['slackingOff'] === undefined) return false;
    if (!('reaming1RepetitionsCount' in value) || value['reaming1RepetitionsCount'] === undefined) return false;
    if (!('numberOfReamings' in value) || value['numberOfReamings'] === undefined) return false;
    if (!('torqueMax' in value) || value['torqueMax'] === undefined) return false;
    if (!('reaming1RopUp' in value) || value['reaming1RopUp'] === undefined) return false;
    if (!('reaming1RopDown' in value) || value['reaming1RopDown'] === undefined) return false;
    if (!('reaming1RpmUp' in value) || value['reaming1RpmUp'] === undefined) return false;
    if (!('reaming1RpmDown' in value) || value['reaming1RpmDown'] === undefined) return false;
    if (!('reaming1FlowRateUp' in value) || value['reaming1FlowRateUp'] === undefined) return false;
    if (!('reaming1FlowRateDown' in value) || value['reaming1FlowRateDown'] === undefined) return false;
    if (!('reaming1Interval' in value) || value['reaming1Interval'] === undefined) return false;
    if (!('flowRateDown' in value) || value['flowRateDown'] === undefined) return false;
    return true;
}

export function ReamingRotorDrmOperationIntervalFromJSON(json: any): ReamingRotorDrmOperationInterval {
    return ReamingRotorDrmOperationIntervalFromJSONTyped(json, false);
}

export function ReamingRotorDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReamingRotorDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'reaming1StopPointOffBottom': json['reaming1StopPointOffBottom'],
        'pressureMax': json['pressureMax'],
        'differentialPressure': json['differentialPressure'],
        'tight': json['tight'],
        'slackingOff': json['slackingOff'],
        'reaming1RepetitionsCount': json['reaming1RepetitionsCount'],
        'numberOfReamings': json['numberOfReamings'],
        'torqueMax': json['torqueMax'],
        'reaming1RopUp': json['reaming1RopUp'],
        'reaming1RopDown': json['reaming1RopDown'],
        'reaming1RpmUp': json['reaming1RpmUp'],
        'reaming1RpmDown': json['reaming1RpmDown'],
        'reaming1FlowRateUp': json['reaming1FlowRateUp'],
        'reaming1FlowRateDown': json['reaming1FlowRateDown'],
        'reaming1Interval': json['reaming1Interval'],
        'flowRateDown': json['flowRateDown'],
    };
}

export function ReamingRotorDrmOperationIntervalToJSON(value?: ReamingRotorDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'reaming1StopPointOffBottom': value['reaming1StopPointOffBottom'],
        'pressureMax': value['pressureMax'],
        'differentialPressure': value['differentialPressure'],
        'tight': value['tight'],
        'slackingOff': value['slackingOff'],
        'reaming1RepetitionsCount': value['reaming1RepetitionsCount'],
        'numberOfReamings': value['numberOfReamings'],
        'torqueMax': value['torqueMax'],
        'reaming1RopUp': value['reaming1RopUp'],
        'reaming1RopDown': value['reaming1RopDown'],
        'reaming1RpmUp': value['reaming1RpmUp'],
        'reaming1RpmDown': value['reaming1RpmDown'],
        'reaming1FlowRateUp': value['reaming1FlowRateUp'],
        'reaming1FlowRateDown': value['reaming1FlowRateDown'],
        'reaming1Interval': value['reaming1Interval'],
        'flowRateDown': value['flowRateDown'],
    };
}

