import {Atom} from '@atomrx/atom';
import {
    CommonLineObjectProps,
    RtmGraphIndexType,
    RtmGraphTooltipMode,
} from '@pages/monitoring/types';

export const activeConnectionId$ = Atom.create<string>('');
export const activeTrackId$ = Atom.create<string | undefined>('');
export const depthChunk$ = Atom.create<number[]>([]);
export const indexType$ = Atom.create<RtmGraphIndexType>(RtmGraphIndexType.depth);
export const tooltipMode$ = Atom.create<RtmGraphTooltipMode>(RtmGraphTooltipMode.line);
export const tooltipModeAutoButton$ = Atom.create<boolean>(true);
export const commonLineObject$ = Atom.create<CommonLineObjectProps | null>(null);
export const activeTimezone$ = Atom.create<string>('');
export const isOnlineMode$ = Atom.create<boolean>(false);
export const comparePlanFactButton$ = Atom.create<boolean>(false);
export const videoStreamingButton$ = Atom.create<boolean>(false);
