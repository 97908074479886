/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingOperationView
 */
export interface AutoDrillingOperationView {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingOperationView
     */
    key?: AutoDrillingOperationViewKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingOperationView
     */
    value?: string;
}


/**
 * @export
 */
export const AutoDrillingOperationViewKeyEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingOperationViewKeyEnum = typeof AutoDrillingOperationViewKeyEnum[keyof typeof AutoDrillingOperationViewKeyEnum];


/**
 * Check if a given object implements the AutoDrillingOperationView interface.
 */
export function instanceOfAutoDrillingOperationView(value: object): value is AutoDrillingOperationView {
    return true;
}

export function AutoDrillingOperationViewFromJSON(json: any): AutoDrillingOperationView {
    return AutoDrillingOperationViewFromJSONTyped(json, false);
}

export function AutoDrillingOperationViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingOperationView {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function AutoDrillingOperationViewToJSON(value?: AutoDrillingOperationView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

