/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataBorderRequest,
  DataBorderResponse,
  DrillingParamsResponse,
  ErrorResponse,
  GraphByDots,
  GraphMomentsAndWeightsRequest,
  MnemonicDataResponse,
  MnemonicTemplateDto,
  MnemonicWithDefaultUnit,
  MonitoringTemplate,
  MonitoringTemplateRequest,
  MonitoringTemplateWithTracks,
  MonitoringWell,
  Unit,
  ValidationErrorResponse,
  WellAndTimePeriodInfoRequest,
  WellPadWithMonitoringData,
} from '../models/index';
import {
    DataBorderRequestFromJSON,
    DataBorderRequestToJSON,
    DataBorderResponseFromJSON,
    DataBorderResponseToJSON,
    DrillingParamsResponseFromJSON,
    DrillingParamsResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GraphByDotsFromJSON,
    GraphByDotsToJSON,
    GraphMomentsAndWeightsRequestFromJSON,
    GraphMomentsAndWeightsRequestToJSON,
    MnemonicDataResponseFromJSON,
    MnemonicDataResponseToJSON,
    MnemonicTemplateDtoFromJSON,
    MnemonicTemplateDtoToJSON,
    MnemonicWithDefaultUnitFromJSON,
    MnemonicWithDefaultUnitToJSON,
    MonitoringTemplateFromJSON,
    MonitoringTemplateToJSON,
    MonitoringTemplateRequestFromJSON,
    MonitoringTemplateRequestToJSON,
    MonitoringTemplateWithTracksFromJSON,
    MonitoringTemplateWithTracksToJSON,
    MonitoringWellFromJSON,
    MonitoringWellToJSON,
    UnitFromJSON,
    UnitToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
    WellAndTimePeriodInfoRequestFromJSON,
    WellAndTimePeriodInfoRequestToJSON,
    WellPadWithMonitoringDataFromJSON,
    WellPadWithMonitoringDataToJSON,
} from '../models/index';

export interface CalculateRequest {
    wellId: number;
    graphMomentsAndWeightsRequest: GraphMomentsAndWeightsRequest;
}

export interface CreateTemplateRequest {
    monitoringTemplateRequest: MonitoringTemplateRequest;
}

export interface DeleteByIdRequest {
    id: string;
}

export interface FindAll2Request {
    wellprofile?: Array<string>;
    status?: Array<string>;
    search?: string;
    fieldId?: Array<number>;
    onlyWithConnections?: boolean;
}

export interface FindByIdRequest {
    id: string;
}

export interface FindDataBordersRequest {
    dataBorderRequest: DataBorderRequest;
}

export interface FindDataForGraphMomentsRequest {
    wellId: number;
}

export interface FindDataForGraphWeightsRequest {
    wellId: number;
}

export interface FindDrillingParamsRequest {
    wellId: number;
}

export interface FindMnemonicDataRequest {
    wellAndTimePeriodInfoRequest: WellAndTimePeriodInfoRequest;
}

export interface FindTemplateRequest {
    name: string;
}

export interface FindWellsRequest {
    fieldId: number;
    onlyWithConnections?: boolean;
}

export interface GetAvailableMnemonicsByWellboreRequest {
    wellboreId: string;
    trackId?: string;
}

export interface MonitoringUpdateTemplateRequest {
    id: string;
    monitoringTemplateRequest: MonitoringTemplateRequest;
}

export interface SaveTemplateRequest {
    mnemonicTemplateDto: MnemonicTemplateDto;
}

/**
 * 
 */
export class APIApi extends runtime.BaseAPI {

    /**
     */
    async calculateRaw(requestParameters: CalculateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling calculate().'
            );
        }

        if (requestParameters['graphMomentsAndWeightsRequest'] == null) {
            throw new runtime.RequiredError(
                'graphMomentsAndWeightsRequest',
                'Required parameter "graphMomentsAndWeightsRequest" was null or undefined when calling calculate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/graph/drilling-params`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GraphMomentsAndWeightsRequestToJSON(requestParameters['graphMomentsAndWeightsRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async calculate(requestParameters: CalculateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.calculateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTemplateRaw(requestParameters: CreateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTemplateWithTracks>> {
        if (requestParameters['monitoringTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'monitoringTemplateRequest',
                'Required parameter "monitoringTemplateRequest" was null or undefined when calling createTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringTemplateRequestToJSON(requestParameters['monitoringTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTemplateWithTracksFromJSON(jsonValue));
    }

    /**
     */
    async createTemplate(requestParameters: CreateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTemplateWithTracks> {
        const response = await this.createTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteByIdRaw(requestParameters: DeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteById(requestParameters: DeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findAll2Raw(requestParameters: FindAll2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitoringWell>>> {
        const queryParameters: any = {};

        if (requestParameters['wellprofile'] != null) {
            queryParameters['wellprofile'] = requestParameters['wellprofile'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['fieldId'] != null) {
            queryParameters['fieldId'] = requestParameters['fieldId'];
        }

        if (requestParameters['onlyWithConnections'] != null) {
            queryParameters['onlyWithConnections'] = requestParameters['onlyWithConnections'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/wells`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringWellFromJSON));
    }

    /**
     */
    async findAll2(requestParameters: FindAll2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitoringWell>> {
        const response = await this.findAll2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitoringTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringTemplateFromJSON));
    }

    /**
     */
    async findAllTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitoringTemplate>> {
        const response = await this.findAllTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves list of mnemonic templates
     * Get mnemonic templates
     */
    async findAllTemplates1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MnemonicTemplateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/mnemonic-mapping-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MnemonicTemplateDtoFromJSON));
    }

    /**
     * This endpoint retrieves list of mnemonic templates
     * Get mnemonic templates
     */
    async findAllTemplates1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MnemonicTemplateDto>> {
        const response = await this.findAllTemplates1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findByIdRaw(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTemplateWithTracks>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTemplateWithTracksFromJSON(jsonValue));
    }

    /**
     */
    async findById(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTemplateWithTracks> {
        const response = await this.findByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDataBordersRaw(requestParameters: FindDataBordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataBorderResponse>> {
        if (requestParameters['dataBorderRequest'] == null) {
            throw new runtime.RequiredError(
                'dataBorderRequest',
                'Required parameter "dataBorderRequest" was null or undefined when calling findDataBorders().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/data/border/find`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataBorderRequestToJSON(requestParameters['dataBorderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBorderResponseFromJSON(jsonValue));
    }

    /**
     */
    async findDataBorders(requestParameters: FindDataBordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataBorderResponse> {
        const response = await this.findDataBordersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDataForGraphMomentsRaw(requestParameters: FindDataForGraphMomentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GraphByDots>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling findDataForGraphMoments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/graph/moments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GraphByDotsFromJSON));
    }

    /**
     */
    async findDataForGraphMoments(requestParameters: FindDataForGraphMomentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GraphByDots>> {
        const response = await this.findDataForGraphMomentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDataForGraphWeightsRaw(requestParameters: FindDataForGraphWeightsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GraphByDots>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling findDataForGraphWeights().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/graph/weights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GraphByDotsFromJSON));
    }

    /**
     */
    async findDataForGraphWeights(requestParameters: FindDataForGraphWeightsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GraphByDots>> {
        const response = await this.findDataForGraphWeightsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDrillingParamsRaw(requestParameters: FindDrillingParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingParamsResponse>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling findDrillingParams().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/graph/drilling-params`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingParamsResponseFromJSON(jsonValue));
    }

    /**
     */
    async findDrillingParams(requestParameters: FindDrillingParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingParamsResponse> {
        const response = await this.findDrillingParamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMnemonicDataRaw(requestParameters: FindMnemonicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MnemonicDataResponse>> {
        if (requestParameters['wellAndTimePeriodInfoRequest'] == null) {
            throw new runtime.RequiredError(
                'wellAndTimePeriodInfoRequest',
                'Required parameter "wellAndTimePeriodInfoRequest" was null or undefined when calling findMnemonicData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/data/find`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WellAndTimePeriodInfoRequestToJSON(requestParameters['wellAndTimePeriodInfoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MnemonicDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async findMnemonicData(requestParameters: FindMnemonicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MnemonicDataResponse> {
        const response = await this.findMnemonicDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves mnemonic template by template name
     * Get mnemonic template by name
     */
    async findTemplateRaw(requestParameters: FindTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MnemonicTemplateDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling findTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/mnemonic-mapping-template/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MnemonicTemplateDtoFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves mnemonic template by template name
     * Get mnemonic template by name
     */
    async findTemplate(requestParameters: FindTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MnemonicTemplateDto> {
        const response = await this.findTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findWellsRaw(requestParameters: FindWellsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WellPadWithMonitoringData>>> {
        if (requestParameters['fieldId'] == null) {
            throw new runtime.RequiredError(
                'fieldId',
                'Required parameter "fieldId" was null or undefined when calling findWells().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fieldId'] != null) {
            queryParameters['fieldId'] = requestParameters['fieldId'];
        }

        if (requestParameters['onlyWithConnections'] != null) {
            queryParameters['onlyWithConnections'] = requestParameters['onlyWithConnections'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/well-pads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WellPadWithMonitoringDataFromJSON));
    }

    /**
     */
    async findWells(requestParameters: FindWellsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WellPadWithMonitoringData>> {
        const response = await this.findWellsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllUnitsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Unit>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template/track/curve/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitFromJSON));
    }

    /**
     */
    async getAllUnits(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Unit>> {
        const response = await this.getAllUnitsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableMnemonicsByWellboreRaw(requestParameters: GetAvailableMnemonicsByWellboreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MnemonicWithDefaultUnit>>> {
        if (requestParameters['wellboreId'] == null) {
            throw new runtime.RequiredError(
                'wellboreId',
                'Required parameter "wellboreId" was null or undefined when calling getAvailableMnemonicsByWellbore().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellboreId'] != null) {
            queryParameters['wellboreId'] = requestParameters['wellboreId'];
        }

        if (requestParameters['trackId'] != null) {
            queryParameters['trackId'] = requestParameters['trackId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/data/mnemonics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MnemonicWithDefaultUnitFromJSON));
    }

    /**
     */
    async getAvailableMnemonicsByWellbore(requestParameters: GetAvailableMnemonicsByWellboreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MnemonicWithDefaultUnit>> {
        const response = await this.getAvailableMnemonicsByWellboreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringUpdateTemplateRaw(requestParameters: MonitoringUpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTemplateWithTracks>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling monitoringUpdateTemplate().'
            );
        }

        if (requestParameters['monitoringTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'monitoringTemplateRequest',
                'Required parameter "monitoringTemplateRequest" was null or undefined when calling monitoringUpdateTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringTemplateRequestToJSON(requestParameters['monitoringTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTemplateWithTracksFromJSON(jsonValue));
    }

    /**
     */
    async monitoringUpdateTemplate(requestParameters: MonitoringUpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTemplateWithTracks> {
        const response = await this.monitoringUpdateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint creates mnemonic template
     * Create mnemonic template
     */
    async saveTemplateRaw(requestParameters: SaveTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mnemonicTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'mnemonicTemplateDto',
                'Required parameter "mnemonicTemplateDto" was null or undefined when calling saveTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/mnemonic-mapping-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MnemonicTemplateDtoToJSON(requestParameters['mnemonicTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint creates mnemonic template
     * Create mnemonic template
     */
    async saveTemplate(requestParameters: SaveTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveTemplateRaw(requestParameters, initOverrides);
    }

}
