/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringWellboreDto
 */
export interface MonitoringWellboreDto {
    /**
     * 
     * @type {string}
     * @memberof MonitoringWellboreDto
     */
    wellboreId?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWellboreDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWellboreDto
     */
    wellboreName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWellboreDto
     */
    sourceWellboreUid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringWellboreDto
     */
    status?: MonitoringWellboreDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringWellboreDto
     */
    logsFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringWellboreDto
     */
    logsTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringWellboreDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringWellboreDto
     */
    live?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoringWellboreDto
     */
    delayInSec?: number;
}


/**
 * @export
 */
export const MonitoringWellboreDtoStatusEnum = {
    New: 'NEW',
    Loading: 'LOADING',
    Finished: 'FINISHED'
} as const;
export type MonitoringWellboreDtoStatusEnum = typeof MonitoringWellboreDtoStatusEnum[keyof typeof MonitoringWellboreDtoStatusEnum];


/**
 * Check if a given object implements the MonitoringWellboreDto interface.
 */
export function instanceOfMonitoringWellboreDto(value: object): value is MonitoringWellboreDto {
    return true;
}

export function MonitoringWellboreDtoFromJSON(json: any): MonitoringWellboreDto {
    return MonitoringWellboreDtoFromJSONTyped(json, false);
}

export function MonitoringWellboreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringWellboreDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellboreId': json['wellboreId'] == null ? undefined : json['wellboreId'],
        'name': json['name'] == null ? undefined : json['name'],
        'wellboreName': json['wellboreName'] == null ? undefined : json['wellboreName'],
        'sourceWellboreUid': json['sourceWellboreUid'] == null ? undefined : json['sourceWellboreUid'],
        'status': json['status'] == null ? undefined : json['status'],
        'logsFrom': json['logsFrom'] == null ? undefined : (new Date(json['logsFrom'])),
        'logsTo': json['logsTo'] == null ? undefined : (new Date(json['logsTo'])),
        'active': json['active'] == null ? undefined : json['active'],
        'live': json['live'] == null ? undefined : json['live'],
        'delayInSec': json['delayInSec'] == null ? undefined : json['delayInSec'],
    };
}

export function MonitoringWellboreDtoToJSON(value?: MonitoringWellboreDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellboreId': value['wellboreId'],
        'name': value['name'],
        'wellboreName': value['wellboreName'],
        'sourceWellboreUid': value['sourceWellboreUid'],
        'status': value['status'],
        'logsFrom': value['logsFrom'] == null ? undefined : ((value['logsFrom']).toISOString()),
        'logsTo': value['logsTo'] == null ? undefined : ((value['logsTo']).toISOString()),
        'active': value['active'],
        'live': value['live'],
        'delayInSec': value['delayInSec'],
    };
}

