/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WitsmlServerConnectionSettings } from './WitsmlServerConnectionSettings';
import {
    WitsmlServerConnectionSettingsFromJSON,
    WitsmlServerConnectionSettingsFromJSONTyped,
    WitsmlServerConnectionSettingsToJSON,
} from './WitsmlServerConnectionSettings';
import type { WitsmlWellboreMappingDto } from './WitsmlWellboreMappingDto';
import {
    WitsmlWellboreMappingDtoFromJSON,
    WitsmlWellboreMappingDtoFromJSONTyped,
    WitsmlWellboreMappingDtoToJSON,
} from './WitsmlWellboreMappingDto';

/**
 * 
 * @export
 * @interface WitsmlConnectionDto
 */
export interface WitsmlConnectionDto {
    /**
     * 
     * @type {WitsmlServerConnectionSettings}
     * @memberof WitsmlConnectionDto
     */
    witsmlServer: WitsmlServerConnectionSettings;
    /**
     * 
     * @type {number}
     * @memberof WitsmlConnectionDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionDto
     */
    connectionName: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionDto
     */
    mnemonicTemplateId?: string;
    /**
     * 
     * @type {Array<WitsmlWellboreMappingDto>}
     * @memberof WitsmlConnectionDto
     */
    wellbores: Array<WitsmlWellboreMappingDto>;
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionDto
     */
    zoneId: string;
}

/**
 * Check if a given object implements the WitsmlConnectionDto interface.
 */
export function instanceOfWitsmlConnectionDto(value: object): value is WitsmlConnectionDto {
    if (!('witsmlServer' in value) || value['witsmlServer'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('connectionName' in value) || value['connectionName'] === undefined) return false;
    if (!('wellbores' in value) || value['wellbores'] === undefined) return false;
    if (!('zoneId' in value) || value['zoneId'] === undefined) return false;
    return true;
}

export function WitsmlConnectionDtoFromJSON(json: any): WitsmlConnectionDto {
    return WitsmlConnectionDtoFromJSONTyped(json, false);
}

export function WitsmlConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlConnectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'witsmlServer': WitsmlServerConnectionSettingsFromJSON(json['witsmlServer']),
        'wellId': json['wellId'],
        'connectionName': json['connectionName'],
        'mnemonicTemplateId': json['mnemonicTemplateId'] == null ? undefined : json['mnemonicTemplateId'],
        'wellbores': ((json['wellbores'] as Array<any>).map(WitsmlWellboreMappingDtoFromJSON)),
        'zoneId': json['zoneId'],
    };
}

export function WitsmlConnectionDtoToJSON(value?: WitsmlConnectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'witsmlServer': WitsmlServerConnectionSettingsToJSON(value['witsmlServer']),
        'wellId': value['wellId'],
        'connectionName': value['connectionName'],
        'mnemonicTemplateId': value['mnemonicTemplateId'],
        'wellbores': ((value['wellbores'] as Array<any>).map(WitsmlWellboreMappingDtoToJSON)),
        'zoneId': value['zoneId'],
    };
}

