/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigTimeNormative
 */
export interface DrillingRigTimeNormative {
    /**
     * 
     * @type {string}
     * @memberof DrillingRigTimeNormative
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigTimeNormative
     */
    subsystem: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigTimeNormative
     */
    maintenanceType: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigTimeNormative
     */
    maintenanceDescription: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigTimeNormative
     */
    unit: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigTimeNormative
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigTimeNormative
     */
    workDuration?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingRigTimeNormative
     */
    isPprAdded?: boolean;
}

/**
 * Check if a given object implements the DrillingRigTimeNormative interface.
 */
export function instanceOfDrillingRigTimeNormative(value: object): value is DrillingRigTimeNormative {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('subsystem' in value) || value['subsystem'] === undefined) return false;
    if (!('maintenanceType' in value) || value['maintenanceType'] === undefined) return false;
    if (!('maintenanceDescription' in value) || value['maintenanceDescription'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function DrillingRigTimeNormativeFromJSON(json: any): DrillingRigTimeNormative {
    return DrillingRigTimeNormativeFromJSONTyped(json, false);
}

export function DrillingRigTimeNormativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigTimeNormative {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subsystem': json['subsystem'],
        'maintenanceType': json['maintenanceType'],
        'maintenanceDescription': json['maintenanceDescription'],
        'unit': json['unit'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'workDuration': json['workDuration'] == null ? undefined : json['workDuration'],
        'isPprAdded': json['isPprAdded'] == null ? undefined : json['isPprAdded'],
    };
}

export function DrillingRigTimeNormativeToJSON(value?: DrillingRigTimeNormative | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'subsystem': value['subsystem'],
        'maintenanceType': value['maintenanceType'],
        'maintenanceDescription': value['maintenanceDescription'],
        'unit': value['unit'],
        'frequency': value['frequency'],
        'workDuration': value['workDuration'],
        'isPprAdded': value['isPprAdded'],
    };
}

