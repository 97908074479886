/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface StaticMeasurementOutputDrmOperationInterval
 */
export interface StaticMeasurementOutputDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof StaticMeasurementOutputDrmOperationInterval
     */
    signalWaitingTime: number;
}

/**
 * Check if a given object implements the StaticMeasurementOutputDrmOperationInterval interface.
 */
export function instanceOfStaticMeasurementOutputDrmOperationInterval(value: object): value is StaticMeasurementOutputDrmOperationInterval {
    if (!('signalWaitingTime' in value) || value['signalWaitingTime'] === undefined) return false;
    return true;
}

export function StaticMeasurementOutputDrmOperationIntervalFromJSON(json: any): StaticMeasurementOutputDrmOperationInterval {
    return StaticMeasurementOutputDrmOperationIntervalFromJSONTyped(json, false);
}

export function StaticMeasurementOutputDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaticMeasurementOutputDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'signalWaitingTime': json['signalWaitingTime'],
    };
}

export function StaticMeasurementOutputDrmOperationIntervalToJSON(value?: StaticMeasurementOutputDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'signalWaitingTime': value['signalWaitingTime'],
    };
}

