/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WitsmlServerConnectionSettings } from './WitsmlServerConnectionSettings';
import {
    WitsmlServerConnectionSettingsFromJSON,
    WitsmlServerConnectionSettingsFromJSONTyped,
    WitsmlServerConnectionSettingsToJSON,
} from './WitsmlServerConnectionSettings';

/**
 * 
 * @export
 * @interface WitsmlConnectionResponse
 */
export interface WitsmlConnectionResponse {
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionResponse
     */
    id?: string;
    /**
     * 
     * @type {WitsmlServerConnectionSettings}
     * @memberof WitsmlConnectionResponse
     */
    witsmlServer?: WitsmlServerConnectionSettings;
    /**
     * 
     * @type {number}
     * @memberof WitsmlConnectionResponse
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionResponse
     */
    connectionName?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlConnectionResponse
     */
    mnemonicTemplateName?: string;
}

/**
 * Check if a given object implements the WitsmlConnectionResponse interface.
 */
export function instanceOfWitsmlConnectionResponse(value: object): value is WitsmlConnectionResponse {
    return true;
}

export function WitsmlConnectionResponseFromJSON(json: any): WitsmlConnectionResponse {
    return WitsmlConnectionResponseFromJSONTyped(json, false);
}

export function WitsmlConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'witsmlServer': json['witsmlServer'] == null ? undefined : WitsmlServerConnectionSettingsFromJSON(json['witsmlServer']),
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'mnemonicTemplateName': json['mnemonicTemplateName'] == null ? undefined : json['mnemonicTemplateName'],
    };
}

export function WitsmlConnectionResponseToJSON(value?: WitsmlConnectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'witsmlServer': WitsmlServerConnectionSettingsToJSON(value['witsmlServer']),
        'wellId': value['wellId'],
        'connectionName': value['connectionName'],
        'mnemonicTemplateName': value['mnemonicTemplateName'],
    };
}

