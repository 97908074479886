/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmSectionCreateUpdateDto } from './DrmSectionCreateUpdateDto';
import {
    DrmSectionCreateUpdateDtoFromJSON,
    DrmSectionCreateUpdateDtoFromJSONTyped,
    DrmSectionCreateUpdateDtoToJSON,
} from './DrmSectionCreateUpdateDto';

/**
 * 
 * @export
 * @interface DrmRevisionCreateUpdateDto
 */
export interface DrmRevisionCreateUpdateDto {
    /**
     * 
     * @type {Date}
     * @memberof DrmRevisionCreateUpdateDto
     */
    actualFor: Date;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionCreateUpdateDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionCreateUpdateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionCreateUpdateDto
     */
    status: DrmRevisionCreateUpdateDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionCreateUpdateDto
     */
    wellbore: string;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionCreateUpdateDto
     */
    intervalStep?: number;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionCreateUpdateDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionCreateUpdateDto
     */
    endDepth: number;
    /**
     * 
     * @type {Array<DrmSectionCreateUpdateDto>}
     * @memberof DrmRevisionCreateUpdateDto
     */
    sections: Array<DrmSectionCreateUpdateDto>;
}


/**
 * @export
 */
export const DrmRevisionCreateUpdateDtoStatusEnum = {
    Agreed: 'AGREED',
    Draft: 'DRAFT'
} as const;
export type DrmRevisionCreateUpdateDtoStatusEnum = typeof DrmRevisionCreateUpdateDtoStatusEnum[keyof typeof DrmRevisionCreateUpdateDtoStatusEnum];


/**
 * Check if a given object implements the DrmRevisionCreateUpdateDto interface.
 */
export function instanceOfDrmRevisionCreateUpdateDto(value: object): value is DrmRevisionCreateUpdateDto {
    if (!('actualFor' in value) || value['actualFor'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('wellbore' in value) || value['wellbore'] === undefined) return false;
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('endDepth' in value) || value['endDepth'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    return true;
}

export function DrmRevisionCreateUpdateDtoFromJSON(json: any): DrmRevisionCreateUpdateDto {
    return DrmRevisionCreateUpdateDtoFromJSONTyped(json, false);
}

export function DrmRevisionCreateUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmRevisionCreateUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'actualFor': (new Date(json['actualFor'])),
        'wellId': json['wellId'],
        'name': json['name'],
        'status': json['status'],
        'wellbore': json['wellbore'],
        'intervalStep': json['intervalStep'] == null ? undefined : json['intervalStep'],
        'startDepth': json['startDepth'],
        'endDepth': json['endDepth'],
        'sections': ((json['sections'] as Array<any>).map(DrmSectionCreateUpdateDtoFromJSON)),
    };
}

export function DrmRevisionCreateUpdateDtoToJSON(value?: DrmRevisionCreateUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actualFor': ((value['actualFor']).toISOString().substring(0,10)),
        'wellId': value['wellId'],
        'name': value['name'],
        'status': value['status'],
        'wellbore': value['wellbore'],
        'intervalStep': value['intervalStep'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
        'sections': ((value['sections'] as Array<any>).map(DrmSectionCreateUpdateDtoToJSON)),
    };
}

