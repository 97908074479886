/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTemplateTrackCurveRequest } from './MonitoringTemplateTrackCurveRequest';
import {
    MonitoringTemplateTrackCurveRequestFromJSON,
    MonitoringTemplateTrackCurveRequestFromJSONTyped,
    MonitoringTemplateTrackCurveRequestToJSON,
} from './MonitoringTemplateTrackCurveRequest';

/**
 * 
 * @export
 * @interface MonitoringTemplateTrackRequest
 */
export interface MonitoringTemplateTrackRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitoringTemplateTrackRequest
     */
    id?: string;
    /**
     * 
     * @type {Array<MonitoringTemplateTrackCurveRequest>}
     * @memberof MonitoringTemplateTrackRequest
     */
    curves?: Array<MonitoringTemplateTrackCurveRequest>;
}

/**
 * Check if a given object implements the MonitoringTemplateTrackRequest interface.
 */
export function instanceOfMonitoringTemplateTrackRequest(value: object): value is MonitoringTemplateTrackRequest {
    return true;
}

export function MonitoringTemplateTrackRequestFromJSON(json: any): MonitoringTemplateTrackRequest {
    return MonitoringTemplateTrackRequestFromJSONTyped(json, false);
}

export function MonitoringTemplateTrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTemplateTrackRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'curves': json['curves'] == null ? undefined : ((json['curves'] as Array<any>).map(MonitoringTemplateTrackCurveRequestFromJSON)),
    };
}

export function MonitoringTemplateTrackRequestToJSON(value?: MonitoringTemplateTrackRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(MonitoringTemplateTrackCurveRequestToJSON)),
    };
}

