/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrmRevisionSectionDto
 */
export interface DrmRevisionSectionDto {
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionSectionDto
     */
    sectionName?: string;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionSectionDto
     */
    startDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionSectionDto
     */
    endDepth?: number;
}

/**
 * Check if a given object implements the DrmRevisionSectionDto interface.
 */
export function instanceOfDrmRevisionSectionDto(value: object): value is DrmRevisionSectionDto {
    return true;
}

export function DrmRevisionSectionDtoFromJSON(json: any): DrmRevisionSectionDto {
    return DrmRevisionSectionDtoFromJSONTyped(json, false);
}

export function DrmRevisionSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmRevisionSectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sectionName': json['sectionName'] == null ? undefined : json['sectionName'],
        'startDepth': json['startDepth'] == null ? undefined : json['startDepth'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
    };
}

export function DrmRevisionSectionDtoToJSON(value?: DrmRevisionSectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sectionName': value['sectionName'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
    };
}

