/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface LoadCapacityDrmOperationInterval
 */
export interface LoadCapacityDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof LoadCapacityDrmOperationInterval
     */
    timeLoadCapacityMin: number;
    /**
     * 
     * @type {number}
     * @memberof LoadCapacityDrmOperationInterval
     */
    differentialPressureMin: number;
    /**
     * 
     * @type {number}
     * @memberof LoadCapacityDrmOperationInterval
     */
    weightOnBitMin: number;
}

/**
 * Check if a given object implements the LoadCapacityDrmOperationInterval interface.
 */
export function instanceOfLoadCapacityDrmOperationInterval(value: object): value is LoadCapacityDrmOperationInterval {
    if (!('timeLoadCapacityMin' in value) || value['timeLoadCapacityMin'] === undefined) return false;
    if (!('differentialPressureMin' in value) || value['differentialPressureMin'] === undefined) return false;
    if (!('weightOnBitMin' in value) || value['weightOnBitMin'] === undefined) return false;
    return true;
}

export function LoadCapacityDrmOperationIntervalFromJSON(json: any): LoadCapacityDrmOperationInterval {
    return LoadCapacityDrmOperationIntervalFromJSONTyped(json, false);
}

export function LoadCapacityDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadCapacityDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'timeLoadCapacityMin': json['timeLoadCapacityMin'],
        'differentialPressureMin': json['differentialPressureMin'],
        'weightOnBitMin': json['weightOnBitMin'],
    };
}

export function LoadCapacityDrmOperationIntervalToJSON(value?: LoadCapacityDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'timeLoadCapacityMin': value['timeLoadCapacityMin'],
        'differentialPressureMin': value['differentialPressureMin'],
        'weightOnBitMin': value['weightOnBitMin'],
    };
}

