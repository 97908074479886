/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationValue } from './OperationValue';
import {
    OperationValueFromJSON,
    OperationValueFromJSONTyped,
    OperationValueToJSON,
} from './OperationValue';

/**
 * 
 * @export
 * @interface SettingsDTO
 */
export interface SettingsDTO {
    /**
     * 
     * @type {Array<OperationValue>}
     * @memberof SettingsDTO
     */
    operationValues?: Array<OperationValue>;
    /**
     * 
     * @type {string}
     * @memberof SettingsDTO
     */
    timeStartShiftDay?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDTO
     */
    timeEndShiftDay?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDTO
     */
    timeStartShiftNight?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDTO
     */
    timeEndShiftNight?: string;
}

/**
 * Check if a given object implements the SettingsDTO interface.
 */
export function instanceOfSettingsDTO(value: object): value is SettingsDTO {
    return true;
}

export function SettingsDTOFromJSON(json: any): SettingsDTO {
    return SettingsDTOFromJSONTyped(json, false);
}

export function SettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'operationValues': json['operationValues'] == null ? undefined : ((json['operationValues'] as Array<any>).map(OperationValueFromJSON)),
        'timeStartShiftDay': json['timeStartShiftDay'] == null ? undefined : json['timeStartShiftDay'],
        'timeEndShiftDay': json['timeEndShiftDay'] == null ? undefined : json['timeEndShiftDay'],
        'timeStartShiftNight': json['timeStartShiftNight'] == null ? undefined : json['timeStartShiftNight'],
        'timeEndShiftNight': json['timeEndShiftNight'] == null ? undefined : json['timeEndShiftNight'],
    };
}

export function SettingsDTOToJSON(value?: SettingsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationValues': value['operationValues'] == null ? undefined : ((value['operationValues'] as Array<any>).map(OperationValueToJSON)),
        'timeStartShiftDay': value['timeStartShiftDay'],
        'timeEndShiftDay': value['timeEndShiftDay'],
        'timeStartShiftNight': value['timeStartShiftNight'],
        'timeEndShiftNight': value['timeEndShiftNight'],
    };
}

