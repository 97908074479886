import {IconButton, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useState} from 'react';
import {SettingsMenuItem} from './types';

type SettingsMenuProps = {
    fontSize?: 'small' | 'large' | 'medium';
    disabled?: boolean;
    items: SettingsMenuItem[];
    iconColor?: string;
};

export const SettingsMenu = ({
    fontSize = 'medium',
    items,
    disabled = false,
    iconColor,
}: SettingsMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{pointerEvents: 'all', color: iconColor}}
                size={fontSize}
                id='settings-button'
                aria-controls={open ? 'settings-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disabled={disabled}
            >
                <MoreVertIcon fontSize={fontSize} />
            </IconButton>
            <Menu
                id='settings-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                MenuListProps={{
                    'aria-labelledby': 'settings-button',
                }}
                sx={{'& .MuiMenu-paper': {backgroundImage: 'none'}}}
            >
                {items.map((item, index) => (
                    <MenuItem
                        disabled={item.disabled}
                        sx={{
                            color: 'var(--mui-palette-text-secondary)',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            borderBottom:
                                index === items.length - 1
                                    ? 'none'
                                    : '1px solid var(--mui-palette-divider)',
                        }}
                        key={item.trigger}
                        onClick={(event: React.MouseEvent<HTMLLIElement>) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleClose();
                            if (item.handler) {
                                item.handler(event);
                            }
                        }}
                    >
                        {item?.icon} {item.trigger}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
