/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MnemonicMapDto
 */
export interface MnemonicMapDto {
    /**
     * 
     * @type {string}
     * @memberof MnemonicMapDto
     */
    sourceMnemonic?: string;
    /**
     * 
     * @type {string}
     * @memberof MnemonicMapDto
     */
    monitoringMnemonic?: string;
}

/**
 * Check if a given object implements the MnemonicMapDto interface.
 */
export function instanceOfMnemonicMapDto(value: object): value is MnemonicMapDto {
    return true;
}

export function MnemonicMapDtoFromJSON(json: any): MnemonicMapDto {
    return MnemonicMapDtoFromJSONTyped(json, false);
}

export function MnemonicMapDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicMapDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceMnemonic': json['sourceMnemonic'] == null ? undefined : json['sourceMnemonic'],
        'monitoringMnemonic': json['monitoringMnemonic'] == null ? undefined : json['monitoringMnemonic'],
    };
}

export function MnemonicMapDtoToJSON(value?: MnemonicMapDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sourceMnemonic': value['sourceMnemonic'],
        'monitoringMnemonic': value['monitoringMnemonic'],
    };
}

