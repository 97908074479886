/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateIntervalInOperationDrmTableRequest } from './UpdateIntervalInOperationDrmTableRequest';
import {
    UpdateIntervalInOperationDrmTableRequestFromJSON,
    UpdateIntervalInOperationDrmTableRequestFromJSONTyped,
    UpdateIntervalInOperationDrmTableRequestToJSON,
} from './UpdateIntervalInOperationDrmTableRequest';

/**
 * 
 * @export
 * @interface DrmSectionOperationTableDto
 */
export interface DrmSectionOperationTableDto {
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    revisionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    drmType?: DrmSectionOperationTableDtoDrmTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    sectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    sectionName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DrmSectionOperationTableDto
     */
    warningSectionPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    warningSectionMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    operationType?: DrmSectionOperationTableDtoOperationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DrmSectionOperationTableDto
     */
    warningOperationPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DrmSectionOperationTableDto
     */
    warningOperationMessage?: string;
    /**
     * 
     * @type {Array<UpdateIntervalInOperationDrmTableRequest>}
     * @memberof DrmSectionOperationTableDto
     */
    sectionOperationTableData?: Array<UpdateIntervalInOperationDrmTableRequest>;
}


/**
 * @export
 */
export const DrmSectionOperationTableDtoDrmTypeEnum = {
    Drm: 'DRM',
    AkbMode: 'AKB_MODE'
} as const;
export type DrmSectionOperationTableDtoDrmTypeEnum = typeof DrmSectionOperationTableDtoDrmTypeEnum[keyof typeof DrmSectionOperationTableDtoDrmTypeEnum];

/**
 * @export
 */
export const DrmSectionOperationTableDtoOperationTypeEnum = {
    RotorDrilling: 'ROTOR_DRILLING',
    SlideDrilling: 'SLIDE_DRILLING',
    WorkingOutDown: 'WORKING_OUT_DOWN',
    WorkingOutUp: 'WORKING_OUT_UP',
    Spo: 'SPO',
    Restriction: 'RESTRICTION',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    LoadCapacity: 'LOAD_CAPACITY',
    TfOrientation: 'TF_ORIENTATION',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    ReamingRotor: 'REAMING_ROTOR',
    ReamingSlide: 'REAMING_SLIDE',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    Oscillation: 'OSCILLATION'
} as const;
export type DrmSectionOperationTableDtoOperationTypeEnum = typeof DrmSectionOperationTableDtoOperationTypeEnum[keyof typeof DrmSectionOperationTableDtoOperationTypeEnum];


/**
 * Check if a given object implements the DrmSectionOperationTableDto interface.
 */
export function instanceOfDrmSectionOperationTableDto(value: object): value is DrmSectionOperationTableDto {
    return true;
}

export function DrmSectionOperationTableDtoFromJSON(json: any): DrmSectionOperationTableDto {
    return DrmSectionOperationTableDtoFromJSONTyped(json, false);
}

export function DrmSectionOperationTableDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmSectionOperationTableDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'revisionId': json['revisionId'] == null ? undefined : json['revisionId'],
        'drmType': json['drmType'] == null ? undefined : json['drmType'],
        'sectionId': json['sectionId'] == null ? undefined : json['sectionId'],
        'sectionName': json['sectionName'] == null ? undefined : json['sectionName'],
        'warningSectionPresent': json['warningSectionPresent'] == null ? undefined : json['warningSectionPresent'],
        'warningSectionMessage': json['warningSectionMessage'] == null ? undefined : json['warningSectionMessage'],
        'operationType': json['operationType'] == null ? undefined : json['operationType'],
        'warningOperationPresent': json['warningOperationPresent'] == null ? undefined : json['warningOperationPresent'],
        'warningOperationMessage': json['warningOperationMessage'] == null ? undefined : json['warningOperationMessage'],
        'sectionOperationTableData': json['sectionOperationTableData'] == null ? undefined : ((json['sectionOperationTableData'] as Array<any>).map(UpdateIntervalInOperationDrmTableRequestFromJSON)),
    };
}

export function DrmSectionOperationTableDtoToJSON(value?: DrmSectionOperationTableDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'revisionId': value['revisionId'],
        'drmType': value['drmType'],
        'sectionId': value['sectionId'],
        'sectionName': value['sectionName'],
        'warningSectionPresent': value['warningSectionPresent'],
        'warningSectionMessage': value['warningSectionMessage'],
        'operationType': value['operationType'],
        'warningOperationPresent': value['warningOperationPresent'],
        'warningOperationMessage': value['warningOperationMessage'],
        'sectionOperationTableData': value['sectionOperationTableData'] == null ? undefined : ((value['sectionOperationTableData'] as Array<any>).map(UpdateIntervalInOperationDrmTableRequestToJSON)),
    };
}

