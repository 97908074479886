/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringWell } from './MonitoringWell';
import {
    MonitoringWellFromJSON,
    MonitoringWellFromJSONTyped,
    MonitoringWellToJSON,
} from './MonitoringWell';

/**
 * 
 * @export
 * @interface WellPadWithMonitoringData
 */
export interface WellPadWithMonitoringData {
    /**
     * 
     * @type {number}
     * @memberof WellPadWithMonitoringData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WellPadWithMonitoringData
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof WellPadWithMonitoringData
     */
    postPhoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof WellPadWithMonitoringData
     */
    coordinatesOffsetToTheNorth?: number;
    /**
     * 
     * @type {number}
     * @memberof WellPadWithMonitoringData
     */
    coordinatesOffsetToTheEast?: number;
    /**
     * 
     * @type {Array<MonitoringWell>}
     * @memberof WellPadWithMonitoringData
     */
    wells?: Array<MonitoringWell>;
}

/**
 * Check if a given object implements the WellPadWithMonitoringData interface.
 */
export function instanceOfWellPadWithMonitoringData(value: object): value is WellPadWithMonitoringData {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function WellPadWithMonitoringDataFromJSON(json: any): WellPadWithMonitoringData {
    return WellPadWithMonitoringDataFromJSONTyped(json, false);
}

export function WellPadWithMonitoringDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellPadWithMonitoringData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'],
        'postPhoneNumber': json['postPhoneNumber'] == null ? undefined : json['postPhoneNumber'],
        'coordinatesOffsetToTheNorth': json['coordinatesOffsetToTheNorth'] == null ? undefined : json['coordinatesOffsetToTheNorth'],
        'coordinatesOffsetToTheEast': json['coordinatesOffsetToTheEast'] == null ? undefined : json['coordinatesOffsetToTheEast'],
        'wells': json['wells'] == null ? undefined : ((json['wells'] as Array<any>).map(MonitoringWellFromJSON)),
    };
}

export function WellPadWithMonitoringDataToJSON(value?: WellPadWithMonitoringData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'postPhoneNumber': value['postPhoneNumber'],
        'coordinatesOffsetToTheNorth': value['coordinatesOffsetToTheNorth'],
        'coordinatesOffsetToTheEast': value['coordinatesOffsetToTheEast'],
        'wells': value['wells'] == null ? undefined : ((value['wells'] as Array<any>).map(MonitoringWellToJSON)),
    };
}

