/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WitsmlServerConnectionSettings } from './WitsmlServerConnectionSettings';
import {
    WitsmlServerConnectionSettingsFromJSON,
    WitsmlServerConnectionSettingsFromJSONTyped,
    WitsmlServerConnectionSettingsToJSON,
} from './WitsmlServerConnectionSettings';

/**
 * 
 * @export
 * @interface WitsmlServerSettings
 */
export interface WitsmlServerSettings {
    /**
     * 
     * @type {WitsmlServerConnectionSettings}
     * @memberof WitsmlServerSettings
     */
    witsmlServer?: WitsmlServerConnectionSettings;
    /**
     * 
     * @type {number}
     * @memberof WitsmlServerSettings
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerSettings
     */
    connectionName?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerSettings
     */
    mnemonicTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlServerSettings
     */
    zoneId?: string;
}

/**
 * Check if a given object implements the WitsmlServerSettings interface.
 */
export function instanceOfWitsmlServerSettings(value: object): value is WitsmlServerSettings {
    return true;
}

export function WitsmlServerSettingsFromJSON(json: any): WitsmlServerSettings {
    return WitsmlServerSettingsFromJSONTyped(json, false);
}

export function WitsmlServerSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlServerSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'witsmlServer': json['witsmlServer'] == null ? undefined : WitsmlServerConnectionSettingsFromJSON(json['witsmlServer']),
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'mnemonicTemplateId': json['mnemonicTemplateId'] == null ? undefined : json['mnemonicTemplateId'],
        'zoneId': json['zoneId'] == null ? undefined : json['zoneId'],
    };
}

export function WitsmlServerSettingsToJSON(value?: WitsmlServerSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'witsmlServer': WitsmlServerConnectionSettingsToJSON(value['witsmlServer']),
        'wellId': value['wellId'],
        'connectionName': value['connectionName'],
        'mnemonicTemplateId': value['mnemonicTemplateId'],
        'zoneId': value['zoneId'],
    };
}

