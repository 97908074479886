/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigV2
 */
export interface DrillingRigV2 {
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    rigName: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    drillingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    rigPurpose: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    rigType: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    rigModel: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2
     */
    rigYear: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2
     */
    rigLiftingCapacity: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2
     */
    wellId: number;
    /**
     * 
     * @type {Date}
     * @memberof DrillingRigV2
     */
    lastExpertise?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DrillingRigV2
     */
    nextExpertise?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2
     */
    fuelType?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2
     */
    fuelConsumption?: number;
}

/**
 * Check if a given object implements the DrillingRigV2 interface.
 */
export function instanceOfDrillingRigV2(value: object): value is DrillingRigV2 {
    if (!('rigName' in value) || value['rigName'] === undefined) return false;
    if (!('drillingCompany' in value) || value['drillingCompany'] === undefined) return false;
    if (!('manufacturer' in value) || value['manufacturer'] === undefined) return false;
    if (!('rigPurpose' in value) || value['rigPurpose'] === undefined) return false;
    if (!('rigType' in value) || value['rigType'] === undefined) return false;
    if (!('rigModel' in value) || value['rigModel'] === undefined) return false;
    if (!('rigYear' in value) || value['rigYear'] === undefined) return false;
    if (!('rigLiftingCapacity' in value) || value['rigLiftingCapacity'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    return true;
}

export function DrillingRigV2FromJSON(json: any): DrillingRigV2 {
    return DrillingRigV2FromJSONTyped(json, false);
}

export function DrillingRigV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'rigName': json['rigName'],
        'drillingCompany': json['drillingCompany'],
        'manufacturer': json['manufacturer'],
        'rigPurpose': json['rigPurpose'],
        'rigType': json['rigType'],
        'rigModel': json['rigModel'],
        'rigYear': json['rigYear'],
        'rigLiftingCapacity': json['rigLiftingCapacity'],
        'wellId': json['wellId'],
        'lastExpertise': json['lastExpertise'] == null ? undefined : (new Date(json['lastExpertise'])),
        'nextExpertise': json['nextExpertise'] == null ? undefined : (new Date(json['nextExpertise'])),
        'fuelType': json['fuelType'] == null ? undefined : json['fuelType'],
        'fuelConsumption': json['fuelConsumption'] == null ? undefined : json['fuelConsumption'],
    };
}

export function DrillingRigV2ToJSON(value?: DrillingRigV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'rigName': value['rigName'],
        'drillingCompany': value['drillingCompany'],
        'manufacturer': value['manufacturer'],
        'rigPurpose': value['rigPurpose'],
        'rigType': value['rigType'],
        'rigModel': value['rigModel'],
        'rigYear': value['rigYear'],
        'rigLiftingCapacity': value['rigLiftingCapacity'],
        'wellId': value['wellId'],
        'lastExpertise': value['lastExpertise'] == null ? undefined : ((value['lastExpertise']).toISOString().substring(0,10)),
        'nextExpertise': value['nextExpertise'] == null ? undefined : ((value['nextExpertise']).toISOString().substring(0,10)),
        'fuelType': value['fuelType'],
        'fuelConsumption': value['fuelConsumption'],
    };
}

