/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataBorderResponse
 */
export interface DataBorderResponse {
    /**
     * 
     * @type {Date}
     * @memberof DataBorderResponse
     */
    startIndex?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataBorderResponse
     */
    endIndex?: Date;
    /**
     * 
     * @type {number}
     * @memberof DataBorderResponse
     */
    startDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof DataBorderResponse
     */
    endDepth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataBorderResponse
     */
    isRealTimePossible?: boolean;
}

/**
 * Check if a given object implements the DataBorderResponse interface.
 */
export function instanceOfDataBorderResponse(value: object): value is DataBorderResponse {
    return true;
}

export function DataBorderResponseFromJSON(json: any): DataBorderResponse {
    return DataBorderResponseFromJSONTyped(json, false);
}

export function DataBorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataBorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'startIndex': json['startIndex'] == null ? undefined : (new Date(json['startIndex'])),
        'endIndex': json['endIndex'] == null ? undefined : (new Date(json['endIndex'])),
        'startDepth': json['startDepth'] == null ? undefined : json['startDepth'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
        'isRealTimePossible': json['isRealTimePossible'] == null ? undefined : json['isRealTimePossible'],
    };
}

export function DataBorderResponseToJSON(value?: DataBorderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startIndex': value['startIndex'] == null ? undefined : ((value['startIndex']).toISOString()),
        'endIndex': value['endIndex'] == null ? undefined : ((value['endIndex']).toISOString()),
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
        'isRealTimePossible': value['isRealTimePossible'],
    };
}

