/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramOperationParams
 */
export interface AutoDrillingProgramOperationParams {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AutoDrillingProgramOperationParams
     */
    params: { [key: string]: number; };
}

/**
 * Check if a given object implements the AutoDrillingProgramOperationParams interface.
 */
export function instanceOfAutoDrillingProgramOperationParams(value: object): value is AutoDrillingProgramOperationParams {
    if (!('params' in value) || value['params'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramOperationParamsFromJSON(json: any): AutoDrillingProgramOperationParams {
    return AutoDrillingProgramOperationParamsFromJSONTyped(json, false);
}

export function AutoDrillingProgramOperationParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramOperationParams {
    if (json == null) {
        return json;
    }
    return {
        
        'params': json['params'],
    };
}

export function AutoDrillingProgramOperationParamsToJSON(value?: AutoDrillingProgramOperationParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'params': value['params'],
    };
}

