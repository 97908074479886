/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface WorkingOutDownDrmOperationInterval
 */
export interface WorkingOutDownDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof WorkingOutDownDrmOperationInterval
     */
    rpmSetpoint: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutDownDrmOperationInterval
     */
    flowRate: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutDownDrmOperationInterval
     */
    reamingRepetitionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutDownDrmOperationInterval
     */
    reamingDurationMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingOutDownDrmOperationInterval
     */
    reaming1RopDown: number;
}

/**
 * Check if a given object implements the WorkingOutDownDrmOperationInterval interface.
 */
export function instanceOfWorkingOutDownDrmOperationInterval(value: object): value is WorkingOutDownDrmOperationInterval {
    if (!('rpmSetpoint' in value) || value['rpmSetpoint'] === undefined) return false;
    if (!('flowRate' in value) || value['flowRate'] === undefined) return false;
    if (!('reamingRepetitionsCount' in value) || value['reamingRepetitionsCount'] === undefined) return false;
    if (!('reamingDurationMinutes' in value) || value['reamingDurationMinutes'] === undefined) return false;
    if (!('reaming1RopDown' in value) || value['reaming1RopDown'] === undefined) return false;
    return true;
}

export function WorkingOutDownDrmOperationIntervalFromJSON(json: any): WorkingOutDownDrmOperationInterval {
    return WorkingOutDownDrmOperationIntervalFromJSONTyped(json, false);
}

export function WorkingOutDownDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingOutDownDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'rpmSetpoint': json['rpmSetpoint'],
        'flowRate': json['flowRate'],
        'reamingRepetitionsCount': json['reamingRepetitionsCount'],
        'reamingDurationMinutes': json['reamingDurationMinutes'],
        'reaming1RopDown': json['reaming1RopDown'],
    };
}

export function WorkingOutDownDrmOperationIntervalToJSON(value?: WorkingOutDownDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'rpmSetpoint': value['rpmSetpoint'],
        'flowRate': value['flowRate'],
        'reamingRepetitionsCount': value['reamingRepetitionsCount'],
        'reamingDurationMinutes': value['reamingDurationMinutes'],
        'reaming1RopDown': value['reaming1RopDown'],
    };
}

