/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpcomingTechnicalMaintenanceWorkInfo
 */
export interface UpcomingTechnicalMaintenanceWorkInfo {
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    timeNormativeId: string;
    /**
     * 
     * @type {Date}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    datePlan: Date;
    /**
     * 
     * @type {number}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    equipmentId: number;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    subsystem: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    maintenanceType: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    maintenanceDescription: string;
    /**
     * 
     * @type {number}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    frequency: number;
    /**
     * 
     * @type {string}
     * @memberof UpcomingTechnicalMaintenanceWorkInfo
     */
    status?: UpcomingTechnicalMaintenanceWorkInfoStatusEnum;
}


/**
 * @export
 */
export const UpcomingTechnicalMaintenanceWorkInfoStatusEnum = {
    Overdue: 'OVERDUE'
} as const;
export type UpcomingTechnicalMaintenanceWorkInfoStatusEnum = typeof UpcomingTechnicalMaintenanceWorkInfoStatusEnum[keyof typeof UpcomingTechnicalMaintenanceWorkInfoStatusEnum];


/**
 * Check if a given object implements the UpcomingTechnicalMaintenanceWorkInfo interface.
 */
export function instanceOfUpcomingTechnicalMaintenanceWorkInfo(value: object): value is UpcomingTechnicalMaintenanceWorkInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('timeNormativeId' in value) || value['timeNormativeId'] === undefined) return false;
    if (!('datePlan' in value) || value['datePlan'] === undefined) return false;
    if (!('equipmentId' in value) || value['equipmentId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('subsystem' in value) || value['subsystem'] === undefined) return false;
    if (!('maintenanceType' in value) || value['maintenanceType'] === undefined) return false;
    if (!('maintenanceDescription' in value) || value['maintenanceDescription'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    return true;
}

export function UpcomingTechnicalMaintenanceWorkInfoFromJSON(json: any): UpcomingTechnicalMaintenanceWorkInfo {
    return UpcomingTechnicalMaintenanceWorkInfoFromJSONTyped(json, false);
}

export function UpcomingTechnicalMaintenanceWorkInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpcomingTechnicalMaintenanceWorkInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timeNormativeId': json['timeNormativeId'],
        'datePlan': (new Date(json['datePlan'])),
        'equipmentId': json['equipmentId'],
        'type': json['type'],
        'model': json['model'],
        'title': json['title'],
        'subsystem': json['subsystem'],
        'maintenanceType': json['maintenanceType'],
        'maintenanceDescription': json['maintenanceDescription'],
        'frequency': json['frequency'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UpcomingTechnicalMaintenanceWorkInfoToJSON(value?: UpcomingTechnicalMaintenanceWorkInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'timeNormativeId': value['timeNormativeId'],
        'datePlan': ((value['datePlan']).toISOString().substring(0,10)),
        'equipmentId': value['equipmentId'],
        'type': value['type'],
        'model': value['model'],
        'title': value['title'],
        'subsystem': value['subsystem'],
        'maintenanceType': value['maintenanceType'],
        'maintenanceDescription': value['maintenanceDescription'],
        'frequency': value['frequency'],
        'status': value['status'],
    };
}

