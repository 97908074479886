/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrillingRigTimeNormative,
  DrillingRigTimeNormativeRequest,
  ErrorResponse,
  TitleDto,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrillingRigTimeNormativeFromJSON,
    DrillingRigTimeNormativeToJSON,
    DrillingRigTimeNormativeRequestFromJSON,
    DrillingRigTimeNormativeRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    TitleDtoFromJSON,
    TitleDtoToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface Create1Request {
    drillingRigEquipmentId: number;
    drillingRigTimeNormativeRequest: DrillingRigTimeNormativeRequest;
}

export interface Delete3Request {
    requestBody: Array<string>;
}

export interface FindAllRequest {
    drillingRigEquipmentId: number;
}

export interface SaveAsTemplateRequest {
    drillingRigEquipmentId: number;
    titleDto: TitleDto;
}

export interface Update1Request {
    drillingRigTimeNormativeId: string;
    drillingRigTimeNormativeRequest: DrillingRigTimeNormativeRequest;
}

/**
 * 
 */
export class DrillingRigTimeNormativeControllerApi extends runtime.BaseAPI {

    /**
     */
    async create1Raw(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigTimeNormative>> {
        if (requestParameters['drillingRigEquipmentId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipmentId',
                'Required parameter "drillingRigEquipmentId" was null or undefined when calling create1().'
            );
        }

        if (requestParameters['drillingRigTimeNormativeRequest'] == null) {
            throw new runtime.RequiredError(
                'drillingRigTimeNormativeRequest',
                'Required parameter "drillingRigTimeNormativeRequest" was null or undefined when calling create1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipments/{drillingRigEquipmentId}/time-normatives`.replace(`{${"drillingRigEquipmentId"}}`, encodeURIComponent(String(requestParameters['drillingRigEquipmentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigTimeNormativeRequestToJSON(requestParameters['drillingRigTimeNormativeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigTimeNormativeFromJSON(jsonValue));
    }

    /**
     */
    async create1(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigTimeNormative> {
        const response = await this.create1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async delete3Raw(requestParameters: Delete3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling delete3().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-time-normatives`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete3(requestParameters: Delete3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delete3Raw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllRaw(requestParameters: FindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrillingRigTimeNormative>>> {
        if (requestParameters['drillingRigEquipmentId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipmentId',
                'Required parameter "drillingRigEquipmentId" was null or undefined when calling findAll().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipments/{drillingRigEquipmentId}/time-normatives`.replace(`{${"drillingRigEquipmentId"}}`, encodeURIComponent(String(requestParameters['drillingRigEquipmentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrillingRigTimeNormativeFromJSON));
    }

    /**
     */
    async findAll(requestParameters: FindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrillingRigTimeNormative>> {
        const response = await this.findAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveAsTemplateRaw(requestParameters: SaveAsTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['drillingRigEquipmentId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipmentId',
                'Required parameter "drillingRigEquipmentId" was null or undefined when calling saveAsTemplate().'
            );
        }

        if (requestParameters['titleDto'] == null) {
            throw new runtime.RequiredError(
                'titleDto',
                'Required parameter "titleDto" was null or undefined when calling saveAsTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipments/{drillingRigEquipmentId}/save-as-template`.replace(`{${"drillingRigEquipmentId"}}`, encodeURIComponent(String(requestParameters['drillingRigEquipmentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TitleDtoToJSON(requestParameters['titleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveAsTemplate(requestParameters: SaveAsTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveAsTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async update1Raw(requestParameters: Update1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigTimeNormative>> {
        if (requestParameters['drillingRigTimeNormativeId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigTimeNormativeId',
                'Required parameter "drillingRigTimeNormativeId" was null or undefined when calling update1().'
            );
        }

        if (requestParameters['drillingRigTimeNormativeRequest'] == null) {
            throw new runtime.RequiredError(
                'drillingRigTimeNormativeRequest',
                'Required parameter "drillingRigTimeNormativeRequest" was null or undefined when calling update1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-time-normatives/{drillingRigTimeNormativeId}`.replace(`{${"drillingRigTimeNormativeId"}}`, encodeURIComponent(String(requestParameters['drillingRigTimeNormativeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigTimeNormativeRequestToJSON(requestParameters['drillingRigTimeNormativeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigTimeNormativeFromJSON(jsonValue));
    }

    /**
     */
    async update1(requestParameters: Update1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigTimeNormative> {
        const response = await this.update1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
